<template>
        <header class="tw-top">
          <a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
          <h1 class="tw-label-top">{{$t('user.main.MYPAGE.WTHD.TITLE')}}</h1>
        </header>
        
        <div class="tw-body">
          <div>
            <div class="tw-body-text-wrap">
              <label class="tw-label-default f24" >{{$t('user.main.MYPAGE.WTHD.NTC')}}</label>
              <div class="tw-notice-box">
                <ul>
                  <li>{{$t('user.main.MYPAGE.WTHD.NTC-1')}}</li>
                  <li>{{$t('user.main.MYPAGE.WTHD.NTC-4')}}</li>
                  <!-- <li>결재 내역 및 선 결제 금액에 대한 정보도 함께 삭제됩니다. 삭제된 결재 내역은 환불 처리가 불가능합니다.</li> -->
                  <!-- <li>{{$t('user.main.MYPAGE.WTHD.NTC-2')}}</li> -->
                  <li>{{$t('user.main.MYPAGE.WTHD.NTC-3')}}</li>
                </ul>
              </div>
              <div class="tw-body-agree-wrap">
                <label for="check8" class="tw-check-default medium">
                  <input type="checkbox" id="check8" aria-label="안내사항 모두 확인" @click="toggleAgree" :checked="agreeChk">
                  <span class="on" tabindex="0"></span>
                  {{$t('user.main.MYPAGE.WTHD.AGREE-1')}} <br> {{$t('user.main.MYPAGE.WTHD.AGREE-2')}} 
                </label>
              </div>
              <div class="tw-body-line-wrap m-0">
                <p>라인</p>
              </div>
              <div class="tw-service-box">
                <label class="tw-label-default bold f16">{{$t('user.main.MYPAGE.WTHD.REASON')}}</label>
                <div class="tw-radio-wrap">
                  <label v-for="option in options" :key="option.id" :for="option.id" class="radio_box">
                    <input
                      type="radio"
                      :id="option.id"
                      name="withdrawalReason"
                      :value="option.value"
                      :aria-label="option.label"
                      v-model="selectedReason"
                      @change="handleReasonChange"
                    >
                    <span class="on2" tabindex="0"></span>
                    {{ option.label }}
                  </label>
                  <div class="tw-radio-compo-wrap">
                    <label for="reason5" class="radio_box">
                      <input
                        type="radio"
                        id="reason5"
                        name="withdrawalReason"
                        value="005"
                        :aria-label="$t('user.main.MYPAGE.WTHD.CODE.OTHER')"
                        v-model="selectedReason"
                        @change="handleReasonChange"
                      >
                      <span class="on2" tabindex="0"></span>
                      {{ $t('user.main.MYPAGE.WTHD.CODE.OTHER') }}
                    </label>
                    <input
                      type="text"
                      class="tw-input-default"
                      :placeholder="$t('user.main.MYPAGE.WTHD.CODE.OTHER-PH')"
                      aria-label="기타 사유 입력"
                      v-model="otherReason"
                      :disabled="selectedReason !== '005'"
                    >
                  </div>
              </div>
            </div>
          </div>
          <div class="tw-body-btn-wrap">
            <button type="submit" class="tw-btn-default primary" :disabled="!(requiredChk && selectedReason)" aria-label="탈퇴하기 버튼" @click="goDelUser"> {{ $t('user.main.MYPAGE.WTHD.BUTTON') }}</button>
          </div>
        </div>
      </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { createLogger, useStore } from "vuex";
import { useI18n } from 'vue-i18n';
const { locale,t } = useI18n();

//글로벌 프로퍼티 설정 부분
import { useGlobalProperties } from '@/page/main/composables/useGlobalProperties'; 
const { $axios, $axiosJson } = useGlobalProperties();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert, loginChk, stripHtml } = useComm();

const store = useStore();
const sUser = computed(() => store.state.user.user);

const requiredChk = computed(() => {return agreeChk.value});
const agreeChk = ref(false);

const toggleAgree = () => {
  agreeChk.value = !agreeChk.value;
}


const options = [
  { id: 'reason1', value: '001', label: t('user.main.MYPAGE.WTHD.CODE.001') },
  { id: 'reason2', value: '002', label: t('user.main.MYPAGE.WTHD.CODE.002') },
  { id: 'reason3', value: '003', label: t('user.main.MYPAGE.WTHD.CODE.003') },
  { id: 'reason4', value: '004', label: t('user.main.MYPAGE.WTHD.CODE.004') },
];


const selectedReason = ref('');
const otherReason = ref('');

const handleReasonChange = () => {
  if (selectedReason.value !== '005') {
    otherReason.value = '';
  }
};

watch(selectedReason, (newValue) => {
  if (newValue !== '005') {
    otherReason.value = '';
  }
});

const goDelUser = async () => {
  let user = new Object;
  user.userNum = sUser.value.userNum;
  user.wthdCode = selectedReason.value;
  user.wthdText = otherReason.value;
  await $axios({
      method: 'DELETE',
      params: user,
      url: '/user',
  }).then((data) => {
      const resultYn = data.resultYn;
      if (resultYn == 'Y') {
        store.dispatch("user/clearUser");
        localStorage.removeItem("authorization");
        navigate({value: "mypageWthdResult"})
      }else{
        const ERROR_CODE = data.resultCode;
        switch(ERROR_CODE){
          case "C008":
            const goMypageMain = () => {
              navigate({ value: 'mypageMain' }); // 로그인 페이지로 리다이렉트
            }
            toggleCommAlert("ERROR", ERROR_CODE, "ALERT", goMypageMain);
            break;
          default:
            toggleCommAlert("COMM", "FAILED", "ALERT");
            break;
        } 
      }
  }).catch((error) => {
      toggleCommAlert("COMM", "FAILED");
  })
};


</script>