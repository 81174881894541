<template>

        <header class="tw-top">
          <a class="tw-top-icon" @click="navigate({value:'mypageMain'})">뒤로가기 아이콘</a>
          <h1 class="tw-label-top">{{$t('user.main.MYPAGE.WTHD.TITLE')}}</h1>
        </header>

        <div class="tw-body p-0">
          <div class="tw-resign-wrap">
            <div class="tw-body-imgbox">
              <img src="/tripwith/assets/images/img-out.png" alt="회원탈퇴 이미지" width="180" height="180">
            </div>
            <div class="tw-body-text-wrap">
              <h2 class="tw-label-default f16" style="margin-bottom: 12px;">{{$t('user.main.MYPAGE.WTHD.RESULT.MESSAGE-1')}}</h2>
              <label class="tw-label-default f16">{{$t('user.main.MYPAGE.WTHD.RESULT.MESSAGE-2')}}</label>
            </div>
          </div>
          <div class="tw-body-btn-wrap bottom" style="margin-top: 20px;">
            <button type="button" class="tw-btn-default primary" @click="navigate({value:'mypageMain'})">{{$t('user.main.MYPAGE.WTHD.RESULT.BUTTON')}}</button>
          </div>
        </div>


</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { createLogger, useStore } from "vuex";
import { useI18n } from 'vue-i18n';
const { locale,t } = useI18n();

//글로벌 프로퍼티 설정 부분
import { useGlobalProperties } from '@/page/main/composables/useGlobalProperties'; 
const { $axios, $axiosJson } = useGlobalProperties();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { stripHtml } = useComm();

const store = useStore();

</script>