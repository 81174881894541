<template>
<li class="tw-comment-list" v-for="(tripinfoCmnt, index) in cmntLst" :key="index" >
  <cmnt-item :tripinfoCmnt="tripinfoCmnt" :sUserNum="sUser.userNum" :isReply="false" />
  <ul class="tw-reply-list" v-if="tripinfoCmnt.childTripinfoCmntProjection">
    <li class="tw-comment-list reply">
      <div class="tw-comment-list-left" v-for="(tripinfoCmnt, index) in tripinfoCmnt.childTripinfoCmntProjection" :key="index">
        <cmnt-item :tripinfoCmnt="tripinfoCmnt" :sUserNum="sUser.userNum" :isReply="true" />
      </div>
    </li>
  </ul>
</li>
</template>


<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import { createLogger, useStore } from "vuex";
import cmntItem from '@/page/main/views/tripinfo/comm/cmntItem.vue'

const store = useStore();

const sUser = computed(() => store.state.user.user);
const cmntLst = computed(() => store.state.tripinfo.cmnt.lst);

const activeModalId = ref(null);

// 외부 클릭 이벤트를 처리하는 함수
const onClickOutside = (event) => {
    const modalElements = document.querySelectorAll('.tw-pop-mini, .tw-comment-icon');

    // 클릭된 요소가 모달 내부가 아닌 경우 모달 닫기
    for (const modalElement of modalElements) {
        if (!modalElement.contains(event.target)) {
            activeModalId.value = null;
            break;
        }
    }
}

// 외부 클릭 이벤트 처리
onMounted(() => window.addEventListener('click', onClickOutside));
onBeforeUnmount(() => window.removeEventListener('click', onClickOutside));

</script>