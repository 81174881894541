<template>
        <header class="tw-top">
          <a @click="goBack()" class="tw-top-icon">뒤로가기 아이콘</a>
          <h1 class="tw-label-top" v-text="title"></h1>
        </header>

        <div class="tw-body">

            <!-- 비밀번호 입력 부분 -->
            <div class="tw-body-input-wrap bt-p" :class="{ 'error': !isPswrdValid && bPswrdTouched }">
              <label class="tw-label-default essential bt-6">{{ $t('user.main.SIGNUP.WRITE.PSWRD')}}</label>
              <div class="tw-pw-wrap">
                <input 
                  :type="bShowPswrd ? 'text' : 'password'" 
                  class="tw-input-default" 
                  :class="{ 'error': !isPswrdValid && bPswrdTouched }"
                  autocomplete="new-pswrd" 
                  :placeholder="$t('user.main.SIGNUP.WRITE.PSWRD-PH')" 
                  :aria-label="$t('user.main.SIGNUP.WRITE.PSWRD-PH')"
                  v-model="pswrd"
                  @blur="validatePswrd"
                >
                <span class="tw-pw-icon" tabindex="0" @click="togglePswrdVisibility('pswrd')">
                  {{ bShowPswrd ? '숨기기' : '보이기' }}
                </span>
              </div>
              <label class="tw-label-body f12" v-show="!isPswrdValid && bPswrdTouched">{{ $t('user.main.SIGNUP.WRITE.PSWRD-ERROR')}}</label>
            </div>

            <!-- 비밀번호 확인 부분 -->
            <div class="tw-body-input-wrap" :class="{ 'error': !isPswrdConfirmValid && bPswrdConfirmTouched }">
              <label class="tw-label-default essential bt-6">{{ $t('user.main.SIGNUP.WRITE.PSWRD-CONFIRM')}}</label>
              <div class="tw-pw-wrap">
                <input 
                  :type="bShowPswrdConfirm ? 'text' : 'password'" 
                  class="tw-input-default" 
                  :class="{ 'error': !isPswrdConfirmValid && bPswrdConfirmTouched }"
                  autocomplete="new-pswrd" 
                  :placeholder="$t('user.main.SIGNUP.WRITE.PSWRD-CONFIRM-PH')" 
                  :aria-label="$t('user.main.SIGNUP.WRITE.PSWRD-CONFIRM-PH')"
                  v-model="pswrdConfirm"
                  @blur="validatePswrdConfirm"
                >
                <span class="tw-pw-icon" tabindex="0" @click="togglePswrdVisibility('pswrdConfirm')">
                  {{ bShowPswrdConfirm ? '숨기기' : '보이기' }}
                </span>
              </div>
              <label class="tw-label-body f12" v-show="!isPswrdConfirmValid && bPswrdConfirmTouched">{{ $t('user.main.SIGNUP.WRITE.PSWRD-CONFIRM-ERROR')}}</label>
            </div>

            <div class="tw-body-btn-wrap bottom">
              <button type="submit" class="tw-btn-default primary" aria-label="수정하기 버튼" :disabled="!isFormValid" @click="goResetPswrd">{{ $t('user.main.RESET_PSWRD.BUTTON') }}</button>
            </div>
        </div>
</template>


<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, onBeforeUnmount, watch } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

import { useI18n } from 'vue-i18n';
const { locale,t } = useI18n();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert } = useComm();

const sOtp = computed(() => store.state.user.otp);
const sUser = computed(() => store.state.user.user);

const pswrd = ref('')
const pswrdConfirm = ref('')

const bPswrdTouched = ref(false)
const bPswrdConfirmTouched = ref(false)


const isPswrdValid = computed(() => {
  const pswrdRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/
  return pswrdRegex.test(pswrd.value)
})

const isPswrdConfirmValid = computed(() => {
  return pswrd.value === pswrdConfirm.value
})

const validatePswrd = () => {
  bPswrdTouched.value = true
}

const validatePswrdConfirm = () => {
  bPswrdConfirmTouched.value = true
}

const bShowPswrd = ref(false)
const bShowPswrdConfirm = ref(false)

const togglePswrdVisibility = (field) => {
  if (field === 'pswrd') {
    bShowPswrd.value = !bShowPswrd.value
  } else if (field === 'pswrdConfirm') {
    bShowPswrdConfirm.value = !bShowPswrdConfirm.value
  }
}

const isFormValid = computed(() => {
  return isPswrdValid.value && isPswrdConfirmValid.value
})

const title = sUser.value.userNum ? t('user.main.RESET_PSWRD.TITLE-ISLOGIN') : t('user.main.RESET_PSWRD.TITLE');
const pswrdChgUrl = sUser.value.userNum ? '/user/mod/pswrd' : '/user/reset/pswrd';

const goResetPswrd = async () => {
  const userData = {
  id : sOtp.value.id,
    otpNum : sOtp.value.otpNum,
    newPswrd : pswrd.value,
    otpCode : "RESET_PSWRD",
    userNum : sUser.value.userNum
  }


  const onConfirm = () => {
    navigate({ value: 'mypageMain' });
  }

  await $axios({
      method: 'POST',
      data: userData,
      url: pswrdChgUrl,
  }).then((data) => {
      const resultYn = data.resultYn;
      if (resultYn == 'Y') {
        toggleCommAlert("RESET_PSWRD", "SUCCESS", "ALERT", onConfirm);
      }else{
        const ERROR_CODE = data.resultCode;
        switch(ERROR_CODE){
          case "O001":
          case "M004":
            const goMypageMain = () => {
              navigate({ value: 'mypageMain' }); // 로그인 페이지로 리다이렉트
            }
            errorHandler(ERROR_CODE, goMypageMain);
            break;
          default:
            toggleCommAlert("COMM", "FAILED", "ALERT");
            break;
        }
      }
  }).catch((error) => {
      toggleCommAlert("COMM", "FAILED", "ALERT");
  })
}


</script>