<template>
	<header class="tw-top">
		<a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
		<h1 class="tw-label-top">{{ $t('user.main.ERROR.404.TITLE')}}</h1>
	</header>

	<div class="tw-body p-0">
		<h2 class="tw-body-imgbox"></h2>
		<div class="tw-body-text-wrap">
			<label class="tw-label-default f16" style="margin-bottom: 12px;">{{ $t('user.main.ERROR.404.MESSAGE-1')}} </label>
			<label class="tw-label-default f16">{{ $t('user.main.ERROR.404.MESSAGE-2')}} </label>
		</div>
		<button type="button" class="tw-btn-default primary" @click="navigate({value : 'home'})">{{ $t('user.main.ERROR.404.BUTTON')}}</button>
	</div>
</template>

<script setup>
import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

</script>