<template>

        <header class="tw-top">
          <a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
          <h1 class="tw-label-top">{{ $t('user.main.MYPAGE.BUTTON.MYCMMNT') }}</h1>
        </header>

        <div class="tw-body p-0">
          <div class="tw-align-box">
            <label class="tw-label-body">{{$t('user.main.COMM.COUNT.TOTAL')}} <span>{{ cmmnt.totalCnt }}</span> {{ cmmnt.totalCnt > 1 ? $t('user.main.COMM.COUNT.ITEMS') : $t('user.main.COMM.COUNT.ITEM')  }}</label>
            <div class="tw-select-wrap small none" ref="selOrder" @click="clickOrderLst" >
              <button type="button" class="tw-select-default" aria-label="정렬 선택 버튼">{{ $t('user.main.COMM.COMM-ORDER.' + cmmnt.orderCtgryCode) }} </button>
              <ul class="optionList">
                <li class="optionItem" tabindex="0" @click="clickOrder('001')" >{{ $t('user.main.COMM.COMM-ORDER.001') }}</li>
                <li class="optionItem" tabindex="0" @click="clickOrder('002')">{{ $t('user.main.COMM.COMM-ORDER.002') }}</li>
                <li class="optionItem" tabindex="0" @click="clickOrder('003')">{{ $t('user.main.COMM.COMM-ORDER.003') }}</li>
              </ul>
            </div>
          </div>

          <div class="tw-main-tab-list search">
            <nav>
              <ul>
                <li v-for="(cmmnt, index) in cmmntLst" :key="index" @click="goCmmntDtl(cmmnt.postNum)">
                  <div class="rank">{{ index + 1 }}</div>
                  <div class="rank-img">
                    <a v-if="cmmnt.thmbFullPath" ><img :src="cmmnt.thmbFullPath" alt="랭킹 이미지"></a>
                    <a v-else><img src="/tripwith/assets/images/img-sample(1).png" alt="랭킹 이미지"></a>
                  </div>
                  <div class="rank-wrap">
                    <div class="rank-wrap-detail">
                      <a>
                        <p class="detail-tit">{{ cmmnt.title }}</p>
                        <p class="detail-body">{{ cmmnt.cntn }}</p>
                      </a>
                      <div class="info-txt-bt left">
                            <div class="info-bt-like icon" :class="cmmnt.likeCnt > 0 ? 'on' : ''" tabindex="0">
                              <span class="tw-like-icon">좋아요 아이콘</span>
                              <span class="tw-like-txt">{{ cmmnt.likeCnt > 0 ? cmmnt.likeCnt : '좋아요'}}</span>
                            </div>
                        <div class="info-bt-comm icon" :class="cmmnt.cmntCnt > 0 ? 'on' : ''" tabindex="0">
                          <span class="tw-comm-icon">댓글 아이콘</span>
                          <span class="tw-comm-txt">{{ cmmnt.cmntCnt > 0 ? cmmnt.cmntCnt : '댓글달기'}}</span>
                        </div>
                        <div class="info-bt-view icon" :class="cmmnt.viewCnt > 0 ? 'on' : ''" tabindex="0">
                          <span class="tw-view-icon">조회수 아이콘</span>
                          <span class="tw-view-txt">{{ cmmnt.viewCnt > 0 ? cmmnt.viewCnt : '조회수'}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>

        </div>
</template>


<script setup>
import { ref, reactive, onMounted, onBeforeMount, getCurrentInstance, computed, onBeforeUnmount, watch } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
import { useI18n } from 'vue-i18n';

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

const serverUrl = computed(() => store.getters.getServerUrl);
import modal from "@/page/main/comm/modal.vue";

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert } = useComm();


const pageObj = reactive({totalCnt : 0 //최대 리스트 개수
                    ,totalPage : 100 // 최대 페이지 개수 
                    ,pageNum : 0 //현재 페이지 번호(실제 페이지 넘버 -1)
                    ,pageSize : 1 //보여줄 페이징의 최대 개수
                    ,pageBlockNum : 8 //한 페이지 내에 보여줄 리스트 개수
                    })


//언어 설정 부분
const { locale } = useI18n();

const cmmnt = ref({});
const cmmntLst = ref([]);

onMounted( async () => {  
  goStartSrch();
})

const sUser = computed(() => store.state.user.user);

const goStartSrch = async () => {
  cmmnt.value.pageNum = 0;
  cmmnt.value.pageSize = 30;
  cmmnt.value.boardNum = '3';
  cmmnt.value.orderCtgryCode = '001';
  cmmnt.value.bMyPosts = true;

  cmmntLst.value = await goGetLstCmmnt(cmmnt.value);
}


const selOrder = ref(null);

const clickOrderLst = () => {
  selOrder.value.classList;
  if(selOrder.value.classList.contains('active')){
    selOrder.value.classList.remove('active');
  }else{
    selOrder.value.classList.add('active');
  }
};


const clickOrder = async (pOrder) => {
  cmmnt.value.orderCtgryCode = pOrder;
  goGetLstCmmnt(cmmnt.value);

}

const goGetLstCmmnt = async (pCmmnt) => {
  let cmmntLst;
    await $axios({
        method: 'GET',
        params: pCmmnt,
        url: '/tripwith/post/user',
        showSpinner: false,
    }).then((data) => {
        const resultObj = data.resultObj.content;
        if (resultObj) {
            cmmntLst = resultObj;
            cmmnt.value.totalCnt = data.resultObj.totalElements;
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
    return cmmntLst;
};

const goCmmntDtl = (pPostNum) => {
  navigate({value:'cmmntDtl', params: {postNum:pPostNum} } );
}
</script>