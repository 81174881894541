<template>
      <footer class="tw-footer">
        <a v-if="isCurrentRoute('cmmntMain', 'EXACT')" class="tw-commu-writing" @click="goCmmntWrite" ></a>
        <nav>
          <ul class="footer-tabs">
            <li class="tab-link" :class="{ current: isCurrentRoute('home') }" >
              <a @click="navigate({ value:'home'})">
                <p class="tw-footer-home-icon icon">홈 아이콘</p>
                <p class="tw-footer-txt">Home</p>
              </a>
            </li>
            <li class="tab-link" :class="{ current: isCurrentRoute('tripinfo') }">
              <a @click="navigate({ value:'tripinfo'})">
                <p class="tw-footer-info-icon icon">트립인포 아이콘</p>
                <p class="tw-footer-txt">Trip Info</p>
              </a>
            </li>
            <li class="tab-link" :class="{ current: isCurrentRoute('cmmnt') }" >
              <a @click="navigate({ value:'cmmntMain'})">
                <p class="tw-footer-commu-icon icon">커뮤니티 아이콘</p>
                <p class="tw-footer-txt">Community</p>
              </a>
            </li>
            <li class="tab-link" :class="{ current: isCurrentRoute('mypage') || isCurrentRoute('login') || isCurrentRoute('weather')  }">
              <a @click="navigate({ value:'mypageMain'})">
                <p class="tw-footer-my-icon icon">마이페이지 아이콘</p>
                <p class="tw-footer-txt">MyPage</p>
              </a>
            </li>
          </ul>
        </nav>
      </footer>
</template>

<script setup>

import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();
const currentRoute = ref(route.name); // 현재 라우트 이름을 저장하는 반응형 참조


import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert, loginChk } = useComm();


// 라우터의 경로가 변경될 때마다 currentRoute 업데이트
watch(() => route.name, (newRoute) => {
  currentRoute.value = newRoute;
});


// 현재 경로가 주어진 경로 패턴을 포함하는지 확인하는 함수
function isCurrentRoute(routeName, mode = 'INCLUDE') {
  if (mode === 'EXACT') {
    return currentRoute.value === routeName; // 완벽히 일치하는 경우
  } else if (mode === 'INCLUDE') {
    return currentRoute.value.includes(routeName); // 이름에 포함되는 경우
  }
}

const goCmmntWrite = () => {
  if(!loginChk()){
    //로그인하지 않은 경우 함수의 실행 중단
    return;
  };

  navigate({ value : 'cmmntWrite'})
}

</script>
