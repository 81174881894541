export const getWeatherClass = (sky, pty) => {
    if (pty == 1 || pty == 2 || pty == 5 || pty == 6) {
      return 'rain'; // 비 관련 날씨
    } else if (pty == 3 || pty == 7) {
      return 'snow'; // 눈 관련 날씨
    } else {
      switch (sky) {
        case 1:
          return 'sunny'; // 맑음
        case 3:
          return 'partly-cloudy'; // 부분적으로 흐림
        case 4:
          return 'cloudy'; // 흐림
        default:
          return 'sunny'; // 기본값
      }
    }
  };

export const getRegionName = (region, step) => {
  return region[`${step}_KR`] || region[`${step}_EN`]; // 한국어 또는 영어 이름 반환
};