<template>

        <header class="tw-top">
          <a @click="navigate({value:'mypageMain'})" class="tw-top-icon">뒤로가기 아이콘</a>
          <h1 class="tw-label-top">{{ $t('user.main.SIGNUP.TITLE')}}</h1>
        </header>
        
        <div class="tw-body p-0">
          <div class="tw-resign-wrap">
            <h2 class="tw-body-imgbox">
              <img src="/tripwith/assets/images/img-con.png" alt="회원가입 축하 이미지" width="180" height="180">
            </h2>
            <div class="tw-body-text-wrap">
              <label class="tw-label-default f16" style="margin-bottom: 12px;">{{ $t('user.main.SIGNUP.WELCOME.CNTN-1')}}</label>
              <label class="tw-label-default f16">{{ $t('user.main.SIGNUP.WELCOME.CNTN-2')}}</label>
            </div>
          </div>
          <div class="tw-body-btn-wrap bottom">
            <button type="button" class="tw-btn-default primary" @click="navigate({value : 'mypageMain'})">{{ $t('user.main.SIGNUP.WELCOME.START')}}</button>
          </div>
        </div>

</template>


<script setup>
import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate } = useNavigation();



</script>