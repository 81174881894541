<template>
  <div class="weather-timeline content">
    <div class="swiper tw-ConSwipe">
      <!-- <div class="tw-body-input-wrap">
        <h2 class="tw-label-default bold bt-p">{{$t('user.main.WEATHER.TIMELINE')}}</h2>
      </div> -->

      <Swiper :slides-per-view="'auto'" :space-between="5" :loop="false">
        <template v-for="(item, index) in weatherTimeline" :key="index">
          <SwiperSlide>
            <div class="swiper-slide tw-weather-timeline-item" role="group" aria-label="Weather Timeline">
              <div class="tw-weather-icon" :class="getWeatherClass(item.sky, item.pty)">아이콘</div>
              <div class="tw-time">{{ formatTimeWithLabel(item.fcstDate, item.fcstTime) }}</div>
              <div class="tw-weather-temperature">{{ Math.round(item.tmp) }}</div>
            </div>
          </SwiperSlide>
        </template>
      </Swiper>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue'; // defineProps 추가
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css'; // 기본 Swiper 스타일
import 'swiper/css/pagination'; // Swiper Pagination 스타일
import 'swiper/css/navigation'; // Swiper Navigation 스타일
import { useI18n } from 'vue-i18n'; // i18n 추가
import { getWeatherClass } from '@/util/weatherUtil'; // 유틸리티 함수 이름 변경

const { t } = useI18n(); // i18n 사용을 위한 t 함수 가져오기

const weatherTimeline = ref([]); // 날씨 타임라인 데이터를 저장할 ref

const props = defineProps({
  timelineData: Array, // 부모 컴포넌트에서 전달받은 날씨 타임라인 데이터
});

// 날짜 차이를 계산하여 내일/모레/글피 같은 라벨을 반환하는 함수
const getDayLabel = (fcstDate) => {
  const today = new Date();
  const forecastDate = new Date(
    fcstDate.slice(0, 4), // 년도
    fcstDate.slice(4, 6) - 1, // 월 (0부터 시작)
    fcstDate.slice(6, 8) // 일
  );

  const diffTime = forecastDate.getTime() - today.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 1) return t('user.main.MYPAGE.WEATHER.TOMORROW'); // 내일
  if (diffDays === 2) return t('user.main.MYPAGE.WEATHER.NEXT_DAY'); // 모레
  if (diffDays === 3) return t('user.main.MYPAGE.WEATHER.2_DAYS'); // 글피
  return null; // 오늘 또는 그 이후는 따로 라벨 추가하지 않음
};

// fcstTime을 AM/PM 형식으로 변환하고 00:00일 때 날짜 라벨을 표시하는 함수
const formatTimeWithLabel = (fcstDate, fcstTime) => {
  const hours = parseInt(fcstTime.slice(0, 2), 10);
  const minutes = fcstTime.slice(2);
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // 0을 12로 변환

  const timeString = `${period} ${formattedHours}:${minutes}`;

  // fcstTime이 00:00일 때 날짜 라벨 추가
  if (fcstTime === '0000') {
    const dayLabel = getDayLabel(fcstDate);
    if (dayLabel) {
      return `${dayLabel}`; // 내일, 모레, 글피 라벨과 시간 표시
    }
  }

  return timeString; // 일반 시간 표시
};

onMounted(() => {
  // 타임라인 데이터 설정
  weatherTimeline.value = props.timelineData;
});
</script>

<style scoped>
.weather-timeline {
  /* 필요한 스타일 추가 */
}
.tw-weather-timeline-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}
</style>
