import { loginCheckFromJwt } from '@/services/userService';
import { useStore } from "vuex";


export function useAuth() {
    const store = useStore();

    async function getUserInfoFromJwt() {
        let curUser = await loginCheckFromJwt();
        const curLang = localStorage.getItem('lang');

        if(curUser && Object.keys(curUser).length !== 0){
            let user = {
                joinTypeCode : curUser.joinTypeCode,
                lang : curLang,
                bLoginChk : true,
                userNum : curUser.userNum,
                nick : curUser.nick,
                gndr : curUser.gndr,
                prflImgNum : curUser.prflFileNum,
                prflFileFullPath : curUser.prflFileFullPath,
                birthYear : curUser.birthYear,
                birthMonth : curUser.birthMonth,
                birthDay : curUser.birthDay,
            }
            store.dispatch('user/setUser', user);  
        }
    }

    return { getUserInfoFromJwt };
}