// useNavigation.js
import { useRouter } from 'vue-router';

export function useNavigation() {
  const router = useRouter();

  function navigate({ type = 'name', value, querys, params }) {
    const routeConfig = { [type]: value, query: querys, params: params };
    router.push(routeConfig).then(() => window.scrollTo(0, 0));
  }

  function goBack() {
    const currentRoute = router.currentRoute.value;
    const previousPage = window.history.state?.back;
    const restrictedPages = ['/login/kakao', '/login/naver', '/login/google', '/signup/welcome'];
    if (previousPage && restrictedPages.some(page => previousPage.includes(page))) {
      router.push('/home');
    } else {
      router.back();
    }
  }

  return { navigate, goBack };
}