<template>
        <div id="tw-pop-agree" class="tw-pop-wrap agree" role="dialog" aria-modal="true"  v-show="isAlertOpen">
          <div class="tw-pop-box">
            <div class="scroll-wrap">
              <h2 class="tw-label-default" v-html="props.cmmnt.title"></h2>
              <div class="tw-body-line-wrap">
                <p>라인</p>
              </div>
              <!-- <div class="tw-body-text-wrap">
                <label class="tw-label-default bold">수집 목적</label>
                <label class="tw-label-default">이용자 식별 및 서비스 사용, 본인확인</label>
              </div>
              <div class="tw-body-text-wrap">
                <label class="tw-label-default bold">수집 항목</label>
                <label class="tw-label-default">이메일, 비밀번호, 닉네임, 단말기정보, 서비스 이용기록, 이름, 생년월일, 성별, 전화번호, 통신사업자, 내/외국인여부, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI) </label>
              </div>
              <div class="tw-body-text-wrap">
                <label class="tw-label-default bold">보유기간</label>
                <label class="tw-label-default">회원 탈퇴 시 즉시 파기</label>
              </div> -->


              <div class="tw-body-text-wrap" v-html="cmmnt.cntn">
              </div>
            </div>
            <div class="tw-body-btn-wrap pop-btn">
              <button type="button" class="tw-btn-default" aria-label="동의하고 계속하기 버튼" @click="onConfirm">동의하고 계속하기</button>
            </div>
            <button type="button" class="tw-btn-text" @click="closeAlert" aria-label="취소 아이콘 버튼"> 취소 </button>
          </div>
        </div>
        <div class="tw-pop-bg" v-show="isAlertOpen">모달bg</div>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue';
import { createLogger, useStore } from "vuex";

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const store = useStore();

const isAlertOpen = computed(() => store.state.alert.agree.show);
const alertStore = computed(() => store.state.alert.agree);
const boardNum = computed(() => store.state.alert.agree.boardNum);

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert } = useComm();

const props = defineProps({
  cmmnt: {
    type: Object
  }
});

const closeAlert = () => {
  store.dispatch('alert/closeAlert', {
    stateName: 'agree'
  });
}

const onConfirm = async () => {
  if (alertStore.value.event) {
    alertStore.value.event();
  }
};

const updateCmmnt = async () => {
  cmmnt.value.lang = locale.value;
  cmmnt.value.boardNum = boardNum.value;

  if(!boardNum.value){
    return false;
  }

  cmmnt.value = await goGetLegalDocument();
}


</script>

