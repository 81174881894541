<template>
    <div class="tw-wrap" :class=" !route.name.includes('signup') 
                                  && !route.name.includes('reset') ? 'h-none' : '' " >
      <router-view v-if="isInitialized"></router-view>
        <div v-else>
            <sppiner></sppiner>
        </div>
        <user-footer v-if="showFooter" ></user-footer>
        <sppiner v-if="showSppiner" ></sppiner>
        <comm-alert></comm-alert>
    </div>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue';
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import { useAuth } from '@/page/main/composables/useAuth';
const { getUserInfoFromJwt } = useAuth();

import userFooter from "@/page/main/comm/footer.vue";
import sppiner from "@/page/main/comm/sppiner.vue";
import commAlert from "@/page/main/comm/commAlert.vue";
import weatherRegData from '@/assets/data/weather_reg.json';
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

//vuex 설정
const store = useStore();
const position = ref(null);

const route = useRoute();

const showFooter = computed(() => route.name !== 'tripinfoDtl'
                                  && route.name !== 'tripinfoCmnt'
                                  && !route.name.includes('Write')
                                  && !route.name.includes('Dtl')
                                  && !route.name.includes('Detail')
                                  && !route.name.includes('Cmnt')
                                  && !route.name.includes('signup')
                                  && !route.name.includes('Wthd') 
                                  && !route.name.includes('reset') 
                                  && !route.name.includes('exchangeRates') );

const showSppiner = computed(() => store.state.comm.showSppiner);

const bLoginChk = computed(() => store.state.user.user.bLoginChk);
const authorization = localStorage.getItem("authorization");

import { useI18n } from 'vue-i18n';
const { locale } = useI18n();

// 지원 언어 정보
const supportedLangs = ['en', 'ko']; // 영어, 한국어
const setLanguage = () => {
  // 브라우저의 언어 가져오기
  let browserLang = navigator.language || navigator.userLanguage;
  
  // 언어 코드를 두 글자로 표준화 (예: 'en-US'를 'en'으로)
  browserLang = browserLang.split('-')[0];

  // 브라우저 언어가 지원되는 언어 목록에 있는지 확인
  let lang = supportedLangs.includes(browserLang) ? browserLang : 'en'; // 기본값은 영어

  // localStorage에 언어 정보 저장
  localStorage.setItem('lang', lang);

  console.log(`User language set to: ${lang}`);

  return lang;
};

//토큰 인증 정보가 있고 bLoginChk가 false라면 현재 토큰이 유효한지 확인한다.

const isInitialized = ref(false);

const initialize = async () => {
  try {
    // 언어 설정
    let curLang = localStorage.getItem('lang');
    if(!curLang){
      curLang = setLanguage();
    }
    locale.value = curLang;

    // 사용자 인증 체크
    if(!bLoginChk.value && authorization){
      await getUserInfoFromJwt();
    }

    // 위치 정보 초기화
    await updatePositionAndAddress();

    // 모든 초기화가 완료되면 isInitialized를 true로 설정
    isInitialized.value = true;
  } catch (error) {
    console.error('초기화 중 오류 발생:', error);
    isInitialized.value = true; // 에러가 발생해도 앱은 실행되도록 함
  }
};

onMounted( async () => {
  initialize();
})

// 위치 정보를 가져오는 함수
const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};


// 주소 정보를 가져오는 함수
const getAddressInfo = async (lat, lot) => {
  let address = {};
  let map = {
    lat: lat,
    lot: lot
  };

  await $axios({
      method: 'GET',
      params: map,
      url: '/map/address/latlot',
  }).then((data) => {
      const resultObj = data.resultObj;
      if (resultObj) {
        address = resultObj;
      }
  }).catch((error) => {
      alert("에러 발생");
  })
  return address;  
};

// 위치 정보 갱신 및 주소 정보 조회
const updatePositionAndAddress = async () => {
  try {
    const pos = await getCurrentPosition();
    position.value = {
      latitude: pos.coords.latitude,
      longitude: pos.coords.longitude
    };

    store.dispatch('location/setLocation', position.value);

    const addressInfo = await getAddressInfo(position.value.latitude, position.value.longitude);
    if (addressInfo) {
      const { step1, step2 } = parseAddressName(addressInfo.addressName);
      const region = findRegionByAddress(step1, step2);

      store.dispatch('location/setAddressInfo', region);
    }
  } catch (error) {
    console.error('위치 정보 갱신 실패:', error);
  }
};

// 위치 정보 변경 감지
watch(position, async (newPosition, oldPosition) => {
  if (newPosition && (!oldPosition || 
      newPosition.latitude !== oldPosition.latitude || 
      newPosition.longitude !== oldPosition.longitude)) {
    const addressInfo = await getAddressInfo(newPosition.latitude, newPosition.longitude);
    if (addressInfo) {
      const { step1, step2 } = parseAddressName(addressInfo.addressName);
      const region = findRegionByAddress(step1, step2);

      store.dispatch('location/setAddressInfo', region);
    }
  }
});

//제공받은 주소 정보 중 두 번째 스탭까지만 사용
const parseAddressName = (addressName) => {
  if(!addressName) return { step1: '', step2: '' };
  // 예: "서울특별시 강남구 역삼동"
  const parts = addressName.split(' ');
  const step1 = parts[0]; // 도/시
  const step2 = parts[1]; // 시/군/구
  return { step1, step2 };
};

const findRegionByAddress = (step1, step2) => {
  return weatherRegData.find((region) => {
    return region.STEP1_KR === step1 && region.STEP2_KR === step2;
  });
};

const loginVer = async () => {
  let tmpUser = {};
  await $axios({
      method: 'POST',
      data: '',
      url: '/user/chkToken',
  }).then((data) => {
      const resultObj = data.resultObj;
      if (resultObj) {
          tmpUser = resultObj;
      }
  }).catch((error) => {
      alert("에러 발생");
  })
  return tmpUser;
}
</script>