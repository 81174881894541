<template>

          <header class="tw-top">
            <a class="tw-top-icon" @click="goBack">뒤로가기 아이콘</a>
            <h1 class="tw-label-top">{{ $t('user.main.SIGNUP.TITLE') }}</h1>
          </header>
    
          <div class="tw-body p-30-16">
            <form>
              <div class="tw-body-logbox">
                <h2> <img src="/tripwith/assets/images/TripWith-logo.svg" alt="트립위드 로고" width="115" height="30"> </h2>
              </div>
              <div class="tw-body-agree-wrap p-30-4">
                <label for="check8" class="tw-check-default medium f14 m-6">
                  <input type="checkbox" id="check8" v-model="allChked" @change="toggleAllChk">
                  <span class="on top0" tabindex="0"></span>
                  {{ $t('user.main.SIGNUP.AGREE.ALL-AGREE') }}
                </label>
                <label class="tw-label-default f13 m-0">{{ $t('user.main.SIGNUP.AGREE.ALL-AGREE-CNTN-1') }}
                  <br><br>
                  {{ $t('user.main.SIGNUP.AGREE.ALL-AGREE-CNTN-2') }}
                </label>
  
                <div class="tw-agree-bt-wrap">
                  <div class="tw-003-col3">
                    <div class="tw-003-col2">
                      <label for="check3" class="tw-check-default medium p-0-30">
                        <input type="checkbox" id="check3" v-model="sSignup.bAge" @change="updateAllChecked">
                        <span class="on top0" tabindex="0"></span>
                        {{ $t('user.main.SIGNUP.AGREE.AGE') }}
                      </label>
                      <span class="tw-text-essential">({{ $t('user.main.SIGNUP.AGREE.REQUIRED')}})</span>
                    </div>
                    <!-- <button type="button" class="tw-btn-text f12" @click="goAgreeAlert('', 'bAge')"> {{ $t('user.main.SIGNUP.AGREE.VIEW')}} </button> -->
                  </div>
                  <div class="tw-003-col3">
                    <div class="tw-003-col2">
                      <label for="check4" class="tw-check-default medium">
                        <input type="checkbox" id="check4" v-model="sSignup.bService" @change="updateAllChecked">
                        <span class="on top0" tabindex="0"></span>
                        {{ $t('user.main.SIGNUP.AGREE.TNC')}}
                      </label>
                      <span class="tw-text-essential">({{ $t('user.main.SIGNUP.AGREE.REQUIRED')}})</span>
                    </div>
                    <button type="button" class="tw-btn-text f12" @click="goAgreeAlert(tncNum, 'bService')"> {{ $t('user.main.SIGNUP.AGREE.VIEW')}} </button>
                  </div>
                  
                  <div class="tw-003-col3">
                    <div class="tw-003-col2">
                      <label for="check5" class="tw-check-default medium">
                        <input type="checkbox" id="check5" v-model="sSignup.bPersonal" @change="updateAllChecked">
                        <span class="on top0" tabindex="0"></span>
                        {{ $t('user.main.SIGNUP.AGREE.PIPA')}}
                      </label>
                      <span class="tw-text-essential">({{ $t('user.main.SIGNUP.AGREE.REQUIRED')}})</span>
                    </div>
                    <button type="button" class="tw-btn-text f12" @click="goAgreeAlert(pipaNum, 'bPersonal')"> {{ $t('user.main.SIGNUP.AGREE.VIEW')}} </button>
                  </div>
                  
                  <div class="tw-003-col3">
                    <div class="tw-003-col2">
                      <label for="check6" class="tw-check-default medium">
                        <input type="checkbox" id="check6" v-model="sSignup.bLocation" @change="updateAllChecked">
                        <span class="on top0" tabindex="0"></span>
                        {{ $t('user.main.SIGNUP.AGREE.LOCATION')}}
                      </label>
                      <span class="tw-text-essential">({{ $t('user.main.SIGNUP.AGREE.OPTIONAL')}})</span>
                    </div>
                  </div>
                  
                  <div class="tw-003-col3">
                    <div class="tw-003-col2">
                      <label for="check7" class="tw-check-default medium">
                        <input type="checkbox" id="check7" v-model="sSignup.bMarketing" @change="updateAllChecked">
                        <span class="on top0" tabindex="0"></span>
                        {{ $t('user.main.SIGNUP.AGREE.MARKETING')}}
                      </label>
                      <span class="tw-text-essential">({{ $t('user.main.SIGNUP.AGREE.OPTIONAL')}})</span>
                    </div>
                  </div>
                </div>
  
              </div>
              <div class="tw-body-btn-wrap bottom">
                <button type="submit" class="tw-btn-default" :class="isRequiredChecked ? 'primary' : null" aria-label="확인 버튼" :disabled="!isRequiredChecked" @click="goSignupMain">{{ $t('user.main.SIGNUP.AGREE.CHK')}}</button>
              </div>
            </form>
          </div>
          <agree-alert :cmmnt="cmmnt" />

</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import agreeAlert from '@/page/main/comm/agreeAlert.vue';

const bLoginChk = computed(() => store.state.user.user.bLoginChk);
const sSignup = computed(() => store.state.user.signup);

const tncNum = ref(process.env.VUE_APP_BOARD_TNC)
const pipaNum = ref(process.env.VUE_APP_BOARD_PIPA)

import { useI18n } from 'vue-i18n';
const { locale } = useI18n();

const cmmnt = ref({});

const tnc = ref({
  title: "",
  cntn : "",
});
const pipa = ref({
  title: "",
  cntn : "",
});


onMounted(() => {
    if(bLoginChk.value){
      navigate({value:'mypageMain'})
    }

    cmmnt.value.lang = locale.value;
})

const allChked = ref(false);

const updateAllChecked = () => {
  allChked.value = Object.values(sSignup.value).every(v => v);
};

const toggleAllChk = () => {
  Object.keys(sSignup.value).forEach(key => {
    sSignup.value[key] = allChked.value;
  });
};

// 필수 항목이 모두 체크되었는지 확인하는 computed 속성
const isRequiredChecked = computed(() => {
  return sSignup.value.bAge && sSignup.value.bService && sSignup.value.bPersonal;
});

// 확인 버튼 클릭 핸들러
const goSignupMain = () => {
  navigate({value:'signupMain'})
};

const toggleAgreeAlert = (title, code, boardNum, event) => {
    store.dispatch('alert/setAlert', {
        stateName: "agree",
        title,
        code,
        show: true,
        boardNum,
        event,
    });
}

const goAgreeAlert = async (pBoardNum, code) => {
  const onConfirm = () => {
    store.dispatch('user/setSignup', {
      name : code,
      value : true
    });

    store.dispatch('alert/closeAlert', {
      stateName: 'agree'
    });
  }

  if(pBoardNum == tncNum.value && tnc.value.title == ''){
    tnc.value = await goGetLegalDocument(tncNum.value);  
  }else if(pBoardNum == pipaNum.value && pipa.value.title == ''){
    pipa.value = await goGetLegalDocument(pipaNum.value);
  }
  
  if(pBoardNum == tncNum.value){
      cmmnt.value = tnc.value;
  }else{
      cmmnt.value = pipa.value;
  }

  toggleAgreeAlert('AGE', code, pBoardNum, onConfirm);
}


const goGetLegalDocument = async (pBoardNum) => {
    let tmpCmmnt = new Object;
    const pCmmnt = {
      boardNum : pBoardNum,
      lang : locale.value
    }

    await $axios({
        method: 'GET',
        params: pCmmnt,
        showSpinner: false,
        url: `/tripwith/post/legalDocument`,
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpCmmnt = resultObj;
        }
    }).catch((error) => {
      toggleCommAlert("COMM", "FAILED");
    })
    return tmpCmmnt;
};

</script>