import axiosWrpr from '@/util/axiosHelper';

export const loginCheckFromJwt = async () => {
    try {
      const response = await axiosWrpr({
        method: 'GET',
        url: '/token/init',
      });
      return response.resultObj || null;
    } catch (error) {
      console.error('JWT 토큰 확인 중 오류 발생:', error);
      throw error;
    }
  };

export const goGetFus = async (nx, ny) => {
  try {
    const response = await axiosWrpr({
        method: 'GET',
        params: { nx, ny },
        url: '/weather/fus',
      });
  
      return response.resultObj || {};
      
    } catch (error) {
      console.error('Error fetching weather data:', error);
      toggleCommAlert('COMM', 'FAILED');
      return {};
    }
  };

  export const goGetUserRegions = async () => {
    try {
      const response = await axiosWrpr({
          method: 'GET',
          url: '/weather/user',
        });
    
        const resultObj = response.resultObj;
        if (!resultObj) return [];

        return resultObj;
        
      } catch (error) {
        console.error('Error fetching weather data:', error);
        toggleCommAlert('COMM', 'FAILED');
        return [];
      }
    };