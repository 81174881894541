import cnst from '@/util/authConstants.js'

export default {
    namespaced: true,
    state: {
        user: {
            joinTypeCode : cnst.SMPL.NORMAL.USER_REG_TYPE_CODE,
            lang : 'en',
            bLoginChk : false,
            userNum : null,
            nick : null,
            gndr : "000",
            prflImgNum : "",
            birthYear : '2024',
            birthMonth : '03',
            birthDay : '04',
            prflFileFullPath : "",
        },
        tmpUser: {
            joinTypeCode : cnst.SMPL.NORMAL.USER_REG_TYPE_CODE,
            lang : 'en',
            bLoginChk : false,
            userNum : null,
            nick : null,
            gndr : "000",
            prflImgNum : "",
            birthYear : '2024',
            birthMonth : '03',
            birthDay : '04',
            prflFileFullPath : "",
        },
        signup : {
            bAge : false,
            bService : false,
            bPersonal : false,
            bLocation : false,
            bMarketing : false,
        },
        otp : {
            id : null,
            otpNum : null,
        }
    
    },
    getters: {},
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
        clearUser(state, payload) {
            const clearUser = {
                joinTypeCode : null,
                lang : 'en',
                bLoginChk : false,
                userNum : null,
                nick : null,
                gndr : "000",
                prflImgNum : "",
                birthYear : '2024',
                birthMonth : '03',
                birthDay : '04',
                prflFileFullPath : "",
            }
            state.user = clearUser;
        },
        setTmpUser(state, payload) {
            state.tmpUser = payload;
        },
        setUserFromModal(state, payload) {
            state[payload.stateName][payload.key] = payload.value;
        },
        setLoginChk(state, payload) {
            state.user.bLoginChk = payload;
        },
        setLang(state, payload){
            state.user.lang = payload;
        },
        setSignup(state, payload){
            state.signup[payload.name] = payload.value;
        },
        setOtp(state, payload) {
            state.otp = payload;
        },
    },
    actions: {
        setUser({ state, commit }, payload) {
            commit('setUser', payload);
        },
        clearUser({ state, commit }, payload) {
            commit('clearUser', payload);
        },
        setTmpUser({ state, commit }, payload) {
            commit('setTmpUser', payload);
        },
        setUserFromModal({ state, commit }, payload) {
            commit('setUserFromModal', payload);
        },
        setLoginChk({ state, commit }, payload) {
            commit('setLoginChk', payload);
        },
        setLang({ state, commit }, payload) {
            commit('setLang', payload);
        },
        setSignup({ state, commit }, payload) {
            commit('setSignup', payload);
        },
        setOtp({ state, commit }, payload) {
            commit('setOtp', payload);
        },
    },
};
