
import cnst from '@/util/authConstants';
const frontUrl = process.env.VUE_APP_FRONT_URL;

export function clickNaverSmplLoginBtn() {
    const naverUrl = 'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id='
        + cnst.SMPL.NAVER.CLIENT_ID
        + '&state=STATE_STRING&redirect_uri='
        + frontUrl + '/' + cnst.SMPL.NAVER.CALLBACK_URL;

    window.location.href = naverUrl;
}

export function clickKakaoSmplLoginBtn() {
    const kakaoUrl = 'https://kauth.kakao.com/oauth/authorize?client_id='
        + cnst.SMPL.KAKAO.CLIENT_ID
        + '&state=STATE_STRING&redirect_uri='
        + frontUrl + '/' + cnst.SMPL.KAKAO.CALLBACK_URL
        + '&response_type=code';

    window.location.href = kakaoUrl;
}

export function clickGoogleSmplLoginBtn() {
    const googleUrl = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=' 
        + cnst.SMPL.GOOGLE.CLIENT_ID
        + '&redirect_uri='
        + frontUrl + '/' + cnst.SMPL.GOOGLE.CALLBACK_URL
        + '&response_type=code'
        + '&scope=email profile';
    window.location.href = googleUrl;
}