import axiosWrpr from '@/util/axiosHelper';

export const goGetPostLst = async (params) => {
  const response = await axiosWrpr({
    method: 'GET',
    params,
    url: '/tripwith/post/user',
  });
  return response;
};

export const goGetPost = async (pPostNum) => {
  const response = await axiosWrpr({
    method: 'GET',
    url: `/tripwith/post/user/${pPostNum}`,
  });
  return response.resultObj || null;
}

export const goGetCmntLst = async (pCmnt) => {
  const response = await axiosWrpr({
    method: 'GET',
    params: pCmnt,
    url: '/tripwith/cmnt/user',
  });
  return response.resultObj || null;
};

export const regPostCmnt = async (cmntData) => {
  const response = await axiosWrpr({
    method: 'POST',
    data: cmntData,
    url: '/tripwith/cmnt/user',
  });
  return response;
};

export const delPost = async (postNum) => {
  const response = await axiosWrpr({
    method: 'DELETE',
    url: `/tripwith/post/user/${postNum}`,
  });
  return response;
};

export const togglePostLike = async (postNum, isLiked) => {
  const response = await axiosWrpr({
    method: isLiked ? 'DELETE' : 'POST',
    url: `/tripwith/post/${postNum}/like`,
    showSpinner: false,
  });
  return response;
};

export const togglePostDibs = async (postNum, isDibsed) => {
  const response = await axiosWrpr({
    method: isDibsed ? 'DELETE' : 'POST',
    url: `/tripwith/post/${postNum}/dibs`,
    showSpinner: false,
  });
  return response;
};

export const submitPollVote = async (pollNum, pollOptionNums) => {
  const response = await axiosWrpr({
    method: 'POST',
    url: '/poll/vote',
    data: {
      pollNum,
      pollOptionNums
    }
  });
  return response;
};