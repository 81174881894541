<template>

        <header class="tw-top">
          <a class="tw-top-icon" @click="goBack">뒤로가기 아이콘</a>
          <h1 class="tw-label-top">{{ $t('user.main.SIGNUP.TITLE')}}</h1>
        </header>

        <div class="tw-body">
          
          <div class="tw-body-text-wrap p-0">
            <h2 class="tw-label-default f24" >{{ $t('user.main.' + props.otpCode + '.OTP.TITLE')}}</h2>
            <label class="tw-label-default m-0">{{ $t('user.main.' + props.otpCode + '.OTP.CNTN-1')}}</label>
            <label class="tw-label-default m-bt-30">{{ $t('user.main.' + props.otpCode + '.OTP.CNTN-2')}}</label>
          </div>

          <div class="tw-body-input-wrap error">
            <div class="tw-input-wrap">
              <input type="text" class="tw-input-default" 
              :placeholder="$t('user.main.' + props.otpCode + '.OTP.OTP-PH')"
              :aria-label="$t('user.main.' + props.otpCode + '.OTP.OTP-PH')" 
              ref="isncNum"
               maxlength="5">
              <span class="tw-input-icon" @click="delIsncNum">input아이콘</span>
            </div>
            <label class="tw-label-body f12 error" v-show="bOtpError">{{$t('user.main.' + props.otpCode + '.OTP.RESULT-CODE.' + otpErrorCode)}}</label>
          </div>

          <div class="tw-body-btn-wrap bottom">
            <button type="button" class="tw-btn-default primary" aria-label="입력하기 버튼" @click="goChkOtp">{{ $t('user.main.' + props.otpCode + '.OTP.INPUT')}}</button>
          </div>
        </div>
</template>


<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance, computed } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert } = useComm();

const sOtp = computed(() => store.state.user.otp);
const isncNum = ref(null);

const bOtpError = ref(false);
const otpErrorCode = ref(null);

const props = defineProps({
  otpCode: {
    type: String,
    required: true
  }
});

// 컴포넌트 마운트 시 otp 객체 유효성 검사
onMounted(() => {
  checkOtpValidity();
});

// otp 객체 유효성 검사 함수
const checkOtpValidity = () => {
  if (!sOtp.value || !sOtp.value.otpNum) {
    // otp 객체가 없거나 otpNum이 비어있는 경우
    console.log("Invalid OTP access detected. Redirecting to login page.");
    navigate({ value: 'mypageMain' }); // 로그인 페이지로 리다이렉트
  }
};

const delIsncNum = () => {
  isncNum.value.value = '';
}

const nextRoute = props.otpCode == "SIGNUP" ? "signupWrite" : "resetPswrd";

const goChkOtp = async () => {

  bOtpError.value = false;

  const otpData = {
    id : sOtp.value.id,
    otpNum : sOtp.value.otpNum,
    isncNum : isncNum.value.value,
    otpCode : props.otpCode
  }

  await $axios({
      method: 'POST',
      data: otpData,
      url: `/user/otp/${otpData.otpNum}`,
  }).then((data) => {
      const resultObj = data.resultObj;
      if (data.resultYn == 'Y') {
        if(resultObj.resultCode != 'VALID'){
          bOtpError.value = true;
          otpErrorCode.value = resultObj.resultCode;
        }else{
          let otp = {};
          otp.id = resultObj.id;
          otp.otpNum = resultObj.otpNum;
          store.dispatch("user/setOtp", otp);

          navigate({value: nextRoute})
        }
      }else{
        const ERROR_CODE = data.resultCode;
        switch(ERROR_CODE){
          case "M004":
            const goMypageMain = () => {
              navigate({ value: 'mypageMain' }); // 로그인 페이지로 리다이렉트
              store.dispatch('alert/setAlert', {
                  name: 'comm',
                  show: false
              });
            }
            errorHandler(ERROR_CODE, goMypageMain);

            break;
          default:
            toggleCommAlert("COMM", "FAILED", "ALERT");
            break;
        }
      }
  }).catch((error) => {
      toggleCommAlert("COMM", "FAILED", "ALERT");
  })
}

</script>