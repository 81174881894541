<template>

        <header class="tw-top search">
          <a class="tw-top-icon" @click="goBack">뒤로가기 아이콘</a>
          <div class="tw-top-search">
            <form class="tw-search-wrap" role="search">
              <input type="search" class="tw-input-default tw-input-search" placeholder="어떤 가이드가 필요하세요?" aria-label="가이드 통합검색" v-model="sFilter.keyword">
              <span class="tw-search-icon" tabindex="0">검색 아이콘</span>
            </form>
          </div>
        </header>

        <div class="tw-body p-0">
          <div class="tw-body-input-wrap sw-padding">
            <!-- <h2 class="tw-label-default bold bt-p" style="margin: 0;">추천 키워드</h2>
            <div class="tw-body-category b-0">
              <div class="swiper txtSwiper tw-swiper-key swiper-initialized swiper-horizontal swiper-backface-hidden">
                <div class="swiper-wrapper" id="swiper-wrapper-1fa77efdd1047f4f5" aria-live="polite">
                    <div class="swiper-slide tw-swiper-txt tw-swiper-active swiper-slide-active" role="group" aria-label="1 / 10" tabindex="0">전체</div>
                    <div class="swiper-slide tw-swiper-txt swiper-slide-next" role="group" aria-label="2 / 10" tabindex="0">인천공항</div>
                    <div class="swiper-slide tw-swiper-txt" role="group" aria-label="3 / 10" tabindex="0">숙박세일페스타</div>
                    <div class="swiper-slide tw-swiper-txt" role="group" aria-label="4 / 10" tabindex="0">항공권할인</div>
                    <div class="swiper-slide tw-swiper-txt" role="group" aria-label="5 / 10" tabindex="0">서울맛집</div>
                    <div class="swiper-slide tw-swiper-txt" role="group" aria-label="6 / 10" tabindex="0">제주도</div>
                    <div class="swiper-slide tw-swiper-txt" role="group" aria-label="7 / 10" tabindex="0">인천공항</div>
                    <div class="swiper-slide tw-swiper-txt" role="group" aria-label="8 / 10" tabindex="0">숙박세일페스타</div>
                    <div class="swiper-slide tw-swiper-txt" role="group" aria-label="9 / 10" tabindex="0">항공권할인</div>
                    <div class="swiper-slide tw-swiper-txt" role="group" aria-label="10 / 10" tabindex="0">서울맛집</div>
                </div>
                <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
              </div>
            </div> -->
          </div>
          <form style="padding: 0 16px;">
            <!-- <div class="tw-body-input-wrap">
              <h2 class="tw-label-default bold bt-p">지역</h2>
              <div class="tw-select-wrap">
                <button type="button" class="tw-select-default" aria-label="지역 선택 버튼" aria-haspopup="true">서울특별시</button>
              </div>
            </div> -->
            <div class="tw-body-input-wrap">
              <h2 class="tw-label-default bold bt-p">카테고리</h2>
              <div class="tw-select-wrap">
                <button type="button" class="tw-select-default" aria-label="카테고리 선택 버튼" @click="clickCmmntCtgry" >{{ $t('user.main.CMMNT.POST-CTGRY.' + sFilter.postCtgryCode) }}</button>
              </div>
            </div>

            <!-- <div class="tw-body-input-wrap">
              <h2 class="tw-label-default bold bt-p">업로드 일자</h2>
              <div class="tw-compo-wrap">
                <div class="tw-select-wrap">
                  <button  type="button" class="tw-select-default" aria-label="업로드 일자 선택 버튼">최근 일주일</button>
                </div>
                <div class="tw-date-box">
                  <input type="text" id="datepicker" class="tw-input-default" autocomplete="off" aria-label="업로드 일자 입력">
                </div>
              </div>
            </div> -->

          </form>

          <div class="tw-body-btn-wrap m-t-20 p-0-16" style="margin-top: 20px;">
            <button type="submit" class="tw-btn-default primary" aria-label="확인 버튼" @click="srchCmmnt">확인</button>
          </div>
        </div>

        <modal />
</template>


<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance, reactive,computed } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
import { useI18n } from 'vue-i18n';

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer } = useComm();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import TRIPINFO_CNST from '@/page/main/views/cmmnt/js/cmmntCnst'

const clickCmmntCtgry = () => {
  toggleCommLayer(TRIPINFO_CNST.FILTER.CTGRY);
}
//components
import modal from "@/page/main/comm/modal.vue";

const sSrch = computed(() => store.state.cmmnt.srch);

const sFilter = computed(() => store.state.cmmnt.filter);

const srchCmmnt = () => {
  store.dispatch('cmmnt/setCmmntSrch', sFilter.value);
  navigate({value:'cmmntSrch'});
}

onMounted( async () => {
})


</script>