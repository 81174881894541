<template>

  <main>
    <div class="tw-wrap h-none">

      <header class="tw-top icon">
        <h1 class="tw-label-top">Community</h1>
        <div class="tw-top-right">
          <a class="tw-right-icon" @click="navigate({value:'cmmntFilter'})">검색 아이콘</a>
          <!-- <a href="../3step/PB_NC_002.html" class="tw-alarm-icon">알림 아이콘</a> -->
        </div>
      </header>

      <div class="tw-body p-0">
        <main-filter-swiper />
        <div class="tw-main-tab p-0">
          <top-3 />
          
          <div class="tw-commu-tab-list">
            <ul>
              <li v-for="(post, index) in postLst" :key="index">
                <div class="tw-info-detail">
                  <div class="tw-info-detail-top">
                    <div class="detail-top-info-wrap">
                      <div class="detail-top-info-img">
                        <img :src="post.fullPath" alt="닉네임 프로필 이미지">
                      </div>
                      <h2 class="detail-top-info-id">{{ post.nick }}
                        <!-- <span class="id-badge">
                          <img src="/tripwith/assets/images/img-level-total-2.svg" alt="뱃지 이미지">
                        </span> -->
                      </h2>
                      <div class="detail-top-info-date">{{ convertTimestampToFormattedDate(post.regDt) }}</div>
                    </div>
                    <div class="detail-top-icon-wrap">
                      <span :class="['tw-bookmark-icon', { on: post.dibsedByUser }]" @click="handlePostDibs(post)">북마크 아이콘</span>
                      
                      <template v-if="post.userNum == sUser.userNum">
                        <span class="tw-more-icon" aria-haspopup="true" tabindex="0" @click="clickMoreIcon(post.postNum)">더보기 아이콘</span>
                        <div class="tw-pop-mini"  role="dialog" aria-modal="true" v-show="isActiveModal(post.postNum).value" v-click-outside="onClickOutside">
                          <ul>
                            <li><a @click="goCmmnt('cmmntWrite', post.postNum)">{{$t('user.main.COMM.UPD')}}</a></li>
                            <li><a @click="delCmmntConfirm(post)" >{{$t('user.main.COMM.DEL')}}</a></li>
                          </ul>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="tw-info-detail-body">

                    <div class="detail-body-txt">

                      <h2>{{ post.title }}</h2>
                      {{ post.cntn }}
          
                    </div>

                    <div class="detail-body-img-wrap">
                      <ul>
                        <li v-for="(file, index) in post.postFileModelLst" :key="index">
                          <div> <img :src="file.fullPath" alt="첨부 이미지"> </div>
                        </li>
                      </ul>
                    </div>
                    <div v-if="post.pollNum" class="detail-vote-box-wrap">
                      <button type="button" class="tw-vote-btn" aria-label="투표 진행중 버튼" @click="goCmmnt('cmmntDtl', post.postNum)">
                        <span class="tw-vote-ing-icon">투표 아이콘</span>
                        <span class="tw-icon-text bold">{{post.pollQuestion}}</span>
                        <span class="tw-icon-text"></span>
                      </button>
                    </div>

                  </div>
                  <div class="tw-info-detail-btn">
                    <div
                      :class="['info-bt-like icon', { on: post.likedByUser }]"
                      tabindex="0"
                      @click="handlePostLike(post)"
                    >
                      <span class="tw-like-icon">좋아요 아이콘</span>
                      <span class="tw-like-txt">{{ post.likeCnt > 0 ? post.likeCnt : $t('user.main.CMMNT.MAIN.LIKE')}}</span>
                    </div>
                    <div class="info-bt-comm icon" :class="post.cmntCnt > 0 ? 'on' : ''" tabindex="0" @click="goCmmnt('cmmntDtl', post.postNum)">
                      <span class="tw-comm-icon">댓글 아이콘</span>
                      <span class="tw-comm-txt">{{ post.cmntCnt > 0 ? post.cmntCnt : $t('user.main.CMMNT.MAIN.CMNT')}}</span>
                    </div>
                    <div class="info-bt-share icon" tabindex="0">
                      <span class="tw-share-icon">공유하기 아이콘</span>
                      <span class="tw-share-txt">{{$t('user.main.CMMNT.MAIN.SHARE')}}</span>
                    </div>
                  </div>
                </div>
              </li>
              
            </ul>
          </div>

        </div>
      </div>

    </div>
  </main>
</template>

<script setup>

import { ref, onMounted, onUnmounted, computed, reactive, nextTick, watch } from 'vue'
import { useStore } from "vuex";

import { usePost } from '@/page/main/composables/usePost';
import { useNavigation } from '@/page/main/composables/useNavigation';
import { useComm } from '@/page/main/composables/comm';
import { useInfiniteScroll } from '@/page/main/composables/useInfiniteScroll';

const { navigate } = useNavigation();
const { toggleCommAlert } = useComm();

import top3 from '@/page/main/views/cmmnt/comm/top3.vue'
import mainFilterSwiper from '@/page/main/views/cmmnt/comm/mainFilterSwiper.vue';

//vuex 설정
const store = useStore();
const pageObj = reactive({pageSize : 10})

const post = ref({});

const { 
  posts: postLst,
  pageInfo,
  loadInitialPosts,
  loadMorePosts,
  handlePostLike,
  handlePostDibs,
  handleDelPost 
} = usePost();


const sUser = computed(() => store.state.user.user);
const sSrch = computed(() => store.state.cmmnt.srch);

async function loadInitialData() {
  const params = {
    boardNum: '3',
    orderCtgryCode: '003',
    pageSize: pageObj.pageSize
  };
  await loadInitialPosts(params);
}

// 컴포넌트 활성화 상태 추적
const isComponentActive = ref(true);

// 스크롤 이벤트 수정
const loadMore = async () => {
    // 컴포넌트가 비활성화 상태면 로딩 중단
    if (!isComponentActive.value) return;
    
    const params = {
        boardNum: '3',
        orderCtgryCode: '003',
        pageSize: pageObj.pageSize,
        postCtgryCode: post.value.postCtgryCode
    };
    await loadMorePosts(params);
};

// 컴포넌트 마운트/언마운트 처리 수정
onMounted(async () => {
    isComponentActive.value = true;
    await loadInitialData();
    isInitialDataLoaded.value = true;

    nextTick(() => {
        if (isComponentActive.value) {
            document.addEventListener('scroll', scrollHandler);
            scrollHandler({ target: document });
        }
    });
});

onUnmounted(() => {
    isComponentActive.value = false;
    document.removeEventListener('scroll', scrollHandler);
});

// 페이지 이동 시 cleanup을 위한 beforeRouteLeave 추가
const beforeRouteLeave = () => {
    isComponentActive.value = false;
    document.removeEventListener('scroll', scrollHandler);
};

// useInfiniteScroll에 isComponentActive 전달
const { 
    isInitialDataLoaded,
    scrollHandler
} = useInfiniteScroll(loadMore, {
    threshold: 200,
    delay: 500,
    isActive: isComponentActive
});

const clickMoreIcon = (pPostNum) =>{
  event.stopPropagation();
  store.dispatch('cmmnt/setActivePostNum', pPostNum);
}

const isActiveModal = (pPostNum) => {
  return computed(() => store.state.cmmnt.cmmnt.activePostNum === pPostNum)
}

const onClickOutside = (event) => {
  store.dispatch('cmmnt/clearActivePostNum');
}

const goCmmnt = (pName, pPostNum) => {
  navigate({value:pName, params: {postNum:pPostNum} } );
}

const delCmmntConfirm = (pPost) => {
  const handleSuccess = () => {
    postLst.value = postLst.value.filter(post => post.postNum !== pPost.postNum);
  };
  const onConfirm = () => {
    handleDelPost(pPost, handleSuccess);
  };
  toggleCommAlert("CMMNT-DEL", "CONFIRM", "CONFIRM", onConfirm);
}

function convertTimestampToFormattedDate(timestamp) {
    // timestamp 값을 milliseconds로 변환
    const date = new Date(timestamp);

    // 원하는 형식으로 날짜를 포맷
    const options = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
    };
    
    // 로케일을 'ko-KR'로 설정하여 한국 형식으로 포맷
    const formattedDate = new Intl.DateTimeFormat('ko-KR', options).format(date);

    // 'yyyy. MM. dd' 포맷으로 반환
    return formattedDate.replace(/\. /g, '.');
}


watch(() => sSrch.value.postCtgryCode, async (newCategory) => {
  post.value.postCtgryCode = newCategory;
  const params = {
    boardNum: '3',
    orderCtgryCode: newCategory,
    pageSize: pageObj.pageSize
  };
  await loadInitialPosts(params);
});
</script>