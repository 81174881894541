<template>

  <header class="tw-top">
      <a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
      <h1 class="tw-label-top">{{ $t('user.main.MYPAGE.WEATHER.TITLE') }}</h1>
    </header>
    
    <div class="tw-body">
      <div class="tw-weather-wrap">
        <ul>
          <li>
            <a @click="goWeatherDetail(location.addressInfo.WEATHER_REG_NUM)">
              <div class="tw-weather-box">
                <div :class="['tw-weather-icon', getWeatherClass(fus.sky, fus.pty)]">날씨 아이콘</div>
                <!-- 날씨 클래스 : sunny / partly-cloudy / cloudy / rain / snow  -->
                <div class="tw-weather-txt">
                  <div class="txt-top">{{ getRegionName(location.addressInfo, 'STEP1') }} {{ getRegionName(location.addressInfo, 'STEP2') }}</div>
                  <div class="txt-bt">{{ formattedTime }}</div>
                </div>
                <div class="tw-weather-temperature">{{ fus.t1h }}</div>
              </div>
            </a>
          </li>
          <li v-for="(region, index) in savedRegions" :key="region.WEATHER_REG_NUM">
            <a @click="goWeatherDetail(region.WEATHER_REG_NUM)">
              <div class="tw-weather-box">
                <div :class="['tw-weather-icon', getWeatherClass(region.weatherData.sky, region.weatherData.pty)]">날씨 아이콘</div>
                <div class="tw-weather-txt">
                  <div class="txt-top">{{ getRegionName(region, 'STEP1') }} {{ getRegionName(region, 'STEP2') }}</div>
                  <div class="txt-bt">{{ formatTime24to12(region.weatherData.baseTime) }}</div>
                </div>
                <div class="tw-weather-temperature">{{ region.weatherData.t1h }}</div>
              </div>
            </a>
          </li>
        </ul>
  
        <div class="tw-add-btn">
          <button type="button" class="tw-btn-default border icon black" aria-label="지역추가 버튼" @click="goWeatherAdd">
            <span class="tw-plus-icon">아이콘</span>
            <span class="tw-icon-text ff-m">{{ $t('user.main.MYPAGE.WEATHER.ADD') }}</span>
          </button>
        </div>
      </div>
    </div>
  </template>
  
  
  <script setup>
  import { ref, onMounted, computed } from 'vue'
  import { useStore } from "vuex";
  
  const store = useStore();
  
  import { useNavigation } from '@/page/main/composables/useNavigation';
  const { navigate, goBack } = useNavigation();
  
  import { useComm } from '@/page/main/composables/comm';
  const { toggleCommAlert, loginChk } = useComm();
  
  import { getFormattedTime, formatTime24to12 } from '@/util/DateTimeUtil';
  import { useWeather } from '@/page/main/composables/useWeather';
  const { getFusData, getWeatherClass, getUserRegions, getRegionName } = useWeather();
  
  // 현재 위치 정보
  const location = computed(() => store.state.location.location);
  const formattedTime = computed(() => getFormattedTime());
  
  const fus = ref({});
  const savedRegions = ref([]);  
  const bLoginChk = computed(() => store.state.user.user.bLoginChk);

  onMounted(async () => {
  try {
    if(location.value.addressInfo.X && location.value.addressInfo.Y) {
      fus.value = await getFusData(location.value.addressInfo.X, location.value.addressInfo.Y);
    }

    if(bLoginChk.value) {
      savedRegions.value = await getUserRegions();
    }
  } catch (error) {
      console.error('Error in onMounted:', error);
      toggleCommAlert('COMM', 'FAILED');
    }
  });

  const goWeatherAdd = () => {
    if(!loginChk()){
      return false;
    }else{
      navigate({ value: 'weatherAdd' });
    }
  };
  
  const goWeatherDetail = (weatherRegNum) => {
    navigate({ value: 'weatherDetail', params: { weatherRegNum } });
  };
  
  </script>