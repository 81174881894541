// src/page/main/composables/useInfiniteScroll.js
import { ref } from 'vue';
import { throttle } from 'lodash';

export const useInfiniteScroll = (loadMore, options = {}) => {
    const {
        threshold = 200,
        delay = 500,
        isActive = ref(true)
    } = options;

    const isInitialDataLoaded = ref(false);
    const isLoading = ref(false);

    const scrollHandler = throttle(async (event) => {
        // 컴포넌트가 비활성화 상태면 스크롤 이벤트 무시
        if (!isActive.value) return;
        
        if (!isInitialDataLoaded.value || isLoading.value) return;

        const { scrollHeight, scrollTop, clientHeight } = 
            event.target.documentElement || event.target;

        if (scrollHeight - scrollTop - clientHeight <= threshold) {
            isLoading.value = true;
            try {
                await loadMore();
            } finally {
                isLoading.value = false;
            }
        }
    }, delay);

    return {
        isInitialDataLoaded,
        scrollHandler
    };
};