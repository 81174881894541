import { useStore } from "vuex";
import { useNavigation } from '@/page/main/composables/useNavigation';
import { computed } from 'vue'

export function useComm() {
  const store = useStore();
  const { navigate } = useNavigation();

  function toggleCommLayer (commLayer) {

    const currentShowState = store.state.layer.comm.show;

    store.dispatch('layer/setLayerLst', {
        storeName : commLayer.storeName,
        stateName : commLayer.stateName,
        funcName : commLayer.funcName,
        valueName : commLayer.valueName,
        langPath : commLayer.langPath,
        show: !currentShowState,
        lst : commLayer.lst
    });
  }

  const toggleCommAlert = (title, code, mode = 'ALERT', event) => {
    store.dispatch('alert/setAlert', {
        stateName: "comm",
        title,
        code,
        show: true,
        mode,
        event,
    });
  }

  const loginChk = () => {
    const sUser = computed(() => store.state.user.user);
    if(!sUser.value.userNum){
      //비로그인의 경우 로그인 페이지로 이동시키는 기능 구현
      const onConfirm = () => {
        store.dispatch('alert/clearAlert', null);
        navigate({value:"mypageMain", querys: {} } );
      };
      //confirm open
      toggleCommAlert("USER-LOGIN-CHK", "CONFIRM", "CONFIRM", onConfirm);

      return false;
    }

    return true;
  }


  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  return {
    toggleCommLayer,
    toggleCommAlert,
    loginChk,
    stripHtml,
    // 여기에 추가 함수 이름을 쉼표로 구분하여 계속 추가
  };
}