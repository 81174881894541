<template>
      <header class="tw-top">
        <a href="#" class="tw-top-icon" @click="goBack()" >뒤로가기 아이콘</a>
        <h1 class="tw-label-top">Tripinfo</h1>
      </header>
      <div class="tw-body p-0">
        <div class="tw-info-detail">
          <div class="tw-info-detail-commentbox">
            <ul class="tw-comment-list-wrap">
              <!-- 댓글 열린상태 예시 -->
                <tripinfo-comm-cmnt></tripinfo-comm-cmnt>
            </ul>
          </div>
          <send-input :cmnt="tripinfoCmnt" @submit="regTripinfoCmnt" ></send-input>
        </div>
      </div>
</template>


<script setup>
import { onMounted, computed } from 'vue'
import { useRoute } from 'vue-router';
import { useStore } from "vuex";
//components
import tripinfoCommCmnt from '@/page/main/views/tripinfo/comm/cmntLst.vue'
import sendInput from '@/page/main/views/tripinfo/comm/sendInput.vue'

// composables
import { useNavigation } from '@/page/main/composables/useNavigation';
import { useTripinfo } from '@/page/main/composables/useTripinfo';

const { goBack } = useNavigation();
const route = useRoute();
const store = useStore();

const { 
    tripinfoCmnt,
    tripinfoCmntList,
    getTripinfoCmntList,
    regTripinfoCmnt,
    initTripinfoCmnt
} = useTripinfo();

const sTripinfoCmnt = computed(() => store.getters['tripinfo/getCmntData']);

const selTripinfoNum = route.query.tripinfoNum;
const selTripinfoCmntNum = route.query.tripinfoCmntNum;

// 컴포넌트 마운트 시 초기화 및 데이터 로드
onMounted(async () => {
    // 댓글 상태 초기화
    initTripinfoCmnt(selTripinfoNum, selTripinfoCmntNum);
    
    // 댓글 목록 조회 및 store 연동
    const cmntList = await getTripinfoCmntList(sTripinfoCmnt.value.tripinfoNum, sTripinfoCmnt.value.upprTripinfoCmntNum);
    tripinfoCmntList.value = cmntList;
});
</script>