import axiosWrpr from '@/util/axiosHelper';

// 상세 조회
export const getTripinfoDetail = async (tripinfoParam) => {
  try {
      const response = await axiosWrpr({
          method: 'POST',
          data: tripinfoParam,
          url: '/tripinfo/getview',
      });
      return response.resultObj;
  } catch (error) {
      console.error('상세 조회 실패:', error);
      throw error;
  }
};

// 목록 조회 (페이징)
export const getTripinfoList = async (tripinfoParam) => {
  try {
      const response = await axiosWrpr({
          method: 'POST',
          data: tripinfoParam,
          url: '/tripinfo/lstpage',
      });
      return response.resultObj;
  } catch (error) {
      console.error('목록 조회 실패:', error);
      throw error;
  }
};

// 댓글 목록 조회
export const getTripinfoCmntList = async (tripinfoNum, upprTripinfoCmntNum = null) => {
  try {
      const params = {};
      if (upprTripinfoCmntNum) {
          params.upprTripinfoCmntNum = upprTripinfoCmntNum;
      }

      const response = await axiosWrpr({
          method: 'GET',
          url: `/tripinfo/${tripinfoNum}/cmnt`,
          params: params,
          showSpinner: false
      });

      return response.resultObj || [];
  } catch (error) {
      console.error('댓글 목록 조회 실패:', error);
      throw error;
  }
};

// 댓글 등록
export const regTripinfoCmnt = async (cmntData) => {
  try {
      const response = await axiosWrpr({
          method: 'POST',
          data: cmntData,
          url: '/tripinfo/cmnt/reg',
          contentType: 'json'
      });
      return response;
  } catch (error) {
      console.error('댓글 등록 실패:', error);
      throw error;
  }
};

// 댓글 수정
export const modTripinfoCmnt = async (cmntData) => {
    try {
        const response = await axiosWrpr({
            method: 'PATCH',
            data: cmntData,
            url: `/tripinfo/cmnt/${cmntData.tripinfoCmntNum}`,
            contentType: 'json'
        });
        return response;
    } catch (error) {
        console.error('댓글 수정 실패:', error);
        throw error;
    }
};

export const delTripinfoCmnt = async (tripinfoCmntNum) => {
    try {
        const response = await axiosWrpr({
            method: 'DELETE',
            url: `/tripinfo/cmnt/${tripinfoCmntNum}`,
            contentType: 'json',
            showSpinner: false,
        });
        return response;
    } catch (error) {
        console.error('댓글 삭제 실패:', error);
        throw error;
    }
};

// 좋아요 등록/삭제
export const toggleTripinfoLike = async (tripinfoNum, isLiked) => {
  try {
      const response = await axiosWrpr({
          method: isLiked ? 'DELETE' : 'POST',
          url: `/tripinfo/${tripinfoNum}/like`,
          showSpinner: false,
      });
      return response;
  } catch (error) {
      console.error(`좋아요 ${isLiked ? '삭제' : '등록'} 실패:`, error);
      throw error;
  }
};

// 찜하기 등록/삭제
export const toggleTripinfoDibs = async (tripinfoNum, isDibsed) => {
  try {
      const response = await axiosWrpr({
          method: isDibsed ? 'DELETE' : 'POST',
          url: `/tripinfo/${tripinfoNum}/dibs`,
          showSpinner: false,
      });
      return response;
  } catch (error) {
      console.error(`찜하기 ${isDibsed ? '삭제' : '등록'} 실패:`, error);
      throw error;
  }
};