<template>

      <header class="tw-top icon">
        <h1 class="tw-label-top">Trip Info</h1>
        <div class="tw-top-right">
          <a class="tw-right-icon" @click="navigate({value:'tripinfoFilter'})">검색 아이콘</a>
          <!-- <a class="tw-alarm-icon">알림 아이콘</a> -->
        </div>
      </header>

      <div class="tw-body main">

        <bnrSwiper :bnrLst="bnrLst" />
        <div class="tw-main-info">
          <div class="tw-info-align">
            <h2 class="tw-label-title none">Info Category</h2>
          </div>
          <div class="tw-main-conbox-wrap info">
            <nav>
              <ul class="tw-main-conbox">
                <li class="tw-main-con">
                  <a>
                    <p class="tw-con-tv-icon">Trip TV 아이콘</p>
                    <p class="tw-con-txt">Trip TV</p>
                  </a>
                </li>
                <li class="tw-main-con">
                  <a>
                    <p class="tw-con-blog-icon">Trip Blog 아이콘</p>
                    <p class="tw-con-txt">Trip Blog</p>
                  </a>
                </li>
                <li class="tw-main-con">
                  <a>
                    <p class="tw-con-sos-icon">S.O.S 아이콘</p>
                    <p class="tw-con-txt">S.O.S</p>
                  </a>
                </li>
                <li class="tw-main-con">
                  <a>
                    <p class="tw-con-here-icon">Go Here 아이콘</p>
                    <p class="tw-con-txt">Go Here</p>
                    </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="tw-main-tab p-0">
          <ul class="main-tabs info">
            <li class="tab-link" :class="curTab == '001' ? 'current' : ''" tabindex="0" @click="tabbarClick('001')" >{{ $t('user.main.COMM.COMM-ORDER.001') }}</li>
            <li class="tab-link" :class="curTab == '002' ? 'current' : ''" tabindex="0" @click="tabbarClick('002')" >{{ $t('user.main.COMM.COMM-ORDER.002') }}</li>
            <li class="tab-link" :class="curTab == '003' ? 'current' : ''" tabindex="0" @click="tabbarClick('003')" >{{ $t('user.main.COMM.COMM-ORDER.003') }}</li>
            <li class="tab-link" :class="curTab == '004' ? 'current' : ''" tabindex="0" @click="tabbarClick('004')" >{{ $t('user.main.COMM.COMM-ORDER.004') }}</li>
          </ul>

          <template v-for="(tab, index) in tabs" :key="index">
            <div id="m-tab-1" class="tab-content" :class="curTab == tab ? 'current' : ''">
              <div class="tw-info-tab-list">
                <ul class="clearfix">
                  <tripinfo-card :tripinfoLst="tripinfoLists[tab]" ></tripinfo-card>
                </ul>
              </div>
            </div>
          </template>

        </div>
      </div>
</template>


<script setup>

import { ref, onMounted, onUnmounted, getCurrentInstance, nextTick } from 'vue'
import { useI18n } from 'vue-i18n';
import { throttle } from 'lodash'
import { useTripinfo } from '@/page/main/composables/useTripinfo';

const { 
    tripinfo,
    tripinfoLists,
    tripinfoPageNum,
    tripinfoTotalpages,
    getTripinfoList 
} = useTripinfo();

//글로벌 프로퍼티 설정 부분
//언어 설정 부분
const { locale } = useI18n();

//공통 js import
import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { loginChk } = useComm();

//components
import tripinfoCard from '@/page/main/views/tripinfo/comm/cardLst.vue'

// 상태 관리
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const isInitialDataLoaded = ref(false);
const currentScroll = ref(0);
const waitingScroll = ref(false);
const tabs = ['001', '002', '003', '004'];
const curTab = ref(tabs[0]);
const bnr = ref({ bnrCode: "24", lang: locale.value });
const bnrLst = ref([]);

tripinfo.value.lang = locale.value;
tripinfo.value.pageNum = tripinfoPageNum[tabs[0]];
tripinfo.value.pageSize = 4;
tripinfo.value.tripinfoOrderCtgryCode = tabs[0];


const tabbarClick = async (pTab) => {
    if (pTab === '004' && !loginChk()) {
        return;
    }

    curTab.value = pTab;
    if (tripinfoLists[pTab].length === 0) {
        const params = {
            tripinfoOrderCtgryCode: pTab,
            pageNum: tripinfoPageNum[pTab],
            pageSize: 4,
            lang: locale.value
        };
        tripinfoLists[pTab] = await getTripinfoList(params);
    }
};


// 스크롤 관련 처리
const scrollHandler = throttle(scrollCheck, 500);

function scrollCheck(e) {
    if (!isInitialDataLoaded.value) return;

    currentScroll.value = e.target.scrollingElement.scrollTop + e.target.scrollingElement.clientHeight;
    if (currentScroll.value > e.target.scrollingElement.scrollHeight - 200 &&
        tripinfoTotalpages[curTab.value] > tripinfoPageNum[curTab.value] &&
        !waitingScroll.value) {
        loadMoreTripinfo();
    }
}

// 추가 데이터 로드
async function loadMoreTripinfo() {
    if (waitingScroll.value) return;

    waitingScroll.value = true;
    try {
        const params = {
            tripinfoOrderCtgryCode: curTab.value,
            pageNum: tripinfoPageNum[curTab.value],
            pageSize: 4,
            lang: locale.value
        };
        const addTripinfoLst = await getTripinfoList(params);
        tripinfoLists[curTab.value].push(...addTripinfoLst);
    } catch (error) {
        console.error("추가 데이터 로드 실패:", error);
    } finally {
        waitingScroll.value = false;
    }
}

// 컴포넌트가 언마운트될 때 이벤트 리스너 제거
onUnmounted(() => {
  document.removeEventListener('scroll', scrollHandler);
});


async function loadInitialData() {
  tripinfo.value.pageNum = tripinfoPageNum[curTab.value];
  tripinfo.value.tripinfoOrderCtgryCode = curTab.value;
  tripinfoLists[curTab.value] = await getTripinfoList(tripinfo.value);
  isInitialDataLoaded.value = true;
}

onMounted( async () => {
  await loadInitialData();

  bnrLst.value = await getBnrLst(bnr.value);

  nextTick(() => {
    document.addEventListener('scroll',scrollHandler);
    scrollCheck({ target: document });
  })
})


import bnrSwiper from "@/page/main/comm/bnrSwiper.vue"
const getBnrLst = async (bnr) => {
    let tmpBnrLst;
    await $axios({
        method: 'POST',
        data: bnr,
        url: '/tripwith/bnr/lstview',
        showSpinner: false,
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpBnrLst = resultObj;
        }
    }).catch((error) => {
        alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
    })
    return tmpBnrLst;
};

</script>