<template>

        <header class="tw-top">
          <a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
          <h1 class="tw-label-top">{{ $t('user.main.MYPAGE.BUTTON.MYLIKE') }}</h1>
        </header>

        <div class="tw-body p-0">
          <div class="tw-main-tab p-0">
            <ul class="main-tabs">
              <li class="tab-link" :class="{ current: currentTab === 'tripinfo' }" tabindex="0" @click="setCurrentTab('tripinfo')">Tripinfo</li>
              <li class="tab-link" :class="{ current: currentTab === 'cmmnt' }" tabindex="0"  @click="setCurrentTab('cmmnt')">Community</li>
            </ul>
            <div id="m-tab-1" class="tab-content" :class="{ current: currentTab === 'tripinfo' }">


              <div class="tw-info-tab-list">
                <ul class="clearfix">
                  <tripinfo-card :tripinfoLst="tripinfoLst" ></tripinfo-card>
                </ul>
              </div>

            </div>
            <div id="m-tab-2" class="tab-content" :class="{ current: currentTab === 'cmmnt' }">
              <div class="tw-main-tab-list question">
                <nav>
                  <ul>
                    <li v-for="(cmmnt, index) in cmmntLst" :key="index" @click="goCmmntDtl('cmmntDtl', cmmnt.postNum)">
                      <div class="rank">{{ index + 1 }}</div>
                      <div class="rank-img">
                        <a v-if="cmmnt.thmbFullPath" ><img :src="cmmnt.thmbFullPath" alt="랭킹 이미지"></a>
                        <a v-else><img src="/tripwith/assets/images/img-sample(1).png" alt="랭킹 이미지"></a>
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <a>
                            <p class="detail-tit">{{ cmmnt.title }}</p>
                            <p class="detail-body">{{ cmmnt.cntn }}</p>
                          </a>
                          <div class="info-txt-bt left">
                                <div class="info-bt-like icon" :class="cmmnt.likeCnt > 0 ? 'on' : ''" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">{{ cmmnt.likeCnt > 0 ? cmmnt.likeCnt : '좋아요'}}</span>
                                </div>
                            <div class="info-bt-comm icon" :class="cmmnt.cmntCnt > 0 ? 'on' : ''" tabindex="0">
                              <span class="tw-comm-icon">댓글 아이콘</span>
                              <span class="tw-comm-txt">{{ cmmnt.cmntCnt > 0 ? cmmnt.cmntCnt : '댓글달기'}}</span>
                            </div>
                            <div class="info-bt-view icon" :class="cmmnt.viewCnt > 0 ? 'on' : ''" tabindex="0">
                              <span class="tw-view-icon">조회수 아이콘</span>
                              <span class="tw-view-txt">{{ cmmnt.viewCnt > 0 ? cmmnt.viewCnt : '조회수'}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

        </div>
</template>


<script setup>
import { ref, reactive, onMounted, onBeforeMount, getCurrentInstance, computed, onBeforeUnmount, watch } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
import { useI18n } from 'vue-i18n';

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

const serverUrl = computed(() => store.getters.getServerUrl);
import modal from "@/page/main/comm/modal.vue";

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert } = useComm();

//components
import tripinfoCard from '@/page/main/views/tripinfo/comm/cardLst.vue'


const pageObj = reactive({totalCnt : 0 //최대 리스트 개수
                    ,totalPage : 100 // 최대 페이지 개수 
                    ,pageNum : 0 //현재 페이지 번호(실제 페이지 넘버 -1)
                    ,pageSize : 1 //보여줄 페이징의 최대 개수
                    ,pageBlockNum : 8 //한 페이지 내에 보여줄 리스트 개수
                    })

const tripinfo = reactive({});
const tripinfoLst = ref([]);


tripinfo.pageNum = 0;
tripinfo.pageSize = 10;


const goGetTripinfoLst = async (tripinfoParam) => {
  let tmpTripinfoLst;
    await $axios({
        method: 'POST',
        data: tripinfoParam,
        url: '/tripinfo/lstpage',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpTripinfoLst = resultObj.content;
            tripinfo.totalCnt = resultObj.totalElements;
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
    return tmpTripinfoLst;
};


//언어 설정 부분
const { locale } = useI18n();

const cmmnt = ref({});
const cmmntLst = ref([]);

onMounted( async () => {
  tripinfo.lang = locale.value;
  tripinfo.tripinfoOrderCtgryCode = '005';
  tripinfoLst.value = await goGetTripinfoLst(tripinfo);
  
  goStartSrch();
})

const sUser = computed(() => store.state.user.user);

const currentTab = computed(() => store.state.mypage.favs.currentTab);

const tabs = [
  { id: 'tripinfo', name: '트립인포'},
  { id: 'cmmnt', name: '커뮤니티'},
]


const setCurrentTab = (tabId) => {
  const tabInfo = {
    stateName : "favs",
    varName : "currentTab",
    value : tabId
  }
  store.dispatch('mypage/setCurrentTab', tabInfo);
}


const goStartSrch = async () => {
  cmmnt.value.pageNum = 0;
  cmmnt.value.pageSize = 30;
  cmmnt.value.boardNum = '3';
  cmmnt.value.orderCtgryCode = '005';

  cmmntLst.value = await goGetLstCmmnt(cmmnt.value);
}

const goGetLstCmmnt = async (pCmmnt) => {
  let cmmntLst;
    await $axios({
        method: 'GET',
        params: pCmmnt,
        url: '/tripwith/post/user',
    }).then((data) => {
        const resultObj = data.resultObj.content;
        if (resultObj) {
            cmmntLst = resultObj;
            cmmnt.value.totalCnt = data.resultObj.totalElements;
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
    return cmmntLst;
};

const goCmmntDtl = (pName, pPostNum) => {
  navigate({value:pName, params: {postNum:pPostNum} } );
}
</script>