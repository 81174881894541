<template>
  <div class="tw-wrap">
    <header class="tw-top search">
      <a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
      <div class="tw-top-search">
        <form class="tw-search-wrap" role="search" @submit.prevent>
          <input v-model="searchQuery" type="search" class="tw-input-default tw-input-search" 
                 :placeholder="$t('user.main.MYPAGE.WEATHER.SEARCH')" aria-label="지역 검색" 
                 @input="filterRegions" @focus="isPopOpen = true">
          <span class="tw-search-icon" tabindex="0">검색 아이콘</span>
        </form>
      </div>
    </header>

    <div class="tw-body p-0">
      <div class="tw-setting-box full fixed-section scroll-section">
        <h2 class="tw-label-default bold f16 bt-p">{{ $t('user.main.MYPAGE.WEATHER.TITLE') }}</h2>
        
        <!-- 선택된 지역 목록 -->
        <ul class="tw-setting-list-box selected-regions">
          <li v-for="region in selectedRegions" :key="region.WEATHER_REG_NUM" 
              class="default-list active" tabindex="0" @click="toggleRegion(region)">
              {{ getRegionName(region, 'STEP1') }} {{ getRegionName(region, 'STEP2') }}<span>active icon</span>
          </li>
        </ul>
      </div>

      <!-- 선택되지 않은 지역 목록 -->
      <div class="tw-setting-box full scrollable-section">
        <ul class="tw-setting-list-box">
          <li v-for="region in filteredUnselectedRegions" :key="region.WEATHER_REG_NUM" 
              class="default-list" tabindex="0" @click="toggleRegion(region)">
              {{ getRegionName(region, 'STEP1') }} {{ getRegionName(region, 'STEP2') }}<span>active icon</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted, computed, getCurrentInstance } from 'vue';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import { useComm } from '@/page/main/composables/comm';
import weatherRegData from '@/assets/data/weather_reg.json';
import { useNavigation } from '@/page/main/composables/useNavigation';

const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const { toggleCommAlert } = useComm();
const { navigate, goBack } = useNavigation();

const isPopOpen = ref(false);
const searchQuery = ref('');
const selectedRegions = ref([]);
const filteredUnselectedRegions = ref([]);
const isProcessing = ref(false); // 클릭 처리 중 여부를 위한 ref


import { createLogger, useStore } from "vuex";
const store = useStore();
const sUser = computed(() => store.state.user.user);

const fetchSavedRegions = async () => {
  await $axios({
        method: 'GET',
        params: '',
        url: '/weather/user',
    }).then((data) => {
        const resultObj = data.resultObj
        if (resultObj) {
          console.log(resultObj);
          // weatherRegData에서 선택된 지역 정보 필터링
          selectedRegions.value = weatherRegData.filter(region => 
            resultObj.some(saved => saved.id.weatherRegNum == region.WEATHER_REG_NUM)
          );

          // 선택되지 않은 지역 정보 설정
          filteredUnselectedRegions.value = weatherRegData.filter(region => 
            !selectedRegions.value.some(selected => selected.WEATHER_REG_NUM == region.WEATHER_REG_NUM)
          );
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
};

const filterRegions = () => {
  const query = searchQuery.value.toLowerCase();
  filteredUnselectedRegions.value = weatherRegData.filter(region => 
    // 선택되지 않은 항목 중에서 검색
    !selectedRegions.value.some(selected => selected.WEATHER_REG_NUM === region.WEATHER_REG_NUM) &&
    // 검색어로 필터링
    ((region.STEP1_KR + (region.STEP2_KR || '')).toLowerCase().includes(query) ||
     (region.STEP1_EN + (region.STEP2_EN || '')).toLowerCase().includes(query))
  );
};

const updateFilteredRegions = () => {
  // 검색어가 있는 경우 검색 결과 유지
  if (searchQuery.value) {
    filterRegions();
  } else {
    // 검색어가 없는 경우 전체 목록에서 선택된 항목 제외
    filteredUnselectedRegions.value = weatherRegData.filter(region => 
      !selectedRegions.value.some(selected => selected.WEATHER_REG_NUM === region.WEATHER_REG_NUM)
    );
  }
};

const toggleRegion = _.throttle(async (region) => {
  if (isProcessing.value) return;
  isProcessing.value = true;

  try {
    const index = selectedRegions.value.findIndex(r => r.WEATHER_REG_NUM === region.WEATHER_REG_NUM);
    if (index > -1) {
      await removeMapping(region.WEATHER_REG_NUM);
      selectedRegions.value.splice(index, 1);
    } else if (selectedRegions.value.length < 3) {
      await saveMapping(region.WEATHER_REG_NUM);
      selectedRegions.value.unshift(region);
    }

    updateFilteredRegions();
  } catch (error) {
    console.error('Error in toggleRegion:', error);
    toggleCommAlert("COMM", "FAILED");
  } finally {
    isProcessing.value = false;
  }
}, 500);

const saveMapping = async (weatherRegNum) => {
  await $axios({
    method: 'POST',
    url: '/weather/user',
    data: { weatherRegNum },
    showSpinner: false,
  }).catch(() => toggleCommAlert("COMM", "FAILED"));
};

const removeMapping = async (weatherRegNum) => {
  await $axios({
    method: 'DELETE',
    url: `/weather/user/${weatherRegNum}`,
    showSpinner: false,
  }).then(() => updateFilteredRegions())
    .catch(() => toggleCommAlert("COMM", "FAILED"));
};

const { locale } = useI18n();
const getRegionName = (region, step) => {
  return locale.value === 'en' ? region[`${step}_EN`] : region[`${step}_KR`];
};

onMounted(fetchSavedRegions);

</script>