<template>

        <header class="tw-top">
          <a class="tw-top-icon" @click="goBack">뒤로가기 아이콘</a>
          <h1 class="tw-label-top">{{ $t('user.main.SIGNUP.TITLE')}}</h1>
        </header>

        <div class="tw-body">
          <div class="tw-body-text-wrap p-0">
            <h2 class="tw-label-default f24">{{ $t('user.main.SIGNUP.TITLE')}}</h2>
            <label class="tw-label-default m-0">{{ $t('user.main.SIGNUP.MAIN.TITLE-CNTN')}}</label>
          </div>
          <div class="tw-body-btn-wrap p-30-0">
            <button type="button" class="tw-btn-default border icon icon-mail" aria-label="이메일로 가입하기 버튼" @click="navigate({value:'signupEmail' } )">
              <span class="tw-email-icon">이메일 아이콘</span>
              <span class="tw-icon-text">{{ $t('user.main.SIGNUP.MAIN.EMAIL')}}</span>
            </button>
            <!-- <button type="button" class="tw-btn-default border icon icon-phone" aria-label="전화번호로 가입하기 버튼" @click="navigate({value:'signupPhone' } )">
              <span class="tw-phone-icon">전화번호 아이콘</span>
              <span class="tw-icon-text">전화번호로 가입하기</span>
            </button> -->
          </div>
          <div class="tw-body-line-wrap">
            <p>라인</p>
            <div>
              <label class="tw-label-title f14">{{ $t('user.main.SIGNUP.MAIN.SMPL')}}</label>
            </div>
          </div>
          <div class="tw-body-sns-wrap">
            <button type="button" class="tw-btn-sns" aria-label="소셜 카카오톡 버튼" @click="clickKakaoSmplLoginBtn">
              <span class="tw-kko-icon">카카오톡로고</span>
            </button>
            <button type="button" class="tw-btn-sns" aria-label="소셜 네이버 버튼" @click="clickNaverSmplLoginBtn">
              <span class="tw-nav-icon">네이버로고</span>
            </button>
            <button type="button" class="tw-btn-sns" aria-label="소셜 구글 버튼" @click="clickGoogleSmplLoginBtn">
              <span class="tw-goo-icon">구글로고</span>
            </button>
            <!-- <button type="button" class="tw-btn-sns" aria-label="소셜 페이스북 버튼">
              <span class="tw-fac-icon">페이스북로고</span>
            </button>
            <button type="button" class="tw-btn-sns" aria-label="소셜 애플 버튼">
              <span class="tw-app-icon">애플로고</span>
            </button>
            <button type="button" class="tw-btn-sns" aria-label="소셜 위챗 버튼">
              <span class="tw-wec-icon">위챗로고</span>
            </button> -->
          </div>
        </div>

</template>


<script setup>
import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { clickNaverSmplLoginBtn, clickKakaoSmplLoginBtn, clickGoogleSmplLoginBtn } from '@/page/main/composables/useSimpleLogin.js';


</script>