<template>
  <header class="tw-top">
    <a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
    <h1 class="tw-label-top">{{ $t('user.main.MYPAGE.EXCHANGE_RATES.TITLE') }}</h1>
  </header>
  
  <div class="tw-body p-0">
    <div class="tw-rate-top">
      <h2 class="tw-rate-standard">{{ formattedTime }} {{ $t('user.main.MYPAGE.EXCHANGE_RATES.TITLE-STANDARD') }}</h2>
      <span class="tw-refresh-icon" tabindex="0" @click="refreshExchangeRates">새로고침 아이콘</span>
    </div>
      <ul class="tw-rate-wrap">
        <li v-for="rate in exchangeRates" :key="rate.exchangeRatesNum" class="tw-rate-box">
          <!-- <div class="tw-rate-update-icon" onClick="popOpen()" tabindex="0" aria-haspopup="true">수정 아이콘</div> -->
          <div class="tw-rate-name">
            <span class="tw-rate-txt">{{ rate.baseCurrency }}</span>
            <span class="tw-rate-arrow-icon">아이콘</span>
            <span class="tw-rate-txt">{{ rate.targetCurrency }}</span>
          </div>
          <div class="tw-rate-num">{{ formatRate(rate.rate) }}</div>
          <div class="tw-rate-move-icon" tabindex="0">이동 아이콘</div>
        </li>

        <div class="tw-add-btn">
          <button type="button" class="tw-btn-default border icon black" aria-label="환율추가 버튼" @click="goExchangeRatesAdd">
            <span class="tw-plus-icon">아이콘</span>
            <span class="tw-icon-text ff-m">{{ $t('user.main.MYPAGE.EXCHANGE_RATES.ADD') }}</span>
          </button>
        </div>
      </ul>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import { useNavigation } from '@/page/main/composables/useNavigation';
import { useComm } from '@/page/main/composables/comm';
import { getFormattedTime } from '@/util/DateTimeUtil';

const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter();
const store = useStore();
const { locale } = useI18n();
const { navigate, goBack } = useNavigation();
const { toggleCommLayer, toggleCommAlert, loginChk } = useComm();

const exchangeRates = ref([]);
const exchangeRatesUser = ref([]);
const formattedTime = ref(getFormattedTime());

const bLoginChk = computed(() => store.state.user.user.bLoginChk);

const fetchExchangeRate = (currency) => {
  return $axios({
    method: 'GET',
    url: `/exchangerates/${currency}`,
  }).then(response => {
    const resultObj = response.resultObj;
    if (resultObj) {
      return resultObj;
    }
  }).catch(error => {
    console.error('Error fetching exchange rate:', error);
    toggleCommAlert('COMM', 'FAILED');
  });
};

const fetchExchangeRatesUser = () => {
  $axios({
    method: 'GET',
    url: '/exchangerates/user',
  }).then(response => {
    exchangeRatesUser.value = response.resultObj;
  }).catch(error => {
    console.error('Error fetching user exchange rates:', error);
    toggleCommAlert('COMM', 'FAILED');
  });
};

const refreshExchangeRates = () => {
  formattedTime.value = getFormattedTime(); // 시간 업데이트
  exchangeRates.value = []; // 기존 환율 정보 초기화
  const promises = exchangeRatesUser.value.map(userRate => fetchExchangeRate(userRate.currency));
  
  Promise.all(promises)
    .then(results => {
      exchangeRates.value = results.filter(result => result != null);
    })
    .catch(error => {
      console.error('Error refreshing exchange rates:', error);
      toggleCommAlert('COMM', 'FAILED');
    });
};

onMounted(() => {
  if(bLoginChk.value){
    fetchExchangeRatesUser();
  }else{
    const defaultExchangeRates = [
      {
        currency: 'USD',
      },
      {
        currency: 'EUR',
      }
    ]
    exchangeRatesUser.value = defaultExchangeRates;
  }

  // watch를 onMounted 내부로 이동
  watch(exchangeRatesUser, (newExchangeRatesUser) => {
    refreshExchangeRates();
  }, { immediate: true });
});

const formatRate = (rate) => {
  return rate.toFixed(4);
};

const goExchangeRatesAdd = () => {
  if(loginChk()){
    navigate({value: 'exchangeRatesAdd'})
  }
}

</script>
