<template>
  <div class="tw-sppiner-uppr-wrap">
    <div class="tw-sppiner-wrap">
      <span class="tw-sppiner"></span>
    </div>
  </div>
</template>

<script setup>

import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();
const currentRoute = ref(route.name); // 현재 라우트 이름을 저장하는 반응형 참조


import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

// 라우터의 경로가 변경될 때마다 currentRoute 업데이트
watch(() => route.name, (newRoute) => {
  currentRoute.value = newRoute;
});


</script>
