import axios from "axios";
import qs from "qs";
import store from "@/store/store";
import router from "@/page/main/router";

let isRefreshing = false;
let failedQueue = [];

const defaultConfig = {
    contentType: 'form', // 'form' 또는 'json'
};
const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

const headers = {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    Accept: "*/*",
    "Access-Control-Allow-Origin": "*",
};

const axiosInst = axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL,
    headers: headers,
    withCredentials: true,  // 쿠키를 전송하기 위해 추가
    transformRequest: [
        (data, headers) => {
            // FormData는 그대로 반환
            if (data instanceof FormData) {
                return data;
            }
            
            // 요청별 설정에 따라 데이터 변환
            const contentType = headers['Content-Type'] || '';
            let transformedData;
            if (contentType.includes('application/json')) {
                transformedData = JSON.stringify(data);
            } else if (contentType.includes('application/x-www-form-urlencoded')) {
                transformedData = qs.stringify(data, { arrayFormat: "brackets" });
            } else {
                transformedData = data;
            }

            return transformedData;
        },
    ],
});

// axiosWrpr interceptor 설정
axiosInst.interceptors.request.use(
    function (config) {
        // 1. 요청 보내기 전에 실행
        let token = localStorage.getItem("authorization");
        if (token != null && token != "") {
            config.headers.Authorization = localStorage.getItem("authorization");
        }
        
        if (config.showSpinner !== false) {
            store.dispatch('comm/setShowSppiner', true);
        }

        return config;
    },
    function (error) {
        console.error("interceptors axiosWrpr Failed:", error.config);
        // Do something with axiosWrpr error
        return Promise.reject(error);
    }
);

axiosInst.interceptors.response.use(
    function (response) {
        if (
            response.headers["authorization"] != null &&
            response.headers["authorization"] != ""
        ) {
            localStorage.setItem("authorization", response.headers["authorization"]);
        }
        
        if (response.config.showSpinner !== false) {
            store.dispatch('comm/setShowSppiner', false);
        }
        return response;
    },
    async function (error) {
        if (error.config?.showSpinner !== false) {
            store.dispatch('comm/setShowSppiner', false);
        }
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && error.response.data.resultCode === 'T001' && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                })
                .then(token => {
                    originalRequest.headers['Authorization'] = token;
                    return axiosInst(originalRequest);
                })
                .catch(err => Promise.reject(err));
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                // 리프레시 토큰을 사용해 새로운 액세스토큰 발급 요청 
                const { data } = await axiosInst.post('/token/refresh', null, {
                    showSpinner: false
                });
                if (data.resultYn === 'Y') {
                    const newToken = data.resultObj;
                    localStorage.setItem("authorization", newToken);
                    axiosInst.defaults.headers['Authorization'] = newToken;
                    processQueue(null, newToken);


                    // 원본 요청 데이터 처리
                    const newRequest = { ...originalRequest };
                    newRequest.headers['Authorization'] = newToken;

                    // Content-Type 헤더 처리
                    if (originalRequest.data instanceof FormData) {
                        delete newRequest.headers['Content-Type']; // axios가 자동으로 설정하도록
                    } else {
                        // 원본 요청의 Content-Type 유지
                        newRequest.headers['Content-Type'] = originalRequest.headers['Content-Type'];
                    }

                    // 데이터 처리
                    if (originalRequest.data instanceof FormData) {
                        newRequest.data = originalRequest.data;
                    } else if (typeof originalRequest.data === 'string') {
                        // Content-Type에 따른 데이터 파싱
                        if (originalRequest.headers['Content-Type'].includes('application/json')) {
                            newRequest.data = JSON.parse(originalRequest.data);
                        } else {
                            const params = new URLSearchParams(originalRequest.data);
                            const dataObject = {};
                            for (const [key, value] of params.entries()) {
                                dataObject[key] = value;
                            }
                            newRequest.data = dataObject;
                        }
                    }

                    return axiosInst(newRequest);
                } else {
                    processQueue(new Error('Token refresh failed'));
                    // 여기서 바로 handleTokenExpired 처리
                    if (originalRequest.meta?.requiresAuth) {
                        await handleRequiredAuthError(originalRequest);
                        return new Promise(() => {}); // 요청 중단
                    } else {
                        return handleNonAuthRequest(originalRequest);
                    }
                }
            } catch (refreshError) {
                processQueue(refreshError);
                if (originalRequest.meta?.requiresAuth) {
                    await handleRequiredAuthError(originalRequest);
                    return new Promise(() => {}); // 요청 중단
                } else {
                    return handleNonAuthRequest(originalRequest);
                }
            } finally {
                isRefreshing = false;
            }
        }else{
            return Promise.reject(error);
        }
        
    }
);

// axiosWrpr Wrapper with default success/error actions
const axiosWrpr = function (options) {

    const showSpinner = options.showSpinner !== false; // 기본값은 true
    const contentType = options.contentType || defaultConfig.contentType;

    const config = { ...options };
    config.headers = { ...options.headers };

    // Content-Type 설정
    if (!(options.data instanceof FormData)) {
        if (contentType === 'json') {
            config.headers['Content-Type'] = 'application/json; charset=UTF-8';
        } else {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
        }
    }

    //SuccessHandler
    const onSuccess = function (response) {
        // 전체 모달창 닫기
        try {
            //hideAllModel();
        } catch (e) { }

        return response.data;
    };

    //ErrorHandler
    const onError = function (error) {
        if (error.response) {
        } else {
        }

        //return Promise.reject(error.response || error.message);
        return Promise.reject(error);
    };

    return axiosInst(config).then(onSuccess).catch(onError);
};


// 인증이 필요한 요청 처리
async function handleRequiredAuthError(originalRequest) {
    localStorage.removeItem("authorization");
    await store.dispatch("user/clearUser");
    delete axiosInst.defaults.headers['Authorization'];
    
    store.dispatch('alert/setAlert', {
        stateName: "comm",
        title: 'ERROR',
        message: '세션이 만료되어 재로그인이 필요합니다.',
        code: 'O001',
        show: true,
        mode: 'ALERT',
        event: () => router.push({ name: 'mypageMain' })
    });
}

async function handleNonAuthRequest(originalRequest) {
    localStorage.removeItem("authorization");
    await store.dispatch("user/clearUser");
    delete axiosInst.defaults.headers['Authorization'];

    const params = new URLSearchParams(originalRequest.data);
    const dataObject = {};
    for (const [key, value] of params.entries()) {
        dataObject[key] = value;
    }

    try {
        // axiosInst 사용
        const response = await axiosInst({
            method: originalRequest.method,
            url: originalRequest.url,
            data: dataObject,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });

        return response;
    } catch (error) {
        console.error('Request Error:', {
            config: error.config,
            data: error.config?.data,
            response: error.response?.data
        });

        return Promise.reject(error);
    }
}

export { axiosInst };
export default axiosWrpr;
