<template>
      <header class="tw-top">
        <a href="#" class="tw-top-icon" @click="goBack()" >뒤로가기 아이콘</a>
        <h1 class="tw-label-top">Tripinfo</h1>
      </header>
      <div class="tw-body p-0">
        <div class="tw-info-detail">
          <div class="tw-info-detail-top">
            <div class="detail-top-info-wrap">
              <div class="detail-top-info-img">
                <img src="/tripwith/assets/images/img-sample(5).png" alt="프로필 이미지">
              </div>
              <h2 class="detail-top-info-id">Team TripWith</h2>
              <div class="detail-top-info-date">{{ convertTimestampToFormattedDate(tripinfo?.regDtInstant) }}</div>
            </div>
            <div class="detail-top-icon-wrap">
              <span 
                :class="['tw-bookmark-icon', { on: tripinfo?.dibsedByUser }]" 
                tabindex="0" 
                @click="toggleTripinfoDibs(tripinfo)"
              >북마크 아이콘
              </span>
              <!-- <template v-if="tripinfo?.userNum == sUser?.userNum">
                <span class="tw-more-icon" aria-haspopup="true" tabindex="0">더보기 아이콘</span>
                <div class="tw-pop-mini"  role="dialog" aria-modal="true" v-show="bTripinfoDtlShow">
                  <ul>
                    <li><a href="../2step/CT_CC_004.html">수정하기</a></li>
                    <li onclick="popOpen2();" tabindex="0"><a>삭제하기</a></li>
                  </ul>
                </div>
              </template> -->
            </div>
          </div>
          <div class="tw-info-detail-body">
            <template v-if="tripinfo?.videoUrl">
              <div class="detail-body-video">
                <iframe width="100%" height="300px" :src="tripinfo.videoUrl" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </template>
            <!-- <div class="detail-body-video">
              <figure>
                <video controls>
                  <source src="/tripwith/assets/video/sample.mp4" >
                </video>
                <figcaption>비디오 설명</figcaption>
              </figure>
            </div> -->
            <div class="detail-body-txt">
              <h2>{{ tripinfo.title }}</h2>
              <div class="tiptap" v-html="tripinfo.cntn"></div>
            </div>
          </div>
          <div class="tw-info-detail-btn">
              <div
                :class="['info-bt-like icon', { on: tripinfo.likedByUser }]"
                tabindex="0"
                @click="toggleTripinfoLike(tripinfo)"
              >
              <span class="tw-like-icon">좋아요 아이콘</span>
              <span class="tw-like-txt">{{ tripinfo.likeCnt > 0 ? tripinfo.likeCnt : $t('user.main.TRIPINFO.COMM.LIKE')}}</span>
            </div>
            <div class="info-bt-comm icon" :class="tripinfo.cmntCnt > 0 ? 'on' : ''" tabindex="0">
              <span class="tw-comm-icon">댓글 아이콘</span>
              <span class="tw-comm-txt">{{ tripinfo.cmntCnt > 0 ? tripinfo.cmntCnt : $t('user.main.TRIPINFO.COMM.CMNT-REG')}} </span>
            </div>
            <div class="info-bt-share icon" tabindex="0">
              <span class="tw-share-icon">공유하기 아이콘</span>
              <span class="tw-share-txt">{{$t('user.main.TRIPINFO.COMM.SHARE')}}</span>
            </div>
          </div>

          <div class="gray-blank"></div>

          <rcmn-tripinfo-swiper :tripinfo="tripinfo" />

          <div class="tw-info-detail-commentbox">
            <p>공백</p>
            <ul class="tw-comment-list-wrap">
              <!-- 댓글 열린상태 예시 -->
                <tripinfo-comm-cmnt></tripinfo-comm-cmnt>
            </ul>
          </div>
          <send-input :cmnt="tripinfoCmnt" @submit="regTripinfoCmnt"></send-input>

          <!-- <div class="tw-info-detail-send active">
            <div class="detail-send-img">
              <img src="/tripwith/assets/images/img-sample(5).png" alt="닉네임 프로필 이미지">
            </div>
            <div class="detail-send-input">
                <input type="text" class="tw-input-default tw-input-comment" v-model="tripinfoCmnt.cntn" @keydown.enter="regTripinfoCmntDeb" >
            </div>
            <div class="detail-send-icon on" tabindex="0">보내기</div>
          </div> -->
        </div>
      </div>
</template>


<script setup>
import { onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'; 

//components
import tripinfoCommCmnt from '@/page/main/views/tripinfo/comm/cmntLst.vue'
import sendInput from '@/page/main/views/tripinfo/comm/sendInput.vue'
import rcmnTripinfoSwiper from '@/page/main/views/tripinfo/comm/rcmnTripinfoSwiper.vue'

// composables
import { useNavigation } from '@/page/main/composables/useNavigation';
import { useTripinfo } from '@/page/main/composables/useTripinfo';
const { goBack } = useNavigation();
const { 
    tripinfo,
    tripinfoCmnt,
    getTripinfoDetail,
    regTripinfoCmnt,
    toggleTripinfoLike,
    toggleTripinfoDibs,
    convertTimestampToFormattedDate,
    initTripinfo
} = useTripinfo();

const props = defineProps({
  tripinfoNum: {
    type: String,
    required: true
  }
});

const route = useRoute()

watch(() => route.params.tripinfoNum, async (newTripinfoNum) => {
    if (newTripinfoNum) {
        initTripinfo(newTripinfoNum);
        await getTripinfoDetail(newTripinfoNum);
    }
});


onMounted(async () => {
    initTripinfo(props.tripinfoNum);
    await getTripinfoDetail(props.tripinfoNum);
});


</script>