<template>
  <div class="tw-body-category">
    <div class="swiper txtSwiper tw-swiper-key swiper-initialized swiper-horizontal swiper-backface-hidden">
<!-- :space-between="5"  -->
      <Swiper :slides-per-view="'auto'" :space-between="5" >
        <SwiperSlide>
          <div class="swiper-slide tw-swiper-txt" :class="sSrch.postCtgryCode == '000' ? 'tw-swiper-active' : ''"  role="group" aria-label="1 / 7" tabindex="0" @click="clickCmmntCtgry('000')" >{{ $t('user.main.CMMNT.POST-CTGRY.000') }}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="swiper-slide tw-swiper-txt" :class="sSrch.postCtgryCode == '001' ? 'tw-swiper-active' : ''"  role="group" aria-label="2 / 7" tabindex="0" @click="clickCmmntCtgry('001')" >{{ $t('user.main.CMMNT.POST-CTGRY.001') }}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="swiper-slide tw-swiper-txt" :class="sSrch.postCtgryCode == '002' ? 'tw-swiper-active' : ''"  role="group" aria-label="3 / 7" tabindex="0" @click="clickCmmntCtgry('002')" >{{ $t('user.main.CMMNT.POST-CTGRY.002') }}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="swiper-slide tw-swiper-txt" :class="sSrch.postCtgryCode == '003' ? 'tw-swiper-active' : ''"  role="group" aria-label="4 / 7" tabindex="0" @click="clickCmmntCtgry('003')" >{{ $t('user.main.CMMNT.POST-CTGRY.003') }}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="swiper-slide tw-swiper-txt" :class="sSrch.postCtgryCode == '004' ? 'tw-swiper-active' : ''"  role="group" aria-label="5 / 7" tabindex="0" @click="clickCmmntCtgry('004')" >{{ $t('user.main.CMMNT.POST-CTGRY.004') }}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="swiper-slide tw-swiper-txt" :class="sSrch.postCtgryCode == '005' ? 'tw-swiper-active' : ''"  role="group" aria-label="6 / 7" tabindex="0" @click="clickCmmntCtgry('005')" >{{ $t('user.main.CMMNT.POST-CTGRY.005') }}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="swiper-slide tw-swiper-txt" :class="sSrch.postCtgryCode == '006' ? 'tw-swiper-active' : ''"  role="group" aria-label="7 / 7" tabindex="0" @click="clickCmmntCtgry('006')" >{{ $t('user.main.CMMNT.POST-CTGRY.006') }}</div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>


<script setup>
import { computed } from 'vue';
import { useStore } from "vuex";

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css'; // 기본 Swiper 스타일
import 'swiper/css/pagination'; // Swiper Pagination 스타일
import 'swiper/css/navigation'; // Swiper Navigation 스타일

const store = useStore();

const sSrch = computed(() => store.state.cmmnt.srch);

const clickCmmntCtgry = (pPostCtgryCode) => {
  const payload = {key:'postCtgryCode', value:pPostCtgryCode}
  store.dispatch("cmmnt/setCmmntSrchValue", payload);
  // store.dispatch("cmmnt/setCmmntFilterValue", payload);
}

</script>
