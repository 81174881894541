export default {
    namespaced: true,
    state: {
        latlot :{
            lat : 0,
            lot : 0,
        },
        location : {
            addressInfo : '',
            code : '',
            weather : {
                t1h : 0,
                rn1 : 0,
                reh : 0,
                pty : '',
                sky : '',
                uuu : 0,
                vvv : 0,
                vec : 0,
                wsd : 0,
            }
        },
        weather : {
            code : '',
            t1h : 0,
            rn1 : 0,
            reh : 0,
            pty : '',
            sky : '',
            uuu : 0,
            vvv : 0,
        }
    },
    getters: {},
    mutations: {
        setLocation(state, payload) {
            state.latlot.lat = payload.latitude;
            state.latlot.lot = payload.longitude;
        },
        setAddressInfo(state, payload) {
            state.location.addressInfo = payload;
        },
        setLocationWeather(state, payload) {
            state.location.weather = payload;
        },
        setWeather(state, payload) {
            state.weather = payload;
        }
    },
    actions: {
        setLocation({ state, commit }, payload) {
            commit('setLocation', payload);
        },
        setAddressInfo({ commit }, addressInfo) {
          commit('setAddressInfo', addressInfo);
        },
        setLocationWeather({ commit }, locationWeather) {
            commit('setLocationWeather', locationWeather);
        },
        setWeather({ commit }, weather) {
            commit('setWeather', weather);
        }
    },
};
