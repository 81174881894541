<template>

        <header class="tw-top">
          <a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
          <h1 class="tw-label-top">{{ $t('user.main.' + props.otpCode + '.TITLE')}}</h1>
        </header>

        <div class="tw-body">
            <div class="tw-body-text-wrap p-0">
              <h2 class="tw-label-default f24" >{{ $t('user.main.' + props.otpCode + '.MAIN.EMAIL')}}</h2>
              <label class="tw-label-default m-bt-30">{{ $t('user.main.' + props.otpCode + '.EMAIL.CNTN')}}</label>
            </div>
            <div class="tw-body-input-wrap bt-p" :class="{ 'error': !isEmailValid && isEmailTouched }">
              <label class="tw-label-default essential bt-6">{{ $t('user.main.' + props.otpCode + '.EMAIL.EMAIL-ADDRESS')}}</label>
              <div class="tw-input-wrap">
                <input 
                  type="text" 
                  class="tw-input-default" 
                  :class="{ 'error': !isEmailValid && isEmailTouched }"
                  :placeholder="$t('user.main.' + props.otpCode + '.EMAIL.EMAIL-ADDRESS-PH')" 
                  :aria-label="$t('user.main.' + props.otpCode + '.EMAIL.EMAIL-ADDRESS-PH')"
                  v-model="email"
                  @blur="validateEmail"
                >
                <span class="tw-input-icon" tabindex="0" @click="delEmail">input아이콘</span>
              </div>
              <label class="tw-label-body f12" v-show="!isEmailValid && isEmailTouched">{{ $t('user.main.' + props.otpCode + '.EMAIL.EMAIL-ERROR')}}</label>
            </div>

          <div class="tw-body-btn-wrap bottom">
            <button type="submit" class="tw-btn-default primary" aria-label="확인 버튼" :disabled="!isFormValid" @click="goRegOtp">{{ $t('user.main.' + props.otpCode + '.EMAIL.CHK')}}</button>
          </div>
        </div>

</template>


<script setup>

import { ref, onMounted, getCurrentInstance, computed } from 'vue'
import { useStore } from "vuex";
//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const store = useStore();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert } = useComm();

const props = defineProps({
  otpCode: {
    type: String,
    required: true
  }
});

import { useI18n } from 'vue-i18n';
const { locale } = useI18n();

const email = ref('')

const isEmailTouched = ref(false)

const isEmailValid = computed(() => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email.value)
})


const isFormValid = computed(() => {
  return isEmailValid.value
})

const validateEmail = () => {
  isEmailTouched.value = true
}

const delEmail = () => {
  email.value = '';
}

let nextRoute = 'signupOtp';

onMounted(() =>{
  switch(props.otpCode){
    case "RESET_PSWRD" :
      nextRoute = 'resetOtp'
      break;
    default:
      nextRoute = 'signupOtp'
      break;
  }
});

const goRegOtp = async () => {
  const otp = {
    id : email.value,
    locale : locale.value,
    otpCode : props.otpCode
  }

  await $axios({
      method: 'POST',
      data: otp,
      url: '/user/otp',
  }).then((data) => {
      const resultObj = data.resultObj;
      if (data.resultYn == 'Y') {
        let otp = {};
        otp.id = resultObj.id;
        otp.otpNum = resultObj.otpNum;
        store.dispatch("user/setOtp", otp);
        navigate({value: nextRoute});
      }else{
        const ERROR_CODE = data.resultCode;
        switch(ERROR_CODE){
          case "C007":
          case "M004":
          case "M008":
          case "M009":
            toggleCommAlert("ERROR", ERROR_CODE);
            break;
          default:
            toggleCommAlert("COMM", "FAILED", "ALERT");
            break;
        }
      }
  }).catch((error) => {
    toggleCommAlert("COMM", "FAILED", "ALERT");
  })
}

</script>