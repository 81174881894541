/**
 * 현재 시간을 AM/PM 형식으로 반환
 * @returns {string} "AM/PM HH:MM" 형식의 현재 시간
 */
export const getFormattedTime = () => {
  const date = new Date();
  return date.toLocaleTimeString([], {
    hour: '2-digit', 
    minute: '2-digit',
    hour12: true // AM/PM 표시 확실히 하기
  });
}

/**
 * 1653와 같은 시간 형식을 PM 04:53 형식으로 변환
 * @param {string} timeString - 시간 형식의 문자열 네 자리
 * @returns {string} 포맷된 시간 문자열
 */
export const formatTime24to12 = (timeString) => {
  // 입력값 유효성 검사
  if (!timeString || timeString.length !== 4) {
    return '';
  }

  try {
    // 시간과 분을 분리
    const hours = parseInt(timeString.slice(0, 2), 10);
    const minutes = timeString.slice(2);
    
    // AM/PM 결정
    const period = hours >= 12 ? 'PM' : 'AM';
    
    // 12시간제로 변환 (13시 -> 1시)
    const formattedHours = hours % 12 || 12;
    
    // 시간을 2자리 숫자로 패딩
    const paddedHours = String(formattedHours).padStart(2, '0');
    
    return `${period} ${paddedHours}:${minutes}`;
  } catch (error) {
    console.error('Error converting time format:', error);
    return '';
  }
};

/**
 * LocalDateTime 배열을 날짜/시간 문자열로 변환
 * @param {Array<number>} localDateTime - [year, month, day, hour, minute, second]
 * @returns {string} "YYYY-MM-DD HH:mm" 형식의 날짜/시간
 * @example
 * formatLocalDateTime([2024, 3, 15, 14, 30]) // "2024-03-15 14:30"
 */
export const formatLocalDateTime = (localDateTime) => {
  if (!localDateTime || !Array.isArray(localDateTime)) return '';
  
  const [year, month, day, hours, minutes] = localDateTime;
  
  // 필수 값이 없는 경우
  if (!year || !month || !day) return '';
  
  // month는 1을 빼지 않음 (이미 올바른 월 값으로 들어옴)
  const formattedMonth = String(month).padStart(2, '0');
  const formattedDay = String(day).padStart(2, '0');
  const formattedHours = String(hours || 0).padStart(2, '0');
  const formattedMinutes = String(minutes || 0).padStart(2, '0');
  
  return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}`;
};
