<template>
        <header class="tw-top">
          <a @click="goBack()" class="tw-top-icon">뒤로가기 아이콘</a>
          <h1 class="tw-label-top">{{ $t('user.main.MYPAGE.TOP.MOD') }}</h1>
        </header>

        <div class="tw-body">

            <div class="tw-body-input-wrap">
              <label class="tw-label-default bt-p">{{ $t('user.main.MYPAGE.MOD.PROFILE-PIC') }}</label>
              <div class="tw-my-img-wrap">
                <ul class="tw-img-box clearfix">
                  <li class="first-img-upload" tabindex="0" @click="clickImgUpload"><input type="file" id="prflFile" class="form-control" @change="imgChange" ref="prflFile" style="display:none" ></li>
                  <li v-show="bNewImgShow">
                    <div>
                      <img ref="previewImg" alt="나의 여행 관심사 첨부 사진">
                    </div>
                  </li>
                  <li v-show="!bNewImgShow && sTmpUser.prflFileFullPath">
                    <div>
                      <img :src="sTmpUser.prflFileFullPath" alt="나의 여행 관심사 첨부 사진">
                    </div>
                  </li>
                  <!-- <li>
                    <div>
                      <img src="/tripwith/assets/images/img-sample(2).png" alt="나의 여행 관심사 첨부 사진">
                    </div>
                    <span class="tw-img-del-icon" tabindex="0">삭제 icon</span>
                  </li>
                  <li>
                    <div>
                      <img src="/tripwith/assets/images/img-sample(3).png" alt="나의 여행 관심사 첨부 사진">
                    </div>
                    <span class="tw-img-del-icon" tabindex="0">삭제 icon</span>
                  </li>
                  <li>
                    <div>
                      <img src="/tripwith/assets/images/img-sample(4).png" alt="나의 여행 관심사 첨부 사진">
                    </div>
                    <span class="tw-img-del-icon" tabindex="0">삭제 icon</span>
                  </li>
                  <li>
                    <div>
                      <img src="/tripwith/assets/images/img-sample(5).png" alt="나의 여행 관심사 첨부 사진">
                    </div>
                    <span class="tw-img-del-icon" tabindex="0">삭제 icon</span>
                  </li> -->
                </ul>
              </div>
            </div>

            <!-- <div class="tw-body-input-wrap">
              <label class="tw-label-default essential bt-p">이메일 주소</label>
              <div class="tw-input-wrap">
                <input type="text" class="tw-input-default" placeholder="ABCDE@naver.com"  aria-label="이메일 주소 입력" disabled>
                <span class="tw-input-icon" tabindex="0">input아이콘</span>
              </div>
              <label class="tw-label-body f14" style="padding: 4px 16px;">최소 10자 이상, 특수문자를 포함해주세요.</label>
            </div>
            <div class="tw-body-input-wrap">
              <label class="tw-label-default essential  bt-p">비밀번호</label>
              <div class="tw-pw-wrap">
                <input type="password" class="tw-input-default" autocomplete="current-password" placeholder="비밀번호를 입력해주세요." aria-label="비밀번호 입력">
                <span class="tw-pw-icon" tabindex="0">pw아이콘</span>
              </div>
              <label class="tw-label-body f14" style="padding: 4px 16px;">최소 10자 이상, 특수문자를 포함해주세요.</label>
            </div>
            <div class="tw-body-input-wrap">
              <label class="tw-label-default essential  bt-p">비밀번호 확인</label>
              <div class="tw-pw-wrap">
                <input type="password" class="tw-input-default" autocomplete="current-password" placeholder="비밀번호를 입력해주세요." aria-label="비밀번호 재입력">
                <span class="tw-pw-icon" tabindex="0">pw아이콘</span>
              </div>
              <label class="tw-label-body f14" style="padding: 4px 16px;">최소 10자 이상, 특수문자를 포함해주세요.</label>
            </div>
             -->
            <div class="tw-body-input-wrap">
              <label class="tw-label-default essential  bt-p">{{ $t('user.main.MYPAGE.GNDR') }}</label>
              <div class="tw-select-wrap">
                <button ref="gndrBtn" type="button" class="tw-select-default" @click="openGndrModal('gndr', sTmpUser.gndr)" > {{ $t('user.main.MYPAGE.MP-GNDR.' + sTmpUser.gndr) }} </button>
              </div>
              <label v-show="bGndrErrorShow" class="tw-label-body">{{ $t('user.main.MYPAGE.MOD.GNDR-ERROR') }}</label>
            </div>
            <!-- <div class="tw-body-input-wrap">
              <label class="tw-label-default essential  bt-p">국적</label>
              <div class="tw-select-wrap">
                <button type="button" class="tw-select-default" @click="toggleCommLayer" aria-haspopup="true">한국</button>
              </div>
            </div> -->
            <!-- <div class="tw-007-col3">
              <div class="tw-body-input-wrap">
                <label class="tw-label-default essential bt-p">생년월일</label>
                <div class="tw-select-wrap">
                  <button type="button" class="tw-select-default">{{ sTmpUser.birthYear }}</button>
                </div>
              </div>
              <div class="tw-body-input-wrap">
                <div class="tw-select-wrap">
                  <button type="button" class="tw-select-default">{{ sTmpUser.birthMonth }}</button>
                </div>
              </div>
              <div class="tw-body-input-wrap">
                <div class="tw-select-wrap">
                  <button type="button" class="tw-select-default">{{ sTmpUser.birthDay }}</button>
                </div>
              </div>
            </div> -->
            <div class="tw-007-col3">
            </div>
            <div class="tw-body-input-wrap">
              <label class="tw-label-default essential bt-p">{{ $t('user.main.MYPAGE.MOD.NICK') }}</label>
              <div class="tw-input-wrap">
                <input type="text" class="tw-input-default" placeholder="Nickname" ref="nickInput" aria-label="닉네임 입력" v-model="sTmpUser.nick" maxlength="18">
                <span class="tw-input-icon"  tabindex="0">input아이콘</span>
              </div>
              <label v-show="bNickErrorShow" class="tw-label-body" v-html="nickErrorMessage">
              </label>
            </div>

            <div class="tw-body-btn-wrap last">
              <button type="submit" class="tw-btn-default primary" aria-label="수정하기 버튼" @click="modUser">{{$t('user.main.MYPAGE.MOD.BUTTON')}}</button>
            </div>
        </div>
        <modal></modal>
</template>


<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, onBeforeUnmount, watch } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
import { useAuth } from '@/page/main/composables/useAuth';
const { getUserInfoFromJwt } = useAuth();

import axiosWrpr from '@/util/axiosHelper';

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

const serverUrl = computed(() => store.getters.getServerUrl);
import modal from "@/page/main/comm/modal.vue";

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert } = useComm();

import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const nickErrorMessage = computed(() => t('user.main.SIGNUP.WRITE.NICK-ERROR'))

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 100 }, (value, index) => currentYear - index);
const selectedYear = ref('');
const selectedMonth = ref('');
const selectedDay = ref('');

const daysInMonth = computed(() => {
  if (!selectedYear.value || !selectedMonth.value) {
    return [];
  }
  const days = new Date(selectedYear.value, selectedMonth.value, 0).getDate();
  return Array.from({ length: days }, (value, index) => index + 1);
});

const sUser = computed(() => store.state.user.user);
const sTmpUser = computed(() => store.state.user.tmpUser);

const date = ref();
const openGndrModal = (pVal) => {
  const commLayer = {
        storeName : "user",
        stateName : "tmpUser",
        funcName : "setUserFromModal",
        valueName : pVal,
        langPath : 'user.main.mypage.MP-GNDR.',
        show: true,
        lst : ['001', '002']
  }
  toggleCommLayer(commLayer);
}

const previewImg = ref(null);
const bNewImgShow = ref(false);
const prflFile = ref(null);
const clickImgUpload = () => {
  prflFile.value.click();
}

let objectUrl = null;

const imgChange = (event) => {
  URL.revokeObjectURL(objectUrl);
  objectUrl = URL.createObjectURL(event.target.files[0]);
  previewImg.value.src = objectUrl;
  bNewImgShow.value = true;
  //URL.revokeObjectURL(objectUrl);
};

//필수 체크 관련
const gndrBtn = ref(null);
const nickInput = ref(null);

const bGndrErrorShow = ref(false);
const bNickErrorShow = ref(false);

function validateNick(nick) {
    // 닉네임 정규 표현식: 3글자 이상 20글자 이하, 한글, 영문, 숫자, 특수문자 !@#$%^&*()-_+=[]{}:<>,./만 허용
    const regex = /^[a-zA-Z0-9가-힣!@#$%^&*()\-_+=\[\]{}:<>,.\/]{3,20}$/;
    
    return regex.test(nick);
}

const modUser = async () => {
  // 유효성 검사
  if (sTmpUser.value.gndr === '000' || sTmpUser.value.gndr === null) {
    gndrBtn.value.classList.add("error");
    gndrBtn.value.parentElement.parentElement.classList.add("error");
    bGndrErrorShow.value = true;
    return;
  } else {
    gndrBtn.value.classList.remove("error");
    gndrBtn.value.parentElement.parentElement.classList.remove("error");
    bGndrErrorShow.value = false;
  }

  if (!validateNick(sTmpUser.value.nick)) {
    nickInput.value.classList.add("error");
    nickInput.value.parentElement.parentElement.classList.add("error");
    bNickErrorShow.value = true;
    return;
  } else {
    nickInput.value.classList.remove("error");
    nickInput.value.parentElement.parentElement.classList.remove("error");
    bNickErrorShow.value = false;
  }

  // FormData 구축
  const formData = new FormData();
  
  if (prflFile.value.files.length > 0) {
    formData.append('prflFile', prflFile.value.files[0]);
  }

  formData.append('userNum', sTmpUser.value.userNum);
  formData.append('gndr', sTmpUser.value.gndr);
  formData.append('nick', sTmpUser.value.nick);

  try {
    const response = await axiosWrpr({
      method: 'POST',
      data: formData,
      url: '/user/mod',
      meta: {
        requiresAuth: true  // meta 대신 headers에 추가
      }
    });

    const resultObj = response.resultObj;
    if (resultObj) {
      localStorage.setItem("authorization", resultObj);
      await getUserInfoFromJwt();
      toggleCommAlert("USER-MOD", "SUCCESS", "ALERT", () => {
        navigate({value:"mypageMain", querys: {} } );
      });
    } else {
      throw new Error('서버에서 유효한 응답을 받지 못했습니다.');
    }
  } catch (error) {
    console.error("사용자 정보 수정 중 오류 발생:", error);
    toggleCommAlert("USER-MOD", "FAILED");
  }
};

//회원정보 수정 화면에서 새로고침되었을 경우 처리
onMounted(() => {
  watch(
    () => sUser.value.userNum,
    (newValue, oldValue) => {
      if (newValue) {
        const user = {
          gndr : sUser.value.gndr,
          nick : sUser.value.nick,
          prflImgNum : sUser.value.prflImgNum,
          prflFileFullPath : sUser.value.prflFileFullPath,
          bLoginChk : true,
          userNum : sUser.value.userNum,
          birthYear : sUser.value.birthYear,
          birthMonth : sUser.value.birthMonth,
          birthDay : sUser.value.birthDay,
        }
        store.dispatch('user/setTmpUser', user);
      }
    },
     { immediate: true } 
  );
});

onBeforeUnmount(() => {
  URL.revokeObjectURL(objectUrl);
})

</script>