<template>
      <div class="tw-top main">
        <div class="tw-top-logo">
          <img src="/tripwith/assets/images/TripWith-logo-w.svg" alt="트립위드 로고">
          <!-- <span class="tw-alarm-icon">알림아이콘</span> -->
        </div>
        <div class="tw-top-search">
          <div class="tw-search-wrap">
            <input type="text" class="tw-input-default tw-input-search" :placeholder="$t('user.main.SEARCH.PLACEHOLDER')">
            <span class="tw-search-icon">search아이콘</span>
          </div>
        </div>
      </div>
      <div class="tw-wrap-content home current">
        <div class="tw-body main">          
          <bnrSwiper :bnrLst="bnrLst" />

          <div class="tw-main-info step1">
            <div class="tw-info-align">
              <h2 class="tw-label-title">{{$t('user.main.TRIPINFO.TITLE') }}</h2>
              <!-- <div class="tw-select-wrap small main">
                <button type="button" class="tw-select-default" aria-label="날씨/시차/환율 선택 버튼">날씨</button>
                <ul class="optionList">
                  <li class="optionItem" tabindex="0">날씨</li>
                  <li class="optionItem" tabindex="0">시차</li>
                  <li class="optionItem" tabindex="0">환율</li>
                </ul>
              </div> -->
            </div>
            <div class="tw-main-conbox-wrap step1">
              <nav>
                <ul class="tw-main-conbox">
                  <li class="tw-main-con" @click="goTripinfo('001')">
                    <a>
                      <p class="tw-con-bus-icon">대중교통 아이콘</p>
                      <p class="tw-con-txt" v-html="$t('user.main.TRIPINFO.TI-CTGRY.001')" ></p>
                    </a>
                  </li>
                  <li class="tw-main-con" @click="goTripinfo('002')">
                    <a>
                      <p class="tw-con-food-icon">식음료 아이콘</p>
                      <p class="tw-con-txt" v-html="$t('user.main.TRIPINFO.TI-CTGRY.002')"></p>
                    </a>
                  </li>
                  <li class="tw-main-con" @click="goTripinfo('003')">
                    <a>
                      <p class="tw-con-sho-icon">쇼핑 아이콘</p>
                      <p class="tw-con-txt" v-html="$t('user.main.TRIPINFO.TI-CTGRY.003')"></p>
                    </a>
                  </li>
                </ul>
              </nav>
              <nav>
                <ul class="tw-main-conbox">
                  <li class="tw-main-con" @click="goTripinfo('004')">
                    <a>
                      <p class="tw-con-act-icon">액티비티 아이콘</p>
                      <p class="tw-con-txt" v-html="$t('user.main.TRIPINFO.TI-CTGRY.004')"></p>
                    </a>
                  </li>
                  <li class="tw-main-con" @click="goTripinfo('005')">
                    <a>
                      <p class="tw-con-pop-icon">문화/관광 아이콘</p>
                      <p class="tw-con-txt" v-html="$t('user.main.TRIPINFO.TI-CTGRY.005')"></p>
                    </a>
                  </li>
                  <li class="tw-main-con" @click="goTripinfo('000')">
                    <a>
                      <p class="tw-con-plus-icon">더보기 아이콘</p>
                      <p class="tw-con-txt">{{$t('user.main.TRIPINFO.MORE')}}</p>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

            <div class="tw-body-line-wrap m-0">
              <p>라인</p>
            </div>
          </div>
        </div>
      <div id="f-tab-2" class="tw-wrap-content info">
        두번째
      </div>
      <div id="f-tab-3" class="tw-wrap-content commu">
        세번째
      </div>
      <div id="f-tab-4" class="tw-wrap-content my">
        네번째
      </div>

</template>

<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, reactive } from 'vue'
import { useStore } from "vuex";

import bnrSwiper from "@/page/main/comm/bnrSwiper.vue"

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate } = useNavigation();

const bnr = ref({});
const bnrLst = ref([]);

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

//vuex 설정
const store = useStore(); 

const getBnrLst = async (bnr) => {
    let tmpBnrLst;
    await $axios({
        method: 'POST',
        data: bnr,
        url: '/tripwith/bnr/lstview',
        showSpinner: false,
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpBnrLst = resultObj;
        }
    }).catch((error) => {
        alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
    })
    return tmpBnrLst;
};

const goTripinfo = (tripinfoCtgryCode) => {
  const data = {
        stateName : 'srch',
        key : 'tripinfoCtgryCode',
        value:tripinfoCtgryCode}
  store.dispatch("tripinfo/setTripinfoFromModal", data);
  navigate({value:'tripinfoSrch'});
}

onMounted( async () => {
  bnr.value.bnrCode = "23";
  bnr.value.lang = localStorage.getItem('lang') || 'en';
  bnrLst.value = await getBnrLst(bnr.value);
})

</script>