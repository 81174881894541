<template>
      <header class="tw-top">
        <a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
        <h1 class="tw-label-top">{{ props.postNum > 0 ? $t('user.main.CMMNT.POST-WRITE.TITLE-MOD') : $t('user.main.CMMNT.POST-WRITE.TITLE-REG') }}</h1>
        <button type="submit" class="tw-btn-text white right" aria-label="등록 버튼" @click="goRegCmmnt" > {{ $t('user.main.CMMNT.POST-WRITE.SAVE') }} </button>
      </header>

      <div class="tw-body p-0">
        <div class="tw-body-padding-wrap">
          <div class="tw-body-input-wrap">
            <h2 class="tw-label-default bold bt-p">{{ $t('user.main.CMMNT.POST-WRITE.TAG') }}</h2>
            <div class="tw-input-wrap tag-input-wrap">
              <form>
                <input id="tw-tag" type="text" class="tw-input-default tw-tag-input" :placeholder="$t('user.main.CMMNT.POST-WRITE.TAG-PLACEHOLDER')" v-model="sCmmnt.tag" ref="tagInput">
              </form>
            </div>
          </div>
          <div class="tw-body-input-wrap">
            <h2 class="tw-label-default bold bt-p">{{ $t('user.main.CMMNT.POST-WRITE.CTGRY') }}</h2>
            <div class="tw-select-wrap">
              <button class="tw-select-default" aria-label="카테고리 선택 셀렉트" aria-haspopup="true" @click="openCtgryModal('postCtgryCode', sCmmnt.postCtgryCode)" >{{ $t('user.main.CMMNT.POST-CTGRY.' + sCmmnt.postCtgryCode) }}</button>
            </div>
          </div>
          <div class="tw-body-board-wrap p-0">
            <div class="board-tit-wrap">
                <input type="text" class="tw-input-default board" :placeholder="$t('user.main.CMMNT.POST-WRITE.TITLE-PLACEHOLDER')" v-model="sCmmnt.title" maxlength="100">
                <span class="text-count"><span class="text-each">{{ textTitleCount }}</span>/100</span>
            </div>
            <div class="board-area-wrap">
              <textarea class="tw-textarea new bt-none" :placeholder="$t('user.main.CMMNT.POST-WRITE.CNTN-PLACEHOLDER')" v-model="sCmmnt.cntn"  maxlength="300"></textarea>
              <span class="textarea-count"><span class="text-each" >{{ textCntnCount }}</span>/300</span>
            </div>
          </div>

         
          <div class="tw-info-detail">
            <div class="tw-info-detail-body">
    
              <div class="detail-body-img-wrap">
                <ul>
                  <!-- <li  v-for="(file, index) in files" :key="index">
                    <div> <img :src="previews[index]" alt="첨부 이미지"> </div>
                    <span class="tw-delete-icon" tabindex="0" @click="removeFile(index)">삭제 아이콘</span>
                  </li> -->



                  <li  v-for="(preview, index) in previews" :key="index">
                    <div> <img :src="preview.fullPath" alt="첨부 이미지"> </div>
                    <span class="tw-delete-icon" tabindex="0" @click="removeFile(index)">삭제 아이콘</span>
                  </li>
                </ul>
              </div>


              <div v-if="pollData" class="detail-vote-attend-wrap update">
                <div class="detail-vote-attend-box">
                  <div class="vote-attend-top">
                    <div class="vote-left">
                      <span class="tw-vote-ing-icon">투표 아이콘</span>
                      <span class="tw-icon-text bold">{{ pollData.question }}</span>
                    </div>
                    <div class="vote-right">
                      <span class="tw-vote-update-icon" tabindex="0" @click="openPoll">투표 수정 아이콘</span>
                      <span class="tw-delete-icon" tabindex="0" @click="removePoll">투표 삭제 아이콘</span>
                    </div>
                  </div>
                  <div class="vote-attend-list">
                    <form>
                      <ul>
                        <li v-for="(option, index) in pollData.options" :key="index">
                          <button type="button" class="tw-vote-btn" aria-label="투표 선택 버튼">
                            <label :for="'agree' + index" class="radio_box">
                              <input 
                                type="radio" 
                                :id="'agree' + index" 
                                name="agree" 
                                :value="option" 
                                disabled
                                aria-label="투표 선택 라디오 버튼"
                              >
                              <span class="on2"></span>
                              {{ option.text }}
                            </label>
                          </button>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>


            </div>
          </div>



        </div>
        <div class="tw-info-detail-btn shadow fixed-bottom">
          <div class="info-bt-gallery icon" tabindex="0" @click="clickImgUpload">
            <span class="tw-gallery-icon">사진 아이콘</span>
            <span class="tw-gallery-txt">{{$t('user.main.CMMNT.POST-WRITE.PHOTO')}}</span>
            <input type="file" id="photos" multiple class="form-control" @change="addFiles" ref="imageUploadForm" style="display:none"  accept="image/png, image/jpeg" >
          </div>
          <div class="info-bt-location icon" tabindex="0">
            <span class="tw-location-icon">장소 아이콘</span>
            <span class="tw-location-txt disabled">{{$t('user.main.CMMNT.POST-WRITE.PLACE')}}</span>
          </div>
          <div class="info-bt-vote icon" @click="openPoll">
            <a>
              <span class="tw-vote-icon" >투표 아이콘</span>
              <span class="tw-vote-txt">{{$t('user.main.CMMNT.POST-WRITE.POLL')}}</span>
            </a>
          </div>
        </div>
      </div>
      <poll
        ref="pollRef"
        :is-visible="isPollVisible"
        @close="closePoll"
        @poll-complete="handlePollComplete"
      />
      <modal></modal>
</template>

<script setup>
import { ref, onMounted, onUnmounted, getCurrentInstance, computed, defineProps, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";
import Tagify from '@yaireo/tagify';
import '@yaireo/tagify/dist/tagify.css';
import modal from "@/page/main/comm/modal.vue";
import { useNavigation } from '@/page/main/composables/useNavigation';
import { useComm } from '@/page/main/composables/comm';
import Poll from '@/page/main/views/cmmnt/comm/poll.vue'

const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const route = useRoute();
const store = useStore();

const { navigate, goBack } = useNavigation();
const { toggleCommLayer, toggleCommAlert } = useComm();

const cmmnt = ref({});
const sCmmnt = computed(() => store.state.cmmnt.cmmnt);

const textTitleCount = computed(() => {
  return sCmmnt.value.title.length;
});

const textCntnCount = computed(() => {
  return sCmmnt.value.cntn.length;
});

const props = defineProps({
  postNum: String // 전달받은 속성 정의
});

const tagInput = ref(null);
let tagifyInstance = null;

const files = ref([]);
const previews = ref([]);
const delFileLst = ref([]); // 삭제

const maxFiles = 5;
let uniqueId = 0; // 고유 식별자 생성용 변수

const imageUploadForm = ref(null);


const openCtgryModal = (pVal) => {
  const commLayer = {
    storeName: "cmmnt",
    stateName: "cmmnt",
    funcName: "setCmmntFromModal",
    valueName: pVal,
    langPath: 'user.main.CMMNT.POST-CTGRY.',
    show: true,
    lst: ['001', '002', '003', '004', '005', '006']
  };
  toggleCommLayer(commLayer);
};

const goRegCmmnt = async () => {

  //formData 구축 시작
  const formData = new FormData();

  for(const file of files.value){
    formData.append('imgFiles', file.file);
  }

  delFileLst.value.forEach(postFileNum => 
    formData.append('delFileLst', postFileNum)); // 배열 데이터를 개별 항목으


  const postData = {
      postNum: sCmmnt.value.postNum,
      title: sCmmnt.value.title,
      cntn: sCmmnt.value.cntn,
      tag: sCmmnt.value.tag,
      postCtgryCode: sCmmnt.value.postCtgryCode,
      delYn: "N",
      useYn: "Y",
      langYn: "N"
  };

  // 투표 정보 case 
    // 1. 신규 등록일 경우
    // 2. 수정일 경우
    // 3. 삭제일 경우
    // 4. 삭제 후 신규 등록일 경우
    // 5. 데이터가 저장되기 전에 삭제한 경우
    if (pollData.value) { // 1번 2번 해결
        postData.poll = {
            pollNum: pollData.value.pollNum,
            question: pollData.value.question,
            pollOptions: pollData.value.options.map(option => ({
                optionText: option.text,
                pollOptionNum: option.optionNum
            }))
        };
    }

    if (isPollDeleted.value && cmmnt.value.pollNum) { // 3번 해결
        // 기존 투표가 있었고 삭제된 경우
        postData.poll = {
          ...postData.poll,
          delPollNum: cmmnt.value.pollNum,
          pollDeleted: isPollDeleted.value
        }
    }

  formData.append('postModel', JSON.stringify(postData));

  await $axios({
      method: 'POST',
      data: formData,
      url: '/tripwith/post/user',
  }).then((data) => {
    toggleCommAlert("CMMNT-REG", "SUCCESS", "ALERT", () => {
      navigate({value:"cmmntDtl", params: {postNum: data.postNum} } );
    });
  }).catch((error) => {
      alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
  })
};


const goGetCmmnt = async (postNum) => {
  let cmmnt;
    await $axios({
        method: 'GET',
        params: cmmnt,
        url: `/tripwith/post/user/${postNum}`,
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            cmmnt = resultObj;
        }
    }).catch((error) => {
        alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
    })
    return cmmnt;
};

const onChangeTags = (e) => {
  const tagMap = tagifyInstance.value.map(tag => tag.value);
  const tagJoin = tagMap.join(',');
  store.dispatch('cmmnt/setCmmntTag', tagJoin);
};

const clickImgUpload = () => {
  if (files.value.length >= maxFiles) {
    alert("최대 10개의 이미지만 추가할 수 있습니다.");
  } else {
    imageUploadForm.value.click();
  }
};

const addFiles = (event) => {
  const newFiles = Array.from(event.target.files);
  const totalPossibleFiles = files.value.length + newFiles.length;

  if (totalPossibleFiles > maxFiles) {
    alert(`최대 ${maxFiles}개의 이미지만 업로드할 수 있습니다. 현재 선택 가능한 이미지 수: ${maxFiles - files.value.length}`);
    return;
  }

  newFiles.forEach((file) => {
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        previews.value.push({fullPath : e.target.result, id: uniqueId });
        files.value.push({file, id: uniqueId++ });
      };
      reader.readAsDataURL(file);
    }
  });

  imageUploadForm.value.value = '';

};

const removeFile = (index) => {
  const preview = previews.value[index];
  if (preview.postFileNum) {
    delFileLst.value.push(preview.postFileNum); // postFileNum이 있으면 삭제 목록에 추가
  }

   const fileIndex = files.value.findIndex(f => f.id === preview.id); // 고유 식별자를 사용하여 파일 찾기
  if (fileIndex !== -1) {
    files.value.splice(fileIndex, 1); // 파일 목록에서 제거
  }
  previews.value.splice(index, 1); // 미리보기 목록에서 제거
};

onMounted(async () => {

  tagifyInstance = new Tagify(tagInput.value, { maxTags: 10 });
  tagifyInstance.on('add', onChangeTags);
  tagifyInstance.on('remove', onChangeTags);

  if (props.postNum) {
    cmmnt.value = await goGetCmmnt(props.postNum);
    store.dispatch('cmmnt/setCmmntFromWrite', cmmnt.value);

    const fileLst = cmmnt.value.postFileModelLst;
    if (fileLst.length > 0) {
      fileLst.forEach(file => previews.value.push(file));
    }
    // 투표 데이터 처리
    if (cmmnt.value.pollQuestion && cmmnt.value.pollOptions.length > 0) {
      pollData.value = {
        pollNum: cmmnt.value.pollNum, // pollNum 추가
        question: cmmnt.value.pollQuestion,
        options: cmmnt.value.pollOptions
          .sort((a, b) => Number(a.pollOptionNum) - Number(b.pollOptionNum))
          .map(option => ({
            optionNum: option.pollOptionNum, // 각 옵션의 번호 저장
            text: option.optionText
          }))
      };
    }
  }else{
    store.dispatch('cmmnt/clearCmmnt');
  }
});

onUnmounted(() => {
  if (tagifyInstance) {
    tagifyInstance.off('add', onChangeTags);
    tagifyInstance.off('remove', onChangeTags);
    tagifyInstance.destroy();
  }
});

// Poll 관련 상태 추가
const isPollVisible = ref(false)
const pollRef = ref(null);
const pollData = ref(null);
const isPollDeleted = ref(false);

const handlePollComplete = (data) => {
  pollData.value = data;
  closePoll();
};

const openPoll = () => {
  isPollVisible.value = true;
  // 기존 데이터가 있으면 자식 컴포넌트에 전달
  nextTick(() => {
    if (pollData.value && pollRef.value) {
      pollRef.value.setPollData(pollData.value);
    }
  });
};

const closePoll = () => {
  isPollVisible.value = false
}

const removePoll = () => {
  const onConfirm = () => {
    isPollDeleted.value = true; // 삭제 플래그 설정
    pollData.value = null;
    if (pollRef.value) {
      pollRef.value.setPollData({
        question: '',
        options: [
          { text: '' },
          { text: '' }
        ]
      });
    }
  }

  toggleCommAlert("VOTE-DELETE", "CONFIRM", "CONFIRM", onConfirm);
};
</script>
