import { ref } from 'vue';
import { useComm } from './comm';
import { 
  goGetPostLst,
  togglePostLike, 
  togglePostDibs,
  delPost
} from '@/services/postService';

export function usePost(initialState = {}) {
  const { toggleCommAlert, loginChk } = useComm();

  const posts = ref([]);
  const pageInfo = ref({
    currentPage: 0,
    totalPages: 0,
    ...initialState
  });

  const getPostList = async (params) => {
    try {
      const response = await goGetPostLst(params);
      if (response.resultYn === 'Y' && response.resultObj) {
        const { content, totalPages } = response.resultObj;
        pageInfo.value.totalPages = totalPages;
        return content;
      }
      return null;
    } catch (error) {
      toggleCommAlert("COMM", "FAILED");
    }
  };

  const loadInitialPosts = async (params) => {
    pageInfo.value.currentPage = 0;
    const content = await getPostList({
      ...params,
      pageNum: pageInfo.value.currentPage
    });
    if (content) {
      posts.value = content;
      pageInfo.value.currentPage++;
    }
    return content;
  };

  const loadMorePosts = async (params) => {
    if (pageInfo.value.currentPage >= pageInfo.value.totalPages) return;
    
    const content = await getPostList({
      ...params,
      pageNum: pageInfo.value.currentPage
    });
    if (content) {
      posts.value.push(...content);
      pageInfo.value.currentPage++;
    }
    return content;
  };

  const handlePostLike = async (post) => {
    if (!loginChk()) return;
    
    try {
      const response = await togglePostLike(post.postNum, post.likedByUser);
      if (response.resultYn === 'Y') {
        post.likedByUser = !post.likedByUser;
        post.likeCnt += post.likedByUser ? 1 : -1;
      }
    } catch (error) {
      toggleCommAlert("COMM", "FAILED");
    }
  };

  const handlePostDibs = async (post) => {
    if (!loginChk()) return;
    
    try {
      const response = await togglePostDibs(post.postNum, post.dibsedByUser);
      if (response.resultYn === 'Y') {
        post.dibsedByUser = !post.dibsedByUser;
      }
    } catch (error) {
      toggleCommAlert("COMM", "FAILED");
    }
  };

  const handleDelPost = async (post, onSuccess) => {
    try {
      const response = await delPost(post.postNum);
      if (response.resultYn === 'Y') {
        toggleCommAlert("CMMNT-DEL", "SUCCESS", "ALERT", () => {
          // 성공 콜백 실행
          if (typeof onSuccess === 'function') {
            onSuccess();
          }
        });
      } else {
        toggleCommAlert("CMMNT-DEL", "FAIL", "ALERT");
      }
    } catch (error) {
      logger.error("Delete post failed:", error);
      toggleCommAlert("CMMNT-DEL", "FAIL", "ALERT");
    }
  };

  return {
    posts,
    pageInfo,
    loadInitialPosts,
    loadMorePosts,
    handlePostLike,
    handlePostDibs,
    handleDelPost
  };
}