<template>

  <header class="tw-top">
      <a class="tw-top-icon" @click="goBack()">뒤로가기 아이콘</a>
    <h1 class="tw-label-top">{{ $t('user.main.MYPAGE.LST.TRIP-SETTING.NTC') }}</h1>
  </header>

  <div class="tw-body p-0">
    <div class="tw-info-detail">
      <div class="tw-info-detail-top">
        <div class="detail-top-info-wrap">
          <div class="detail-top-info-img">
            <img src="/tripwith/assets/images/TripWith-logo-ver.svg" alt="닉네임 프로필 이미지">
          </div>
          <div class="detail-top-info-id">Team TripWith
            <!-- <span class="id-badge">
              <img src="/tripwith/assets/images/img-level-total-1.svg" alt="뱃지 이미지">
            </span> -->
          </div>
          <div class="detail-top-info-date">{{ convertTimestampToFormattedDate(cmmnt.regDt) }}</div>
        </div>    
      </div>
      <div class="tw-info-detail-body">
        <div class="detail-body-txt">
          <h2>{{ cmmnt.title}}</h2>
          <div v-html="cmmnt.cntn"></div>
        </div>

        <div class="detail-body-img-wrap">
          <ul>
            <li v-for="(img, index) in imgFiles" :key="index">
              <div> <img :src="img.fullPath" alt="첨부 이미지"> </div>
            </li>
          </ul>
        </div>
        <!-- <div class="detail-vote-attend-wrap">
          <div class="detail-vote-attend-box">
            <div class="vote-attend-top">
              <span class="tw-vote-ing-icon">투표 아이콘</span>
              <span class="tw-icon-text bold">투표</span>
              <span class="tw-icon-text">10명 참여</span>
            </div>
            <div class="vote-attend-list">
              <form>
                <ul>
                  <li>
                    <button type="button" class="tw-vote-btn" aria-label="투표 선택 버튼">
                      <label for="agree1" class="radio_box">
                        <input type="radio" id="agree1" name="agree" value="동의" checked="" aria-label="투표 선택 라디오 버튼">
                        <span class="on2"></span>
                        여행 콘텐츠 부족
                      </label>
                    </button>
                  </li>
                  <li>
                    <button type="button" class="tw-vote-btn" aria-label="투표 선택 버튼">
                      <label for="agree2" class="radio_box">
                        <input type="radio" id="agree2" name="agree" value="동의" checked="" aria-label="투표 선택 라디오 버튼">
                        <span class="on2"></span>
                        여행 콘텐츠 부족
                      </label>
                    </button>
                  </li>
                  <li>
                    <button type="button" class="tw-vote-btn" aria-label="투표 선택 버튼">
                      <label for="agree3" class="radio_box">
                        <input type="radio" id="agree3" name="agree" value="동의" checked="" aria-label="투표 선택 라디오 버튼">
                        <span class="on2"></span>
                        여행 콘텐츠 부족
                      </label>
                    </button>
                  </li>
                </ul>
              </form>
            </div>
            <div class="tw-body-btn-wrap flex">
              <button type="submit" class="tw-btn-default primary" aria-label="투표하기 버튼">투표하기</button>
              <button type="button" class="tw-btn-default border" aria-label="결과보기 버튼">결과보기</button>
            </div>
          </div>
        </div> -->

        <!-- <div class="detail-vote-attend-wrap result">
          <div class="detail-vote-attend-box">
            <div class="vote-attend-top">
              <span class="tw-vote-ing-icon">투표 아이콘</span>
              <span class="tw-icon-text bold">투표</span>
              <span class="tw-icon-text">10명 참여</span>
              <button type="button" class="tw-btn-text" aria-label="다시 투표하기 버튼"> 다시 투표하기 </button>
            </div>
            <div class="vote-attend-list">
              <ul>
                <li>
                  <div class="vote-result-box" id="progressbar1">
                    <span class="result-txt-left">본 서비스 목적 이용 사라짐</span>
                    <span class="result-txt-right">4명</span>
                  </div>
                </li>
                <li>
                  <div class="vote-result-box" id="progressbar2">
                    <span class="result-txt-left">본 서비스 목적 이용 사라짐</span>
                    <span class="result-txt-right">2명</span>
                  </div>
                  
                </li>
                <li>
                  <div class="vote-result-box" id="progressbar3">
                    <span class="result-txt-left">본 서비스 목적 이용 사라짐</span>
                    <span class="result-txt-right">1명</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div> -->

      </div>
      <div class="tw-info-detail-btn">
          <!-- <div
            :class="['info-bt-like icon', { on: cmmnt.likedByUser }]"
            tabindex="0"
            @click="togglePostLike(cmmnt)"
          >
            <span class="tw-like-icon">좋아요 아이콘</span>
            <span class="tw-like-txt">{{ cmmnt.likeCount > 0 ? cmmnt.likeCount : $t('user.main.CMMNT.MAIN.LIKE')}}</span>
          </div> -->
        <div class="info-bt-share icon" tabindex="0">
          <span class="tw-share-icon">공유하기 아이콘</span>
          <span class="tw-share-txt">{{$t('user.main.CMMNT.MAIN.SHARE')}}</span>
        </div>
      </div>

      <!-- <div class="tw-info-detail-send">
        <div class="detail-send-img">
          <img src="/tripwith/assets/images/img-sample(5).png" alt="닉네임 프로필 이미지">
        </div>
        <div class="detail-send-input">
          <form>
            <input type="text" class="tw-input-default tw-input-comment" aria-label="댓글 달기">
          </form>
        </div>
        <button type="submit" class="detail-send-icon" aria-label="보내기 버튼">보내기</button>
      </div> -->


    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount, getCurrentInstance, defineProps, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { createLogger, useStore } from "vuex";
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();

//글로벌 프로퍼티 설정 부분
import { useGlobalProperties } from '@/page/main/composables/useGlobalProperties'; 
const { $axios, $axiosJson } = useGlobalProperties();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert, loginChk } = useComm();

const route = useRoute();
const store = useStore();

const props = defineProps({
postNum: {
type: String,
required: true
}
});

const postNum = props.postNum;
const cmmnt = ref({});
const imgFiles = ref([]);

const sUser = computed(() => store.state.user.user);

const goGetCmmnt = async (pPostNum) => {
let cmmnt = new Object;
cmmnt.postNum = pPostNum;
cmmnt.lang = locale.value;
await $axios({
    method: 'GET',
    params: cmmnt,
    url: `/tripwith/post/user/${postNum}`,
}).then((data) => {
    const resultObj = data.resultObj;
    if (resultObj) {
        cmmnt = resultObj;
    }
}).catch((error) => {
  toggleCommAlert("COMM", "FAILED");
})
return cmmnt;
};

onMounted( async () => {
cmmnt.value = await goGetCmmnt(postNum);
})


function convertTimestampToFormattedDate(timestamp) {
if(timestamp == undefined){
  return '';
}

// timestamp 값을 milliseconds로 변환
const date = new Date(timestamp);

// 원하는 형식으로 날짜를 포맷
const options = { 
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit' 
};

// 로케일을 'ko-KR'로 설정하여 한국 형식으로 포맷
const formattedDate = new Intl.DateTimeFormat('ko-KR', options).format(date);

// 'yyyy. MM. dd' 포맷으로 반환
return formattedDate.replace(/\. /g, '.');
}
</script>