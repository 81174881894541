<template>

      <div class="tw-top">
        <span class="tw-top-icon">top아이콘</span>
        <label class="tw-label-top">로그인</label>
      </div>
      <div class="tw-body">
        <div class="tw-body-input-wrap">
          <label class="tw-label-default essential">이메일 주소</label>
          <div class="tw-input-wrap">
            <input type="text" class="tw-input-default" placeholder="이메일 주소를 입력해주세요.">
            <span class="tw-input-icon">input아이콘</span>
          </div>
        </div>
        <div class="tw-body-input-wrap">
          <label class="tw-label-default essential">비밀번호</label>
          <div class="tw-pw-wrap">
            <input type="password" class="tw-input-default" autocomplete="current-password" placeholder="비밀번호를 입력해주세요.">
            <span class="tw-pw-icon">pw아이콘</span>
          </div>
        </div>
        <div class="tw-body-btn-wrap">
          <button type="button" class="tw-btn-default primary">로그인</button>
          <button type="button" class="tw-btn-default border">회원가입</button>
        </div>
        <div class="tw-body-pw-wrap">
          <button type="button" class="tw-btn-text no-line"> 이메일로 비밀번호 찾기 </button>
          <label class="tw-label-small"> ｜ </label>
          <button type="button" class="tw-btn-text no-line"> 전화번호로 비밀번호 찾기 </button>
        </div>
        <div class="tw-body-line-wrap">
          <p>라인</p>
          <div>
            <label class="tw-label-title">간편하게 시작하기</label>
          </div>
        </div>
        <div class="tw-body-sns-wrap">
          <button type="button" class="tw-btn-sns" @click="clickKakaoSmplLoginBtn">
            <span class="tw-kko-icon">카카오톡로고</span>
          </button>
          <button type="button" class="tw-btn-sns" @click="clickNaverSmplLoginBtn">
            <span class="tw-nav-icon">네이버로고</span>
          </button>
          <button type="button" class="tw-btn-sns" @click="clickGoogleSmplLoginBtn">
            <span class="tw-goo-icon">구글로고</span>
          </button>
          <!-- <button type="button" class="tw-btn-sns" @click="clickFacebookSmplLoginBtn" >
            <span class="tw-fac-icon">페이스북로고</span>
          </button>
          <button type="button" class="tw-btn-sns">
            <span class="tw-app-icon">애플로고</span>
          </button>
          <button type="button" class="tw-btn-sns">
            <span class="tw-wec-icon">위챗로고</span>
          </button> -->
        </div>
      </div>
</template>


<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

//유저 정보 처리 시작
import cnst from '@/util/authConstants';

const user = ref({});
const userId = ref(null);
const userPw = ref(null);
const baseUrl = process.env.VUE_APP_SERVER_URL;
const frontUrl = process.env.VUE_APP_FRONT_URL;

user.value.id = "";
user.value.pw = "";
user.value.gbn = cnst.USER.GBN.USER;

function goRouterByName(name){
    router.push({
        name : name,
    }).then(() => window.scrollTo(0,0));
}


async function goLogin() {
    if(user.value.id == ""){
        userId.value.focus()
        return false;
    }

    if(user.value.pw == ""){
        userPw.value.focus()
        return false;
    }

    await $axios({
        method: 'POST',
        data: user.value,
        url: '/user/login',
        }).then((data) => {
            store.dispatch('cust/setCust', cnst.SMPL.NORMAL.SMPL_LOGIN_CODE);
            goRouterByName('main');
        }).catch(error => {
            alert("로그인에 실패하였습니다. ");
        });
}

//네이버 간편로그인
function clickNaverSmplLoginBtn(){
    const naverUrl = 'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id='
        + cnst.SMPL.NAVER.CLIENT_ID
        + '&state=STATE_STRING&redirect_uri='
        + frontUrl + '/' + cnst.SMPL.NAVER.CALLBACK_URL;

    window.location.href = naverUrl;
}

function clickKakaoSmplLoginBtn(){
    const kakaoUrl = 'https://kauth.kakao.com/oauth/authorize?client_id='
        + cnst.SMPL.KAKAO.CLIENT_ID
        + '&state=STATE_STRING&redirect_uri='
        + frontUrl + '/' + cnst.SMPL.KAKAO.CALLBACK_URL
        + '&response_type=code';

    window.location.href = kakaoUrl;

}

function clickGoogleSmplLoginBtn(){
    const googleUrl = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=' 
        + cnst.SMPL.GOOGLE.CLIENT_ID
        + '&redirect_uri='
        + frontUrl + '/' + cnst.SMPL.GOOGLE.CALLBACK_URL
        + '&response_type=code'
        + '&scope=email profile';
    window.location.href = googleUrl;

}

function clickFacebookSmplLoginBtn(){

    const facebookUrl = "https://www.facebook.com/v11.0/dialog/oauth?client_id="
                  + cnst.SMPL.FACEBOOK.CLIENT_ID
                  + "&redirect_uri="
                  + cnst.SMPL.FACEBOOK.CALLBACK_URL
                  + "&response_type=code"
                  + "&scope=email,public_profile";
    window.location.href = facebookUrl;
}


onMounted(() => {
    window.fbAsyncInit = function() {
    window.FB.init({
      appId      : cnst.SMPL.FACEBOOK.CLIENT_ID,
      cookie     : true,
      xfbml      : true,
      version    : 'v11.0'
    });
    window.FB.AppEvents.logPageView();
    };
})


</script>