export default {
    namespaced: true,
    state: {
        comm : {
            storeName : 'comm',
            show : false,
            title : "comm",
            code : "comm",
            mode : "ALERT", // ALERT / CONRFIME
            event : "comm",
        },
        agree : {
            storeName : 'comm',
            show : false,
            title : "comm",
            code : "comm",
            mode : "ALERT", // ALERT / CONRFIME
            event : "comm",
            boardNum : process.env.VUE_APP_BOARD_TNC,
        },
    },
    getters: {},
    mutations: {
        setAlert(state, payload) {
            state[payload.stateName] = payload;
        },
        clearAlert(state, payload) {
            const comm = {
                storeName : 'comm',
                show : false,
                title : "comm",
                code : "comm",
                mode : "ALERT",
                event : "comm",
            }

            state.comm = comm;
        },
        closeAlert(state, payload){
            state[payload.stateName]['show'] = false;
        },
        openAlert(state, payload){
            state[payload.stateName]['show'] = true;
        },
        setAlertColumn(state, payload) {
            state[payload.stateName][payload.columnName] = payload.value;
            console.log(payload);
        },
    },
    actions: {
        setAlert({ state, commit }, payload) {
            commit('setAlert', payload);
        },
        clearAlert({ state, commit }, payload) {
            commit('clearAlert', payload);
        },
        openAlert({ state, commit }, payload) {
            commit('openAlert', payload);
        },
        closeAlert({ state, commit }, payload) {
            commit('closeAlert', payload);
        },
        setAlertColumn({ state, commit }, payload) {
            commit('setAlertColumn', payload);
        },
    },
};
