import { ref, reactive, computed } from 'vue';
import { useComm } from './comm';
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';

import * as tripinfoService from '@/services/tripinfoService';

export function useTripinfo() {
  const store = useStore();
  const { locale } = useI18n();
  const { toggleCommAlert, loginChk } = useComm();

  // 상태 관리
  const tripinfo = ref({
      tripinfoNum: '',
      title: '',
      cntn: '',
      lang: locale.value,
      videoUrl: '',
      dibsedByUser: false,
      likedByUser: false,
      likeCnt: 0,
      cmntCnt: 0,
      pageNum: 0,
      pageSize: 10
  });

  const tripinfoCmntList = ref([]);

  // 댓글 관련 상태
  const tripinfoCmnt = ref({
      tripinfoNum: '',
      upprTripinfoCmntNum: null,
      cntn: ''
  });

  const sTripinfoCmnt = computed(() => {
    return store.getters['tripinfo/getCmntData'];
  });

  // 페이징 관련 상태
  const tripinfoPageNum = reactive({
      '001': 0,
      '002': 0,
      '003': 0,
      '004': 0
  });

  const tripinfoTotalpages = reactive({
      '001': 0,
      '002': 0,
      '003': 0,
      '004': 0
  });

  const tripinfoLists = reactive({
      '001': [],
      '002': [],
      '003': [],
      '004': []
  });

// tripinfo 초기화 함수 추가
const initTripinfo = (tripinfoNum) => {
  tripinfo.value = {
      tripinfoNum,
      title: '',
      videoUrl: '',
      lang: locale.value,
      cntn: '',
      dibsedByUser: false,
      likedByUser: false,
      likeCnt: 0,
      cmntCnt: 0
  };
  
  tripinfoCmnt.value = {
      tripinfoNum,
      upprTripinfoCmntNum: null,
      cntn: ''
  };
};

// 댓글 초기화 함수 추가
const initTripinfoCmnt = (tripinfoNum, upprTripinfoCmntNum = null) => {
  tripinfoCmnt.value = {
      tripinfoNum,
      upprTripinfoCmntNum,
      cntn: ''
  };
  tripinfoCmntList.value = [];
};
  // 트립인포 목록 조회
  const getTripinfoList = async (params) => {
      try {
          const result = await tripinfoService.getTripinfoList({
              ...params,
              lang: locale.value
          });
          
          if (result?.content) {
              tripinfoPageNum[params.tripinfoOrderCtgryCode]++;
              tripinfoTotalpages[params.tripinfoOrderCtgryCode] = result.totalPages;
              return result.content;
          }
          return [];
      } catch (error) {
          toggleCommAlert("COMM", "FAILED");
          return [];
      }
  };

  // 트립인포 상세 조회
  const getTripinfoDetail = async (pTripinfoNum) => {
      try {
          const param = {
              tripinfoNum: pTripinfoNum,
              lang: locale.value
          };

          store.dispatch('tripinfo/setCmntData', {
            tripinfoNum: pTripinfoNum,
            upprTripinfoCmntNum: null
        });

          const tripinfoData = await tripinfoService.getTripinfoDetail(param);
          const cmntList = await tripinfoService.getTripinfoCmntList(sTripinfoCmnt.value.tripinfoNum);
          
          tripinfo.value = { ...tripinfoData };
          store.dispatch('tripinfo/setCmntLst', cmntList);
          
          return { tripinfoData, cmntList };
      } catch (error) {
          toggleCommAlert("COMM", "FAILED");
          return { tripinfoData: null, cmntList: [] };
      }
  };

  // 댓글 목록 조회
  const getTripinfoCmntList = async (tripinfoNum, upprTripinfoCmntNum = null) => {
      try {
          const cmntList = await tripinfoService.getTripinfoCmntList(tripinfoNum, upprTripinfoCmntNum);
          store.dispatch('tripinfo/setCmntLst', cmntList);
          return cmntList;
      } catch (error) {
          toggleCommAlert("COMM", "FAILED");
          return [];
      }
  };

  // 댓글 등록
  const regTripinfoCmnt = async (cmntData) => {
      if (!loginChk()) return;

      try {
          const response = await tripinfoService.regTripinfoCmnt(cmntData);
          if (response.resultYn === 'Y') {
              store.dispatch('tripinfo/pushCmnt', response.resultObj);
              tripinfoCmnt.value.cntn = '';
              return response.resultObj;
          }
      } catch (error) {
          toggleCommAlert("COMM", "FAILED");
      }
  };

// 댓글 수정
const modTripinfoCmnt = async (pTripinfoCmnt, inputValue) => {
  if (!loginChk()) return;
  
  if (!inputValue) {
      return false;
  }

  try {
      const cmntData = {
          cntn: inputValue,
          tripinfoCmntNum: pTripinfoCmnt.tripinfoCmntNum,
          upprTripinfoCmntNum: pTripinfoCmnt.upprTripinfoCmntNum
      };

      const response = await tripinfoService.modTripinfoCmnt(cmntData);
      
      if (response.resultYn === 'Y') {
          return response.resultObj;
      }
  } catch (error) {
      toggleCommAlert("COMM", "FAILED");
          return false;
      }
  };

  //댓글 삭제
  const delTripinfoCmnt = async (pTripinfoCmnt) => {
    if (!loginChk()) return;

    try {
        const response = await tripinfoService.delTripinfoCmnt(pTripinfoCmnt.tripinfoCmntNum);
        
        if (response.resultYn === 'Y') {
            //삭제 요청 후 리스트 다시 조회
            const cmntList = await getTripinfoCmntList(sTripinfoCmnt.value.tripinfoNum, sTripinfoCmnt.value.upprTripinfoCmntNum);
            store.dispatch('tripinfo/setCmntLst', cmntList);
            toggleCommAlert("TRIPINFO-CMNT-DEL", "SUCCESS", "ALERT");
            return response.resultObj;
        } else {
            toggleCommAlert("TRIPINFO-CMNT-DEL", "FAIL", "ALERT");
        }
    } catch (error) {
        toggleCommAlert("TRIPINFO-CMNT-DEL", "FAIL", "ALERT");
        return false;
    }
};

  // 좋아요 토글
  const toggleTripinfoLike = async (pTripinfo) => {
      if (!loginChk()) return;

      try {
          const response = await tripinfoService.toggleTripinfoLike(
              pTripinfo.tripinfoNum, 
              pTripinfo.likedByUser
          );

          if (response.resultYn === 'Y') {
              pTripinfo.likedByUser = !pTripinfo.likedByUser;
              pTripinfo.likeCnt += pTripinfo.likedByUser ? 1 : -1;
          }
      } catch (error) {
          toggleCommAlert("COMM", "FAILED");
      }
  };

  // 찜하기 토글
  const toggleTripinfoDibs = async (pTripinfo) => {
      if (!loginChk()) return;

      try {
          const response = await tripinfoService.toggleTripinfoDibs(
              pTripinfo.tripinfoNum, 
              pTripinfo.dibsedByUser
          );

          if (response.resultYn === 'Y') {
              pTripinfo.dibsedByUser = !pTripinfo.dibsedByUser;
          }
      } catch (error) {
          toggleCommAlert("COMM", "FAILED");
      }
  };

  // 날짜 포맷 변환
  const convertTimestampToFormattedDate = (timestamp) => {
      if (!timestamp) return '';

      const date = new Date(timestamp);
      const options = { 
          year: 'numeric', 
          month: '2-digit', 
          day: '2-digit' 
      };
      
      const formattedDate = new Intl.DateTimeFormat('ko-KR', options).format(date);
      return formattedDate.replace(/\. /g, '.');
  };

  return {
      // 상태
      tripinfo,
      tripinfoCmnt,
      tripinfoCmntList,
      tripinfoPageNum,
      tripinfoTotalpages,
      tripinfoLists,

      // 메서드
      initTripinfo,
      initTripinfoCmnt,
      getTripinfoList,
      getTripinfoDetail,
      getTripinfoCmntList,
      regTripinfoCmnt,
      modTripinfoCmnt,
      delTripinfoCmnt,
      toggleTripinfoLike,
      toggleTripinfoDibs,
      convertTimestampToFormattedDate
  };
}