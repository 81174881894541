import { goGetFus, goGetUserRegions } from '@/services/weatherService';
import { useComm } from '@/page/main/composables/comm';
import weatherRegData from '@/assets/data/weather_reg.json';
import { useI18n } from 'vue-i18n';

export const useWeather = () => {
  const { toggleCommAlert } = useComm();
  const { locale } = useI18n();

  const getWeatherClass = (sky, pty) => {
    if (pty == 1 || pty == 2 || pty == 5 || pty == 6) {
      return 'rain';
    } else if (pty == 3 || pty == 7) {
      return 'snow';
    } else {
      switch (sky) {
        case 1:
          return 'sunny';
        case 3:
          return 'partly-cloudy';
        case 4:
          return 'cloudy';
        default:
          return 'sunny'; // 기본값
      }
    }
  }

  const getFusData = async (nx, ny) => {
    return await goGetFus(nx, ny);
  }


  const getUserRegions = async () => {
    try {
      const resultObj = await goGetUserRegions();

      if (!resultObj) return [];

      const regionsWithWeather = await Promise.all(
        resultObj.map(async (item) => {
          try {
            const weatherRegNum = String(item.id.weatherRegNum);
            
            const regionInfo = weatherRegData.find(
              (region) => String(region.WEATHER_REG_NUM) === weatherRegNum
            );

            if (!regionInfo) return null;

            const weatherData = await getFusData(regionInfo.X, regionInfo.Y);
            
            return {
              ...regionInfo,
              weatherData
            };
          } catch (error) {
            console.error('Error processing region:', error);
            return null;
          }
        })
      );

      return regionsWithWeather.filter(region => region !== null);

    } catch (error) {
      console.error('Error fetching regions:', error);
      toggleCommAlert('COMM', 'FAILED');
      return [];
    }
  };

  const getRegionName = (region, step) => {
    if (!region || !step) return '';
    return locale.value === 'en' ? region[`${step}_EN`] : region[`${step}_KR`];
  };
  

  const convertToGridCoord = (lat, lon) => {
    const RE = 6371.00877; // 지구 반경(km)
    const GRID = 5.0; // 격자 간격(km)
    const SLAT1 = 30.0; // 투영 위도1(degree)
    const SLAT2 = 60.0; // 투영 위도2(degree)
    const OLON = 126.0; // 기준점 경도(degree)
    const OLAT = 38.0; // 기준점 위도(degree)
    const XO = 43; // 기준점 X좌표(GRID)
    const YO = 136; // 기준점 Y좌표(GRID)
  
    const DEGRAD = Math.PI / 180.0;
    const re = RE / GRID;
    const slat1 = SLAT1 * DEGRAD;
    const slat2 = SLAT2 * DEGRAD;
    const olon = OLON * DEGRAD;
    const olat = OLAT * DEGRAD;
  
    let sn = Math.tan(Math.PI * 0.25 + slat2 * 0.5) / Math.tan(Math.PI * 0.25 + slat1 * 0.5);
    sn = Math.log(Math.cos(slat1) / Math.cos(slat2)) / Math.log(sn);
    let sf = Math.tan(Math.PI * 0.25 + slat1 * 0.5);
    sf = Math.pow(sf, sn) * Math.cos(slat1) / sn;
    let ro = Math.tan(Math.PI * 0.25 + olat * 0.5);
    ro = re * sf / Math.pow(ro, sn);
  
    let ra = Math.tan(Math.PI * 0.25 + (lat) * DEGRAD * 0.5);
    ra = re * sf / Math.pow(ra, sn);
    let theta = lon * DEGRAD - olon;
    if (theta > Math.PI) theta -= 2.0 * Math.PI;
    if (theta < -Math.PI) theta += 2.0 * Math.PI;
    theta *= sn;
  
    let nx = Math.floor(ra * Math.sin(theta) + XO + 0.5);
    let ny = Math.floor(ro - ra * Math.cos(theta) + YO + 0.5);
  
    return { nx, ny };
  }

  return {
    getWeatherClass,
    getFusData,
    getUserRegions,
    getRegionName,
    convertToGridCoord
  }
}