<template>
  <template v-if="!isReply">
    <div class="tw-comment-list-left">
      <div class="tw-comment-img">
        <img :src="computedProfileImageSrc" alt="닉네임 프로필 이미지">
      </div>
      <div class="tw-comment-txt">
        <div class="comm-txt-name">{{ tripinfoCmnt.nick }}
          <!-- <span class="id-badge">
            <img src="/tripwith/assets/images/img-level-total-1.svg" alt="뱃지 이미지">
          </span> -->
          <span class="comm-txt-my" v-if="tripinfoCmnt.regUserNum == sUserNum">Me</span>
        </div>
        <template v-if="selModTripinfoCmntNum == tripinfoCmnt.tripinfoCmntNum">
        <div class="tw-info-detail-send">
            <div class="detail-send-input">
              <input 
                  type="text" 
                  class="tw-input-default tw-input-comment" 
                  v-model="tripinfoCmnt.cntn" 
                  @keydown.enter="modTripinfoCmntDeb(tripinfoCmnt.tripinfoCmntNum)" 
                  :ref="setInputRef(tripinfoCmnt.tripinfoCmntNum)"
              >
            </div>
            <div 
                class="detail-send-icon on" 
                tabindex="0" 
                @click="() => modTripinfoCmntDeb(tripinfoCmnt.tripinfoCmntNum)"
            >
              보내기
            </div>
        </div>
        </template>
        <template v-else>
          <div class="comm-txt-body">{{ tripinfoCmnt.delYn == 'Y' ? $t('user.main.TRIPINFO.COMM.CMNT-DEL') :tripinfoCmnt.cntn }}</div>
        </template>
        <div class="comm-txt-bt">
          <!-- <div class="info-bt-like icon" tabindex="0">
            <span class="tw-like-icon">좋아요 아이콘</span>
            <span class="tw-like-txt">{{$t('user.main.TRIPINFO.COMM.LIKE')}}</span>
          </div> -->
          <div class="info-bt-comm icon" tabindex="0" @click="goTripinfoCmnt(tripinfoCmnt.tripinfoNum, tripinfoCmnt.tripinfoCmntNum)" >
            <span class="tw-comm-icon">댓글 아이콘</span>
            <span class="tw-comm-txt">{{$t('user.main.TRIPINFO.COMM.CMNT')}}</span>
          </div>
        </div>
      </div>
      <div  v-if="tripinfoCmnt.regUserNum == sUserNum  && tripinfoCmnt.delYn == 'N'" class="tw-comment-icon" tabindex="0" @click="toggleModal(tripinfoCmnt.tripinfoCmntNum, $event)" aria-haspopup="true">댓글 더보기 아이콘</div>
      <div class="tw-pop-mini" role="dialog" aria-modal="true" v-click-outside="onClickOutside" v-if="isActiveModal">
        <ul>
          <li @click="startEditMode(tripinfoCmnt.tripinfoCmntNum)">
            <a>{{$t('user.main.COMM.UPD')}}</a>
          </li>
          <li @click="confirmDelete(tripinfoCmnt)">
            <a>{{$t('user.main.COMM.DEL')}}</a>
          </li>
        </ul>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="tw-comment-img">
      <img :src="computedProfileImageSrc" alt="닉네임 프로필 이미지">
    </div>
    <div class="tw-comment-txt">
      <div class="comm-txt-name">{{ tripinfoCmnt.nick }}
        <span class="comm-txt-my" v-if="tripinfoCmnt.regUserNum && tripinfoCmnt.regUserNum == sUserNum">Me</span>
      </div>
      <template v-if="selModTripinfoCmntNum == tripinfoCmnt.tripinfoCmntNum">
      <div class="tw-info-detail-send">
          <div class="detail-send-input">
              <input type="text" class="tw-input-default tw-input-comment" v-model="tripinfoCmnt.cntn" @keydown.enter="modTripinfoCmntDeb(tripinfoCmnt.tripinfoCmntNum)" :ref="setInputRef(tripinfoCmnt.tripinfoCmntNum)">
          </div>
          <div class="detail-send-icon on" tabindex="0" @click="() => modTripinfoCmntDeb(tripinfoCmnt.tripinfoCmntNum)">보내기</div>
      </div>
      </template>
      <template v-else>
        <div class="comm-txt-body"> {{ tripinfoCmnt.delYn == 'Y' ? $t('user.main.TRIPINFO.COMM.CMNT-DEL') :tripinfoCmnt.cntn }}</div>
      </template>
      <!-- <div class="comm-txt-bt">
        <div class="info-bt-like icon" tabindex="0">
          <span class="tw-like-icon">좋아요 아이콘</span>
          <span class="tw-like-txt">{{$t('user.main.TRIPINFO.COMM.LIKE')}}</span>
        </div>
      </div> -->
    </div>
    <div  v-if="tripinfoCmnt.regUserNum == sUserNum  && tripinfoCmnt.delYn == 'N'" class="tw-comment-icon" tabindex="0" @click="toggleModal(tripinfoCmnt.tripinfoCmntNum, $event)" aria-haspopup="true">댓글 더보기 아이콘</div>

    <div class="tw-pop-mini" role="dialog" aria-modal="true" v-click-outside="onClickOutside" v-if="isActiveModal">
      <ul>
        <li @click="startEditMode(tripinfoCmnt.tripinfoCmntNum)">
            <a>{{$t('user.main.COMM.UPD')}}</a>
        </li>
        <li @click="confirmDelete(tripinfoCmnt)">
            <a>{{$t('user.main.COMM.DEL')}}</a>
        </li>
      </ul>
    </div>
  </template>
</template>
<script setup>
import { ref, computed, nextTick } from 'vue'
import { useStore } from "vuex";
import { debounce } from 'lodash';

import { useComm } from '@/page/main/composables/comm';
const { toggleCommAlert, loginChk } = useComm();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate } = useNavigation();

import { useTripinfo } from '@/page/main/composables/useTripinfo';
const { 
    getTripinfoCmntList,
    modTripinfoCmnt,
    delTripinfoCmnt
} = useTripinfo();

// Props 정의
const props = defineProps({
  tripinfoCmnt: Object,
  sUserNum: String,
  isReply: Boolean,
});

// 상태 관리
const computedProfileImageSrc = computed(() => 
    props.tripinfoCmnt.prflFileFullPath || '/tripwith/assets/images/img-profile.png'
);
const selModTripinfoCmntNum = ref(0);
const inputRefs = ref({});

const store = useStore();


// 대댓글 페이지로 이동
const goTripinfoCmnt = (pTripinfoNum, pTripinfoCmntNum) => {
    if(!loginChk()) return;
    
    store.dispatch('tripinfo/clearCmntLst');
    store.dispatch('tripinfo/setCmntData', {
        tripinfoNum: pTripinfoNum,
        upprTripinfoCmntNum: pTripinfoCmntNum
    });
    navigate({
        value: "tripinfoCmnt", 
        querys: { tripinfoNum: pTripinfoNum, tripinfoCmntNum: pTripinfoCmntNum }
    });
};

// 댓글 수정 처리
const handleModTripinfoCmnt = async (pTripinfoCmntNum) => {
    const selInputRef = inputRefs.value[pTripinfoCmntNum];
    if (!selInputRef.value) return false;

    const cmntData = {
        cntn: selInputRef.value,
        tripinfoCmntNum: props.tripinfoCmnt.tripinfoCmntNum,
        upprTripinfoCmntNum: props.tripinfoCmnt.upprTripinfoCmntNum
    };

    const result = await modTripinfoCmnt(cmntData, selInputRef.value);
    if (result) {
        selModTripinfoCmntNum.value = null;
    }
};

// 디바운스 적용된 수정 함수
const modTripinfoCmntDeb = debounce(handleModTripinfoCmnt, 200);

// 수정 모드 진입
const startEditMode = (pTripinfoCmntNum) => {
    store.dispatch('tripinfo/clearActiveCmntNum');
    selModTripinfoCmntNum.value = pTripinfoCmntNum;

    nextTick(() => {
        if (inputRefs.value[pTripinfoCmntNum]) {
            inputRefs.value[pTripinfoCmntNum].focus();
        }
    });
};

const confirmDelete = (pTripinfoCmnt) => {
    toggleCommAlert(
        "TRIPINFO-CMNT-DEL", 
        "CONFIRM", 
        "CONFIRM", 
        () => delTripinfoCmnt(pTripinfoCmnt)
    );
};
// 입력 참조 설정
const setInputRef = (tripinfoCmntNum) => (el) => {
    if (el) inputRefs.value[tripinfoCmntNum] = el;
};

// 모달 관련
const toggleModal = (tripinfoCmntNum, event) => {
    event.stopPropagation();
    store.dispatch('tripinfo/setActiveCmntNum', tripinfoCmntNum);
};

const isActiveModal = computed(() => 
    store.state.tripinfo.cmnt.activeCmntNum == props.tripinfoCmnt.tripinfoCmntNum
);

const onClickOutside = (event) => {
  store.dispatch('tripinfo/clearActiveCmntNum');
}

</script>