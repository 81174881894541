import { createRouter, createWebHistory } from 'vue-router'

import baseView from '@/page/main/views/baseView.vue';
import home from '@/page/main/views/home/main.vue';

import login from '@/page/main/views/login/login.vue';
import mypageMain from '@/page/main/views/mypage/main.vue';
import mypageMod from '@/page/main/views/mypage/mod.vue';
import mypageLang from '@/page/main/views/mypage/lang.vue';
import mypageFavs from '@/page/main/views/mypage/favs.vue';
import mypagePosts from '@/page/main/views/mypage/posts.vue';
import mypageLikes from '@/page/main/views/mypage/likes.vue';
import mypageNtc from '@/page/main/views/mypage/ntc.vue';
import mypageNtcDtl from '@/page/main/views/mypage/ntcDtl.vue';
import mypageFaq from '@/page/main/views/mypage/faq.vue';
import mypageWthd from '@/page/main/views/mypage/wthd.vue';
import mypageWthdResult from '@/page/main/views/mypage/wthdResult.vue';
import legalDocument from '@/page/main/views/mypage/legalDocument.vue';
import exchangeRatesMain from '@/page/main/views/mypage/exchangeRates/main.vue';
import exchangeRatesAdd from '@/page/main/views/mypage/exchangeRates/add.vue';

import weatherMain from '@/page/main/views/mypage/weather/main.vue';
import weatherDetail from '@/page/main/views/mypage/weather/detail.vue';
import weatherAdd from '@/page/main/views/mypage/weather/add.vue';

import resetPswrd from '@/page/main/views/mypage/resetPswrd.vue';

import naverLoginCallback from '@/page/main/views/login/smpl/naverLoginCallback.vue';
import kakaoLoginCallback from '@/page/main/views/login/smpl/kakaoLoginCallback.vue';
import googleLoginCallback from '@/page/main/views/login/smpl/googleLoginCallback.vue';
import facebookLoginCallback from '@/page/main/views/login/smpl/facebookLoginCallback.vue';

import signupAgree from '@/page/main/views/signup/agree.vue';
import signupEmail from '@/page/main/views/signup/email.vue';
import signupMain from '@/page/main/views/signup/main.vue';
import otp from '@/page/main/views/signup/otp.vue';
import signupWelcome from '@/page/main/views/signup/welcome.vue';
import signupWrite from '@/page/main/views/signup/write.vue';

import tripinfoMain from '@/page/main/views/tripinfo/main.vue';
import tripinfoDetail from '@/page/main/views/tripinfo/detail.vue';
import tripinfoCmnt from '@/page/main/views/tripinfo/cmnt.vue';
import tripinfoSrch from '@/page/main/views/tripinfo/srch.vue';
import tripinfoFilter from '@/page/main/views/tripinfo/filter.vue';

import cmmntMain from '@/page/main/views/cmmnt/main.vue';
import cmmntFilter from '@/page/main/views/cmmnt/filter.vue';
import cmmntWrite from '@/page/main/views/cmmnt/write.vue';
import cmmntSrch from '@/page/main/views/cmmnt/srch.vue';
import cmmntDtl from '@/page/main/views/cmmnt/dtl.vue';
import cmmntCmnt from '@/page/main/views/cmmnt/cmnt.vue';

import error from '@/page/main/views/error.vue';

const requireAuth = () => (to, from, next) => {
  let token = localStorage.getItem('authorization');
  if (token != null) {
    return next();
  }
  location.href = '/mypage';
};

const routes = [
  {
    path: '/',
    name: 'baseView',
    component: baseView,
    children: [
      {
        path: '/',
        alias: ['/main', '/index', '/', ''],
        name: 'home',
        component: home,
        props: true,
      },
      {
        path: '/cmmnt',
        alias: ['/cmmntMain'],
        name: 'cmmntMain',
        component: cmmntMain,
        props: true,
      },
      {
        path: '/cmmnt/filter',
        alias: ['/cmmntFilter'],
        name: 'cmmntFilter',
        component: cmmntFilter,
        props: true,
      },
      {
        path: '/cmmnt/write/:postNum?',
        name: 'cmmntWrite',
        component: cmmntWrite,
        props: true,
      },
      {
        path: '/cmmnt/srch',
        name: 'cmmntSrch',
        component: cmmntSrch,
        props: true,
      },
      {
        path: '/cmmnt/:postNum',
        name: 'cmmntDtl',
        component: cmmntDtl,
        props: true,
      },
      {
        path: '/cmmnt/cmnt/:postNum/:cmntNum',
        name: 'cmmntCmnt',
        component: cmmntCmnt,
        props: true,
      },
      {
        path: '/login',
        name: 'login',
        component: login,
        props: true,
      },
      {
        path: '/mypage/mod',
        name: 'mypageMod',
        component: mypageMod,
        props: true,
        beforeEnter: requireAuth() 
      },
      {
        path: '/mypage/favs',
        name: 'mypageFavs',
        component: mypageFavs,
        props: true,
        beforeEnter: requireAuth() 
      },
      {
        path: '/mypage/posts',
        name: 'mypagePosts',
        component: mypagePosts,
        props: true,
        beforeEnter: requireAuth() 
      },
      {
        path: '/mypage/likes',
        name: 'mypageLikes',
        component: mypageLikes,
        props: true,
        beforeEnter: requireAuth() 
      },
      {
        path: '/mypage/ntc',
        name: 'mypageNtc',
        component: mypageNtc,
        props: true,
      },
      {
        path: '/mypage/faq',
        name: 'mypageFaq',
        component: mypageFaq,
      },
      {
        path: '/mypage/ntc/:postNum',
        name: 'mypageNtcDtl',
        component: mypageNtcDtl,
        props: true,
      },
      {
        path: '/mypage/legalDocument/:boardNum',
        name: 'legalDocument',
        component: legalDocument,
        props: true,
      },
      {
        path: '/mypage/lang',
        name: 'mypageLang',
        component: mypageLang,
        props: true,
      },
      {
        path: '/mypage',
        name: 'mypageMain',
        component: mypageMain,
        props: true,
      },
      {
        path: '/mypage/wthd',
        name: 'mypageWthd',
        component: mypageWthd,
        props: true,
      },
      {
        path: '/mypage/wthd/result',
        name: 'mypageWthdResult',
        component: mypageWthdResult,
        props: true,
      },
      {
        path: '/mypage/weather',
        name: 'weatherMain',
        component: weatherMain,
      },
      {
        path: '/mypage/weather/detail/:weatherRegNum',
        name: 'weatherDetail',
        component: weatherDetail,
        props: true,
      },
      {
        path: '/mypage/exchangeRates',
        name: 'exchangeRatesMain',
        component: exchangeRatesMain,
      },
      {
        path: '/mypage/exchangeRates/add',
        name: 'exchangeRatesAdd',
        component: exchangeRatesAdd,
      },
      {
        path: '/mypage/weather/add',
        name: 'weatherAdd',
        component: weatherAdd,
      },
      {
        path: '/signup/agree',
        name: 'signupAgree',
        component: signupAgree,
      },
      {
        path: '/signup/main',
        name: 'signupMain',
        component: signupMain,
      },
      {
        path: '/signup/email',
        name: 'signupEmail',
        component: signupEmail,
        props: { otpCode: 'SIGNUP' }
      },
      {
        path: '/reset/email',
        name: 'resetEmail',
        component: signupEmail,
        props: { otpCode: 'RESET_PSWRD' }
      },
      {
        path: '/reset/pswrd',
        name: 'resetPswrd',
        component: resetPswrd,
        props: true,
      },
      {
        path: 'signup/otp',
        name: 'signupOtp',
        component: otp,
        props: { otpCode: 'SIGNUP' }
      },
      {
        path: 'reset/otp',
        name: 'resetOtp',
        component: otp,
        props: { otpCode: 'RESET_PSWRD' }
      },
      {
        path: '/signup/write',
        name: 'signupWrite',
        component: signupWrite,
      },
      {
        path: '/signup/welcome',
        name: 'signupWelcome',
        component: signupWelcome,
      },
      {
        path: '/login/naver',
        name: 'loginNaver',
        component: naverLoginCallback,
        props: true,
      },
      {
        path: '/login/kakao',
        name: 'loginKakao',
        component: kakaoLoginCallback,
        props: true,
      },
      {
        path: '/login/google',
        name: 'loginGoogle',
        component: googleLoginCallback,
        props: true,
      },
      {
        path: '/login/facebook',
        name: 'loginFacebook',
        component: facebookLoginCallback,
        props: true,
      },
      {
        path: '/tripinfo/main',
        name: 'tripinfo',
        component: tripinfoMain,
        props: true,
      },
      {
        path: '/tripinfo/:tripinfoNum',
        name: 'tripinfoDetail',
        component: tripinfoDetail,
        props: true,
      },
      {
        path: '/tripinfo/cmnt',
        name: 'tripinfoCmnt',
        component: tripinfoCmnt,
        props: true,
      },
      {
        path: '/tripinfo/srch',
        name: 'tripinfoSrch',
        component: tripinfoSrch,
        props: true,
      },
      {
        path: '/tripinfo/filter',
        name: 'tripinfoFilter',
        component: tripinfoFilter,
        props: true,
      },
      {
        path: '/error',
        name: 'error',
        component: error,
      },
    ]
  },
  // 존재하지 않는 모든 경로 처리
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //base : process.env.BASE_URL,
  routes
})

export default router
