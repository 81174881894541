<template>
  <li v-for="(tripinfo, index) in tripinfoLst" :key="index" @click="goTripinfoDetail(tripinfo.tripinfoNum)">
    <div class="tw-info-box">
      <div class="tw-info-category">{{t('user.main.TRIPINFO.TI-CTGRY.' + tripinfo.tripinfoCtgryCodeRef_1) }} </div>
      <div :class="['tw-info-bookmark-icon', { on: tripinfo.dibsedByUser }]"  tabindex="0">북마크 아이콘</div>
      <div class="tw-info-box-img">
          <img v-if="tripinfo.thmbTripinfoFileNum"
              :src="tripinfo.thmbTripinfoFullPath"
              alt="트립인포 컨텐츠 이미지">

    <!-- 배열이 비어있거나 존재하지 않을 경우 대체 텍스트 표시 -->
            <img v-else src="/tripwith/assets/images/img-sample(1).png" alt="트립인포 컨텐츠 이미지">
      </div>
      <div class="tw-info-box-txt">
        <div class="info-txt-top"> {{ tripinfo.title }} </div>
        <div class="info-txt-sub">{{ stripHtml(tripinfo.cntn) }}</div>
        <div class="info-txt-bt">
          <div :class="['info-bt-like icon', { on: tripinfo.likedByUser }]" tabindex="0">
            <span class="tw-like-icon">좋아요 아이콘</span>
            <span class="tw-like-txt"> {{ tripinfo.likeCnt > 0 ? tripinfo.likeCnt : $t('user.main.TRIPINFO.COMM.LIKE')}} </span>
          </div>
          <div class="info-bt-comm icon" :class="tripinfo.cmntCnt > 0 ? 'on' : ''" tabindex="0">
            <span class="tw-comm-icon">댓글 아이콘</span>
            <span class="tw-comm-txt" > {{ tripinfo.cmntCnt > 0 ? tripinfo.cmntCnt : $t('user.main.TRIPINFO.COMM.CMNT')}} </span>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>


<script setup>
import { onMounted } from 'vue'
import { useNavigation } from '@/page/main/composables/useNavigation';
import { useI18n } from 'vue-i18n';

const { navigate } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { stripHtml } = useComm();

//부모 컴포넌트에서 받은 값
const props = defineProps({
  tripinfoLst: Array,
})

const goTripinfoDetail = (pTripinfoNum) => {
    navigate({value:"tripinfoDetail", params: {tripinfoNum:pTripinfoNum} } );
}

onMounted(() => {
})


const messages = loadLocaleMessages()

function loadLocaleMessages() {
  // json 파일을 읽어들이기 위한 function
  const requireLang = require.context('@/lang', true, /\.json$/);
  const messages = {};
  // json file read
  for (const file of requireLang.keys()) {
    if (file === './index.js') continue; // index.js 파일 제외
    const path = file.replace(/(\.\/|\.json$)/g, '').split('/');
    path.reduce((o, s, i) => {
      if (o[s]) return o[s];
      o[s] = i + 1 === path.length ? requireLang(file) : {};
      return o[s];
    }, messages);
  }
  return messages;
}

//메시지
//$t로 공통 언어에 접근하고 그냥 t로 각 페이지별 언어에 접근한다. 

const { t } = useI18n({messages});

</script>