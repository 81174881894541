<template>

        <header class="tw-top search">
          <a class="tw-top-icon" @click="goBack()">뒤로가기 아이콘</a>
          <div class="tw-top-search">
            <div class="tw-search-wrap" role="search">
              <input type="search" class="tw-input-default tw-input-search" 
                      :placeholder="$t('user.main.TRIPINFO.COMM.SRCH-KEYWORD-PH')" 
                      aria-label="가이드 통합검색" v-model="sSrch.keyword"  @keydown.enter="srchTripinfoLst()">
              <span class="tw-search-icon" tabindex="0" @click="srchTripinfoLst()">검색 아이콘</span>
            </div>
          </div>
        </header>
        <div class="tw-body p-0">
          <filter-swiper />
          <div class="tw-align-box">
            <label class="tw-label-body"> {{$t('user.main.COMM.COUNT.TOTAL')}} <span>{{ tripinfo.totalCnt }}</span> {{ tripinfo.totalCnt > 1 ? $t('user.main.COMM.COUNT.ITEMS') : $t('user.main.COMM.COUNT.ITEM')  }}</label>
                   <!-- 총<span>{{ tripinfo.totalCnt }}</span>건</label> -->
            <div class="tw-select-wrap small none" ref="selOrder" @click="clickOrderLst" >
              <button type="button" class="tw-select-default" aria-label="정렬 선택 버튼">{{ $t('user.main.COMM.COMM-ORDER.' + sSrch.orderCode) }} </button>
              <ul class="optionList">
                <li class="optionItem" tabindex="0" @click="clickOrder('001')" >{{ $t('user.main.COMM.COMM-ORDER.001') }}</li>
                <li class="optionItem" tabindex="0" @click="clickOrder('002')">{{ $t('user.main.COMM.COMM-ORDER.002') }}</li>
                <li class="optionItem" tabindex="0" @click="clickOrder('003')">{{ $t('user.main.COMM.COMM-ORDER.003') }}</li>
              </ul>
            </div>
          </div>
          <div class="tw-info-tab-list">
            <ul class="clearfix">
              <tripinfo-card :tripinfoLst="tripinfoLst" ></tripinfo-card>
            </ul>
          </div>
        </div>
        <modal></modal>
</template>


<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance, reactive,computed, watch } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
import { useI18n } from 'vue-i18n';

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();


import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

//components
import tripinfoCard from '@/page/main/views/tripinfo/comm/cardLst.vue'

import modal from "@/page/main/comm/modal.vue";
import filterSwiper from "@/page/main/views/tripinfo/comm/filterSwiper.vue"

const tripinfo = reactive({});
const tripinfoLst = ref([]);
const sSrch = computed(() => store.state.tripinfo.srch);

tripinfo.pageNum = 0;
tripinfo.pageSize = 10;
tripinfo.tripinfoOrderCtgryCode = sSrch.value.orderCode;
tripinfo.tripinfoCtgryCode = sSrch.value.tripinfoCtgryCode;

const selOrder = ref(null);

const clickOrderLst = () => {
  selOrder.value.classList;
  if(selOrder.value.classList.contains('active')){
    selOrder.value.classList.remove('active');
  }else{
    selOrder.value.classList.add('active');
  }
};

const clickOrder = async (pOrder) => {
  const setSrch = {
    stateName : 'srch',
    key : 'orderCode',
    value : pOrder,
  }
  store.dispatch('tripinfo/setTripinfoFromModal', setSrch);
  tripinfo.tripinfoOrderCtgryCode = sSrch.value.orderCode;
  srchTripinfoLst();

}

const goGetTripinfoLst = async (tripinfoParam) => {
  let tmpTripinfoLst;
    await $axios({
        method: 'POST',
        data: tripinfoParam,
        url: '/tripinfo/lstpage',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpTripinfoLst = resultObj.content;
            tripinfo.totalCnt = resultObj.totalElements;
        }
    }).catch((error) => {
        alert("에러 발생");
    })
    return tmpTripinfoLst;
};

const srchTripinfoLst = async () => {
  tripinfo.tripinfoOrderCtgryCode = sSrch.value.orderCode;
  tripinfo.tripinfoCtgryCode = sSrch.value.tripinfoCtgryCode;
  tripinfo.keyword = sSrch.value.keyword;
  tripinfoLst.value = await goGetTripinfoLst(tripinfo);
}

//언어 설정 부분
const { locale } = useI18n();

onMounted( async () => {
  tripinfo.lang = locale.value;
  srchTripinfoLst();
})

watch(
  () => sSrch.value.tripinfoCtgryCode,
  (newCtgryCode, oldCtgryCode) => {
    srchTripinfoLst();
  }
)

</script>