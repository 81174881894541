<template>
      <header class="tw-top">
        <a href="#" class="tw-top-icon" @click="goBack()" >뒤로가기 아이콘</a>
        <h1 class="tw-label-top">커뮤니티</h1>
      </header>
      <div class="tw-body p-0">
        <div class="tw-info-detail">
          <div class="tw-info-detail-commentbox">
            <ul class="tw-comment-list-wrap">
              <!-- 댓글 열린상태 예시 -->
                <cmmnt-comm-cmnt></cmmnt-comm-cmnt>
            </ul>
          </div>
          <send-input :cmmntCmnt="cmmntCmnt" @submit="regPostCmnt"></send-input>
        </div>
      </div>
</template>


<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance,defineProps } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { createLogger, useStore } from "vuex";

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const $axiosJson = proxy.$axiosJson;
const router = useRouter()
const route = useRoute()
const store = useStore();


//components
import cmmntCommCmnt from '@/page/main/views/cmmnt/comm/cmntLst.vue'
import sendInput from '@/page/main/views/cmmnt/comm/sendInput.vue'

import { debounce, throttle } from 'lodash'

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert, loginChk } = useComm();

const bCmmntDtlShow = ref(false)

const props = defineProps({
  postNum: {
    type: String,
    required: true
  },
  cmntNum: {
    type: String,
    required: true
  },
});

const selPostNum = props.postNum;
const selCmntNum = props.cmntNum;

const cmmntCmnt = ref({postNum : selPostNum, upprCmntNum : selCmntNum, cntn:''});
const cmmntCmntLst = ref([]);

const goGetCmntLst = async (pCmmntCmnt) => {
  let tmpCmmntCmntLst;
    await $axios({
        method: 'GET',
        params: pCmmntCmnt,
        url: '/tripwith/cmnt/user',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpCmmntCmntLst = resultObj;
        }
    }).catch((error) => {
        alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
    })
    return tmpCmmntCmntLst;
};

const regPostCmnt = async (e) => {
    if(!loginChk()){
      //로그인하지 않은 경우 함수의 실행 중단
      return;
    };

    await $axiosJson({
        method: 'POST',
        data: e,
        url: '/tripwith/cmnt/user',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (data.resultYn == 'Y') {

          //store.dispatch('cmmnt/pushCmnt', resultObj);

          cmmntCmnt.value.cntn = '';
        }
    }).catch((error) => {
        alert("댓글 등록 중 에러가 발생하였습니다. ");
    })

    cmmntCmntLst.value = await goGetCmntLst(cmmntCmnt.value);
    store.dispatch('cmmnt/setCmntLst', cmmntCmntLst.value);
}

onMounted( async () => {
  cmmntCmntLst.value = await goGetCmntLst(cmmntCmnt.value);
  //store에 연동
  store.dispatch('cmmnt/setCmntLst', cmmntCmntLst.value);
})


</script>