<template>
  <template v-if="!isReply">
    <div class="tw-comment-list-left">
      <div class="tw-comment-img">
        <img :src="computedProfileImageSrc" alt="닉네임 프로필 이미지">
      </div>
      <div class="tw-comment-txt">
        <div class="comm-txt-name">{{ cmmntCmnt.nick }}
          <!-- <span class="id-badge">
            <img src="/tripwith/assets/images/img-level-total-1.svg" alt="뱃지 이미지">
          </span> -->
          <span class="comm-txt-my" v-if="cmmntCmnt.regUserNum == sUserNum">Me</span>
        </div>
        <template v-if="selModCmmntCmntNum == cmmntCmnt.cmntNum">
        <div class="tw-info-detail-send">
            <div class="detail-send-input">
                <input type="text" class="tw-input-default tw-input-comment" v-model="cmmntCmnt.cntn" @keydown.enter="regCmmntCmntDeb(cmmntCmnt.cmntNum)" :ref="setInputRef(cmmntCmnt.cmntNum)">
            </div>
            <div class="detail-send-icon on" tabindex="0" @click="() => regCmmntCmntDeb(cmmntCmnt.cmntNum)">보내기</div>
        </div>
        </template>
        <template v-else>
          <div class="comm-txt-body">{{ cmmntCmnt.delYn == 'Y' ? $t('user.main.TRIPINFO.COMM.CMNT-DEL') :cmmntCmnt.cntn }}</div>
        </template>
        <div class="comm-txt-bt">
          <!-- <div class="info-bt-like icon" tabindex="0">
            <span class="tw-like-icon">좋아요 아이콘</span>
            <span class="tw-like-txt">{{$t('user.main.CMMNT.MAIN.LIKE')}}</span>
          </div> -->
          <div class="info-bt-comm icon" tabindex="0" @click="goCmmntCmnt(cmmntCmnt.postNum, cmmntCmnt.cmntNum)" >
            <span class="tw-comm-icon">댓글 아이콘</span>
            <span class="tw-comm-txt">{{$t('user.main.CMMNT.MAIN.CMNT')}}</span>
          </div>
        </div>
      </div>
      <div  v-if="cmmntCmnt.regUserNum == sUserNum  && cmmntCmnt.delYn == 'N'" class="tw-comment-icon" tabindex="0" @click="toggleModal(cmmntCmnt.cmntNum, $event)" aria-haspopup="true">댓글 더보기 아이콘</div>

      <div class="tw-pop-mini" role="dialog" aria-modal="true" v-click-outside="onClickOutside" v-if="isActiveModal">
        <ul>
          <li @click="modCmnt(cmmntCmnt.cmntNum)"><a>{{$t('user.main.COMM.UPD')}}</a></li>
          <li @click="delCmmntCmntConfirm(cmmntCmnt)" tabindex="0"><a>{{$t('user.main.COMM.DEL')}}</a></li>
        </ul>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="tw-comment-img">
      <img :src="computedProfileImageSrc" alt="닉네임 프로필 이미지">
    </div>
    <div class="tw-comment-txt">
      <div class="comm-txt-name">{{ cmmntCmnt.nick }}
        <span class="comm-txt-my" v-if="cmmntCmnt.regUserNum == sUserNum">Me</span>
      </div>
      <template v-if="selModCmmntCmntNum == cmmntCmnt.cmntNum">
      <div class="tw-info-detail-send">
          <div class="detail-send-input">
              <input type="text" class="tw-input-default tw-input-comment" v-model="cmmntCmnt.cntn" @keydown.enter="regCmmntCmntDeb(cmmntCmnt.cmntNum)" :ref="setInputRef(cmmntCmnt.cmntNum)">
          </div>
          <div class="detail-send-icon on" tabindex="0" @click="() => regCmmntCmntDeb(cmmntCmnt.cmntNum)">보내기</div>
      </div>
      </template>
      <template v-else>
        <div class="comm-txt-body">{{ cmmntCmnt.delYn == 'Y' ? $t('user.main.TRIPINFO.COMM.CMNT-DEL') :cmmntCmnt.cntn }}</div>
      </template>
      <!-- <div class="comm-txt-bt">
        <div class="info-bt-like icon" tabindex="0">
          <span class="tw-like-icon">좋아요 아이콘</span>
          <span class="tw-like-txt">{{$t('user.main.CMMNT.MAIN.LIKE')}}</span>
        </div>
      </div> -->
    </div>
    <div  v-if="cmmntCmnt.regUserNum == sUserNum  && cmmntCmnt.delYn == 'N'" class="tw-comment-icon" tabindex="0" @click="toggleModal(cmmntCmnt.cmntNum, $event)" aria-haspopup="true">댓글 더보기 아이콘</div>

    <div class="tw-pop-mini" role="dialog" aria-modal="true" v-click-outside="onClickOutside" v-if="isActiveModal">
      <ul>
        <li @click="modCmnt(cmmntCmnt.cmntNum)"><a>{{$t('user.main.COMM.UPD')}}</a></li>
        <li @click="delCmmntCmntConfirm(cmmntCmnt)" tabindex="0"><a>{{$t('user.main.COMM.DEL')}}</a></li>
      </ul>
    </div>
  </template>
</template>
<script setup>
import { ref, onMounted, onBeforeMount, onBeforeUnmount, computed, nextTick } from 'vue'
import { createLogger, useStore } from "vuex";

//글로벌 프로퍼티 설정 부분
import { useGlobalProperties } from '@/page/main/composables/useGlobalProperties'; 
const { $axios, $axiosJson } = useGlobalProperties();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommAlert, loginChk } = useComm();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

// Props 정의
const props = defineProps({
  cmmntCmnt: Object,
  sUserNum: String,
  isReply: Boolean,
});

const computedProfileImageSrc = computed(() => props.cmmntCmnt.prflFileFullPath || '/tripwith/assets/images/img-profile.png');
const selModCmmntCmntNum = ref(0);

const inputRefs = ref({});

const store = useStore();

const goCmmntCmnt = (pPostNum, pCmntNum) => {
    if(!loginChk()){
      return;
    }

    store.dispatch('cmmnt/clearCmntLst', null);
    //여기서 리스트를 초기화하여 cmnt 접근 시 리스트가 이상하게 보이는 부분을 보완하도록 해야 함. 
    navigate({value:"cmmntCmnt", params: {postNum:pPostNum, cmntNum:pCmntNum} } );
}

const regCmmntCmnt = async (pCmntNum) => {

    const selInputRef = inputRefs.value[pCmntNum];
    if(selInputRef.value == ''){
      return false;
    }

    const cmntData = {
      cntn : selInputRef.value,
      cmntNum : selModCmmntCmntNum.value
    }

    await $axiosJson({
        method: 'POST',
        data: cmntData,
        url: '/tripwith/cmnt/user',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (data.resultYn == 'Y') {
          selModCmmntCmntNum.value = null;
        }
    }).catch((error) => {
        alert("댓글 등록 중 에러가 발생하였습니다. ");
    })
}

const regCmmntCmntDeb = _.throttle((pCmmntCmntNum) => {
    regCmmntCmnt(pCmmntCmntNum);
}, 3000);

const modCmnt = (pCmntNum) => {
  store.dispatch('cmmnt/clearActiveCmntNum');
  selModCmmntCmntNum.value = pCmntNum;

  nextTick(() => {
    if (inputRefs.value[pCmntNum]) {
      inputRefs.value[pCmntNum].focus();
    }
  });
}

const delCmmntCmntConfirm = (pCmmntCmnt) => {
  const onConfirm = () => {
    delCmmntCmnt(pCmmntCmnt); // 클로저를 통해 pCmmntCmntNum 접근
  };

  //confirm open
  toggleCommAlert("TRIPINFO-CMNT-DEL", "CONFIRM", "CONFIRM", onConfirm);
}

const delCmmntCmnt = async (pCmmntCmnt) => {
    const cmmntCmntView = computed(() => store.getters['cmmnt/selCmnt'](pCmmntCmnt));

    const cmntData = {
      postNum : cmmntCmntView.value.postNum,
      cmntNum : cmmntCmntView.value.cmntNum,
    }

    // if(cmmntCmntView.value.upprCmntNum){
    //   cmntData.upprCmntModel = {cmntNum : cmmntCmntView.value.upprCmntNum};
    // }

    await $axios({
        method: 'DELETE',
        url: '/tripwith/cmnt/user/' + cmntData.postNum + '/' + cmntData.cmntNum,
    }).then((data) => {
        const resultObj = data.resultObj;
        if (data.resultYn == 'Y') {
          store.dispatch('cmmnt/delCmnt', resultObj);
          toggleCommAlert("TRIPINFO-CMNT-DEL", "SUCCESS", "ALERT");
        }else{
          toggleCommAlert("TRIPINFO-CMNT-DEL", "FAIL", "ALERT");
        }
    }).catch((error) => {
    toggleCommAlert("TRIPINFO-CMNT-DEL", "FAIL", "ALERT");
    })

    const cmmntCmntLst = await goGetCmntLst(props.cmmntCmnt);
    store.dispatch('cmmnt/setCmntLst', cmmntCmntLst);
}

function setInputRef(cmmntCmntNum) {
  return (el) => {
    if (el) {
      inputRefs.value[cmmntCmntNum] = el;
    }
  };
}

// 댓글
const goGetCmntLst = async (pCmmntCmnt) => {
  let tmpCmmntCmntLst;
    await $axios({
        method: 'GET',
        params: pCmmntCmnt,
        url: '/tripwith/cmnt/user',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpCmmntCmntLst = resultObj;
        }
    }).catch((error) => {
        alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
    })
    return tmpCmmntCmntLst;
};

const toggleModal = (pCmntNum, event) => {
    // 이벤트 전파 방지
    event.stopPropagation();
    store.dispatch('cmmnt/setActiveCmntNum', pCmntNum);
}

const isActiveModal = computed(() => {
  return store.state.cmmnt.cmnt.activeCmntNum == props.cmmntCmnt.cmntNum;
});

// 외부 클릭 이벤트를 처리하는 함수
const onClickOutside = (event) => {
  store.dispatch('cmmnt/clearActiveCmntNum');
}

</script>