<template>
</template>

<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance } from 'vue'
import { useRouter,useRoute } from 'vue-router';
import { useStore } from "vuex";
import cnst from '@/util/authConstants.js'

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const route = useRoute()
const store = useStore();


function goRouterPush(data, type, query) {
    if(type == 'path'){
        router.push({
            path: data,
        }).then((() =>window.scrollTo(0,0) ));
    }else if(type == 'name'){
        router.push({
            name: data,
        }).then((() =>window.scrollTo(0,0) ));
    }
}


onMounted(async () => {
    let userInfo = new Object;
    userInfo.authorizationCode = route.query.code,
    userInfo.smplLoginCode = cnst.SMPL.FACEBOOK.SMPL_LOGIN_CODE;

    //해당 엑세스토큰으로 서버단에서 정보 요청을 하는 방식으로 진행할 예정.
    await $axios({
        method: 'POST',
        data: userInfo,
        url: '/user/smplLogin',
        }).then((data) => {
            var resultObj =  data.resultObj;
            if( resultObj != null){
                localStorage.setItem('authorization', resultObj.token);
                store.dispatch('cust/setCust', userInfo.smplLoginCode);
                goRouterPush('main', 'name', '');
            }
        }).catch(error => {
            alert("로그인에 실패하였습니다. ");
            goRouterPush('login', 'name', '');
        });
})

</script>