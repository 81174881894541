<template>

      <header class="tw-top">
        <a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
        <h1 class="tw-label-top">FAQ</h1>
      </header>
      <div class="tw-body p-0">
        <div class="tw-setting-box faq">
          <h2 class="tw-label-default bold">{{$t('user.main.MYPAGE.FAQ.TITLE')}} </h2>
          <div class="tw-accordion-box">
            <ul class="list" >
              <li tabindex="0"  v-for="(cmmnt, index) in cmmntLst" :key="index">
                <p class="title" @click="setCurrentFaq(cmmnt.postNum)">{{ cmmnt.title }}</p>
                  <div class="con" :class="isCurrentFaq === cmmnt.postNum ? 'on' : ''">{{ stripHtml(cmmnt.cntn) }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { createLogger, useStore } from "vuex";
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();

//글로벌 프로퍼티 설정 부분
import { useGlobalProperties } from '@/page/main/composables/useGlobalProperties'; 
const { $axios, $axiosJson } = useGlobalProperties();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { stripHtml } = useComm();

const store = useStore();

const isCurrentFaq = ref(0);


const cmmnt = ref({});
const cmmntLst = ref([]);

const goGetLstCmmnt = async (pCmmnt) => {
  let cmmntLst;
    await $axios({
        method: 'GET',
        params: pCmmnt,
        url: '/tripwith/post/user',
    }).then((data) => {
        const resultObj = data.resultObj.content;
        if (resultObj) {
            cmmntLst = resultObj;
            cmmnt.value.totalCnt = data.resultObj.totalElements;
        }
    }).catch((error) => {
        alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
    })
    return cmmntLst;
};

const setCurrentFaq = (pPostNum) => {
  if(isCurrentFaq.value === pPostNum) {
    isCurrentFaq.value = 0;
    return false;
  }

  isCurrentFaq.value = pPostNum;
}

onMounted( async () => {
  cmmnt.value.pageNum = 0;
  cmmnt.value.pageSize = 999;
  cmmnt.value.lang = locale.value;
  cmmnt.value.boardNum = process.env.VUE_APP_BOARD_FAQ;
  cmmntLst.value = await goGetLstCmmnt(cmmnt.value);
})

</script>
<style scoped>

.con {
  transition: all 0.1s;
  overflow: hidden;
  max-height: 0;
  padding-left: 16px;
  padding-right: 16px;
}

.con.on{
  max-height: 999px; /* 충분히 큰 값으로 설정 */
  padding: 16px;
}
</style>