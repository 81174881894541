<template>

        <header class="tw-top">
          <a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
          <h1 class="tw-label-top">{{ $t('user.main.SIGNUP.TITLE')}}</h1>
        </header>

        <div class="tw-body p-24-16">

            <div class="tw-body-input-wrap bt-p">
              <label class="tw-label-default essential bt-6">{{ $t('user.main.SIGNUP.WRITE.GNDR')}}</label>
              <div class="tw-select-wrap">
                <button ref="gndrBtn" type="button" class="tw-select-default" @click="openGndrModal('gndr', sTmpUser.gndr)" > {{ $t('user.main.MYPAGE.MP-GNDR.' + sTmpUser.gndr) }} </button>
              </div>
              <label v-show="bGndrErrorShow" class="tw-label-body">{{ $t('user.main.SIGNUP.WRITE.GNDR-REQUIRED')}} </label>
            </div>
            <!-- <div class="tw-body-input-wrap bt-p">
              <label class="tw-label-default essential bt-6">국적</label>
              <div class="tw-select-wrap">
                <button type="button" class="tw-select-default" aria-label="국적 선택 버튼">한국</button>
              </div>
            </div>
            <div class="tw-007-col3">
              <div class="tw-body-input-wrap bt-p">
                <label class="tw-label-default essential bt-6">생년월일</label>
                <div class="tw-select-wrap">
                  <button type="button" class="tw-select-default" aria-label="생년월일 년도 선택 버튼">YYYY</button>
                </div>
              </div>
              <div class="tw-body-input-wrap bt-p">
                <div class="tw-select-wrap">
                  <button type="button" class="tw-select-default" aria-label="생년월일 월 선택 버튼">MM</button>
                </div>
              </div>
              <div class="tw-body-input-wrap bt-p">
                <div class="tw-select-wrap">
                  <button type="button" class="tw-select-default" aria-label="생년월일 일 선택 버튼">DD</button>
                </div>
              </div>
            </div> -->
            <div class="tw-body-input-wrap bt-p" :class="{'error' : !isNickErrorShow && bNickTouched} ">
              <label class="tw-label-default essential bt-6">{{ $t('user.main.SIGNUP.WRITE.NICK')}}</label>
              <div class="tw-input-wrap">
                <input type="text" class="tw-input-default" 
                  :placeholder="$t('user.main.SIGNUP.WRITE.NICK-PH')" 
                  ref="nickInput" 
                  :aria-label="$t('user.main.SIGNUP.WRITE.GNDR')" 
                  v-model="sTmpUser.nick" 
                  maxlength="18"
                  @blur="validateNick"
                >
                <span class="tw-input-icon" @click="delNick">input아이콘</span>
              </div>
              <label v-show="!isNickErrorShow && bNickTouched" class="tw-label-body error">
                {{ $t('user.main.SIGNUP.WRITE.NICK-ERROR')}}
              </label>
            </div>


            <!-- 비밀번호 입력 부분 -->
            <div class="tw-body-input-wrap bt-p" :class="{ 'error': !isPswrdValid && bPswrdTouched }">
              <label class="tw-label-default essential bt-6">{{ $t('user.main.SIGNUP.WRITE.PSWRD')}}</label>
              <div class="tw-pw-wrap">
                <input 
                  :type="bShowPswrd ? 'text' : 'password'" 
                  class="tw-input-default" 
                  :class="{ 'error': !isPswrdValid && bPswrdTouched }"
                  autocomplete="new-pswrd" 
                  :placeholder="$t('user.main.SIGNUP.WRITE.PSWRD-PH')" 
                  :aria-label="$t('user.main.SIGNUP.WRITE.PSWRD-PH')"
                  v-model="pswrd"
                  @blur="validatePswrd"
                >
                <span class="tw-pw-icon" tabindex="0" @click="togglePswrdVisibility('pswrd')">
                  {{ bShowPswrd ? '숨기기' : '보이기' }}
                </span>
              </div>
              <label class="tw-label-body f12" v-show="!isPswrdValid && bPswrdTouched">{{ $t('user.main.SIGNUP.WRITE.PSWRD-ERROR')}}</label>
            </div>

            <!-- 비밀번호 확인 부분 -->
            <div class="tw-body-input-wrap" :class="{ 'error': !isPswrdConfirmValid && bPswrdConfirmTouched }">
              <label class="tw-label-default essential bt-6">{{ $t('user.main.SIGNUP.WRITE.PSWRD-CONFIRM')}}</label>
              <div class="tw-pw-wrap">
                <input 
                  :type="bShowPswrdConfirm ? 'text' : 'password'" 
                  class="tw-input-default" 
                  :class="{ 'error': !isPswrdConfirmValid && bPswrdConfirmTouched }"
                  autocomplete="new-pswrd" 
                  :placeholder="$t('user.main.SIGNUP.WRITE.PSWRD-CONFIRM-PH')" 
                  :aria-label="$t('user.main.SIGNUP.WRITE.PSWRD-CONFIRM-PH')"
                  v-model="pswrdConfirm"
                  @blur="validatePswrdConfirm"
                >
                <span class="tw-pw-icon" tabindex="0" @click="togglePswrdVisibility('pswrdConfirm')">
                  {{ bShowPswrdConfirm ? '숨기기' : '보이기' }}
                </span>
              </div>
              <label class="tw-label-body f12" v-show="!isPswrdConfirmValid && bPswrdConfirmTouched">{{ $t('user.main.SIGNUP.WRITE.PSWRD-CONFIRM-ERROR')}}</label>
            </div>
          <div class="tw-body-btn-wrap bottom">
            <button type="submit" class="tw-btn-default primary" :aria-label="$t('user.main.SIGNUP.WRITE.CHK')" :disabled="!isFormValid" @click="goRegUser">{{ $t('user.main.SIGNUP.WRITE.CHK')}}</button>
          </div>
        </div>
        <modal></modal>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

import modal from "@/page/main/comm/modal.vue";

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert } = useComm();

//유저 정보 처리 시작
import cnst from '@/util/authConstants';

const user = ref({});
const sOtp = computed(() => store.state.user.otp);

const frontUrl = process.env.VUE_APP_FRONT_URL;
const serverUrl = computed(() => store.getters.getServerUrl);

const bLoginChk = computed(() => store.state.user.user.bLoginChk);

const sTmpUser = computed(() => store.state.user.tmpUser);

import { useI18n } from 'vue-i18n';
const { locale } = useI18n();

const openGndrModal = (pVal) => {
  const commLayer = {
        storeName : "user",
        stateName : "tmpUser",
        funcName : "setUserFromModal",
        valueName : pVal,
        langPath : 'user.main.MYPAGE.MP-GNDR.',
        show: true,
        lst : ['001', '002']
  }
  toggleCommLayer(commLayer);
}


//필수 체크 관련
const gndrBtn = ref(null);
const nickInput = ref(null);

const bGndrErrorShow = ref(false);
const isNickErrorShow = computed(() => {
  return bValidateNick(sTmpUser.value.nick);
});

const bNickTouched = ref(false);

const validateNick = () => {
  bNickTouched.value = true
}

onMounted(() => {
  checkOtpValidity();
});

// otp 객체 유효성 검사 함수
const checkOtpValidity = () => {
  if (!sOtp.value || !sOtp.value.otpNum || !sOtp.value.id) {
    // otp 객체가 없거나 otpNum이 비어있는 경우
    console.log("Invalid OTP access detected. Redirecting to login page.");
    checkOtpValidityAlert();
  }
}

const checkOtpValidityAlert = () => {
  const onConfirm = () => {
    navigate({ value: 'mypageMain' }); // 로그인 페이지로 리다이렉트
    store.dispatch('alert/setAlert', {
        name: 'comm',
        show: false
    });
  };

  //confirm open
  toggleCommAlert("OTP", "CNTN", "ALERT", onConfirm);
}

//닉네임 정규식
function bValidateNick(nick) {
    // 닉네임 정규 표현식: 세 글자 이상, 특수문자는 !@#$%^&*()-_+=[]{}:<>,./만 허용
    //const regex = /^[a-zA-Z0-9!@#$%^&*()\-_+=\[\]{}:<>,.\/]{3,}$/;

    // 한글 추가
    const regex = /^[가-힣a-zA-Z0-9!@#$%^&*()\-_+=\[\]{}:<>,.\/]{3,}$/;
    
    return regex.test(nick);
}

const pswrd = ref('')
const pswrdConfirm = ref('')

const bPswrdTouched = ref(false)
const bPswrdConfirmTouched = ref(false)

const isPswrdValid = computed(() => {
  const pswrdRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/
  return pswrdRegex.test(pswrd.value)
})

const isPswrdConfirmValid = computed(() => {
  return pswrd.value === pswrdConfirm.value
})

const delNick = () => {
  nickInput.value.value = '';
}

const validatePswrd = () => {
  bPswrdTouched.value = true
}

const validatePswrdConfirm = () => {
  bPswrdConfirmTouched.value = true
}

const bShowPswrd = ref(false)
const bShowPswrdConfirm = ref(false)

const togglePswrdVisibility = (field) => {
  if (field === 'pswrd') {
    bShowPswrd.value = !bShowPswrd.value
  } else if (field === 'pswrdConfirm') {
    bShowPswrdConfirm.value = !bShowPswrdConfirm.value
  }
}

const isGndrValid = computed(() => {
  if(sTmpUser.value.gndr == '000' || sTmpUser.value.gndr == null ) return false;
  else return true

})

const isFormValid = computed(() => {
  return isPswrdValid.value && isPswrdConfirmValid.value && isGndrValid.value && isNickErrorShow.value
})

const goRegUser = async () => {
  const userData = {
    id : sOtp.value.id,
    otpNum : sOtp.value.otpNum,
    nick : sTmpUser.value.nick,
    gndr : sTmpUser.value.gndr,
    pswrd : pswrd.value,
    locale : locale.value,
    joinTypeCode : 'EMAIL'
  }

  await $axios({
      method: 'POST',
      data: userData,
      url: '/user',
  }).then((data) => {
      const resultYn = data.resultYn;
      if (resultYn == 'Y') {
        navigate({ value: 'signupWelcome' });
      }else{
        const ERROR_CODE = data.resultCode;
        switch(ERROR_CODE){
          case "O001":
          case "M004":
            const goMypageMain = () => {
              navigate({ value: 'mypageMain' }); // 로그인 페이지로 리다이렉트
            }
            errorHandler(ERROR_CODE, goMypageMain);

            break;
          default:
            toggleCommAlert("COMM", "FAILED", "ALERT");
            break;
        }
      }
  }).catch((error) => {
      toggleCommAlert("COMM", "FAILED", "ALERT");
  })
}

const errorHandler = (pErrorCode, pOnConfirm) => {
  //confirm open
  toggleCommAlert("ERROR", pErrorCode, "ALERT", pOnConfirm);
}

</script>