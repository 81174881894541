import axiosWrpr from '@/util/axiosHelper';

export const loginCheckFromJwt = async () => {
    try {
      const response = await axiosWrpr({
        method: 'GET',
        url: '/token/init',
      });
      return response.resultObj || null;
    } catch (error) {
      console.error('JWT 토큰 확인 중 오류 발생:', error);
      throw error;
    }
  };