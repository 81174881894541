<template>
  <ul class="main-tabs">
    <li 
      v-for="tab in tabs" 
      :key="tab.id"
      :class="['tab-link', { current: currentTab === tab.id }]"
      :data-tab="tab.id"
      tabindex="0"
      @click="setCurrentTab(tab.id)"
    >
      {{ $t('user.main.CMMNT.MAIN-BEST.TAB.' + tab.code) }}
    </li>
  </ul>

  <template v-for="(tab, index) in tabs" :key="index">
    <top-3-lst :tab="tab" />
  </template>

  <div class="gray-blank"></div>
  <div class="gray-blank"></div>

</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount, getCurrentInstance, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { createLogger, useStore } from "vuex";


//글로벌 프로퍼티 설정 부분
import { useGlobalProperties } from '@/page/main/composables/useGlobalProperties'; 
const { $axios, $axiosJson } = useGlobalProperties();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { loginChk } = useComm();

import modal from "@/page/main/comm/modal.vue";
import filterSwiper from "@/page/main/views/cmmnt/comm/filterSwiper.vue"

import top3Lst from '@/page/main/views/cmmnt/comm/top3Lst.vue'

const store = useStore();

const currentTab = computed(() => store.state.cmmnt.main.currentTab);

const tabs = [
  { id: 'm-tab-1', name: '인기순', code: '001', needLogin : false},
  { id: 'm-tab-2', name: '조회 Best', code: '002', needLogin : false},
  { id: 'm-tab-3', name: '최신순', code: '003', needLogin : false},
  { id: 'm-tab-4', name: 'MY찜', code: '004', needLogin : true},
]

const setCurrentTab = (tabId) => {
  const tab = tabs.find(t => t.id == tabId);
  if(tab.needLogin){
    if(!loginChk()){
      return false;
    }
  }
  store.dispatch('cmmnt/setCurrentTab', tabId);
}


</script>