<template>
        <header class="tw-top">
          <a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
          <h1 class="tw-label-top">{{ $t('user.main.MYPAGE.TOP.MYINFO') }}</h1>
        </header>

        <div class="tw-body p-bt-20">
          <div v-show="bLoginChk">
            <div class="tw-profile">
              <div class="tw-profile-top">
                <div class="tw-profile-box">
                  <div class="profile-fileupload" tabindex="0">
                    <img v-if="sUser.prflFileFullPath" :src="sUser.prflFileFullPath" alt="프로필 사진" width="96" height="96">
                    <img v-else src="/tripwith/assets/images/img-profile.png" alt="프로필 사진" width="96" height="96">
                  </div>
                </div>
                <h2 class="tw-profile-neme"> {{ sUser.nick }}</h2>
              </div>
              <!-- <div class="tw-profile-bt">
                <div class="bt-box profile-gender">
                  <div class="txt-top">성별</div>
                  <div class="txt-bt">여자</div>
                </div>
                <div class="bt-box profile-nation">
                  <div class="txt-top">국적</div>
                  <div class="txt-bt">오스트레일리아</div>
                </div>
                <div class="bt-box profile-age">
                  <div class="txt-top">나이</div>
                  <div class="txt-bt">1993.12.19</div>
                </div>
              </div> -->
              <span class="tw-profile-modify">
                <a class="tw-btn-text" @click="navigate({ value:'mypageMod' })" >{{ $t('user.main.MYPAGE.TOP.MOD') }}</a>
              </span>
            </div>
            <div class="tw-body-line-wrap m-10 p-0">
              <p>라인</p>
            </div>
            <nav>
              <ul class="tw-my-list-box p-0">
                <!-- <li class="my-list">
                  <a>
                    <img src="/tripwith/assets/images/img-my-trip.png" alt="여행기본정보 이미지" width="48" height="48">
                    <span class="my-list-txt">여행기본정보</span>
                  </a>
                </li> -->
                <li class="my-list">
                  <a @click="navigate({ value:'mypageFavs' })">
                    <img src="/tripwith/assets/images/img-my-save.png" alt="나의 찜" width="48" height="48">
                    <span class="my-list-txt">{{ $t('user.main.MYPAGE.BUTTON.MYFAV') }}</span>
                  </a>
                </li>
                <li class="my-list">
                  <a @click="navigate({ value:'mypagePosts' })">
                    <img src="/tripwith/assets/images/img-my-writing.png" alt="내가 작성한 글 이미지" width="48" height="48">
                    <span class="my-list-txt">{{ $t('user.main.MYPAGE.BUTTON.MYCMMNT') }}</span>
                  </a>
                </li>
                <li class="my-list">
                  <a @click="navigate({ value:'mypageLikes' })"> 
                    <img src="/tripwith/assets/images/img-my-heart.png" alt="나의 좋아요 이미지" width="48" height="48">
                    <span class="my-list-txt">{{ $t('user.main.MYPAGE.BUTTON.MYLIKE') }}</span>
                  </a>
                </li>
                <!-- <li class="my-list p-bt-10">
                  <a>
                    <img src="/tripwith/assets/images/img-my-follow.png" alt="팔로우 이미지" width="48" height="48">
                    <span class="my-list-txt">팔로우</span>
                  </a>
                </li>
                <li class="my-list p-bt-10">
                  <a href="../3step/PB_NC_001.html">
                    <img src="/tripwith/assets/images/img-my-warning.png" alt="나의 신고 이미지" width="48" height="48">
                    <span class="my-list-txt">나의 신고</span>
                  </a>
                </li>
                <li class="my-list p-bt-10">
                  <a href="../3step/MP_AC_001.html">
                    <img src="/tripwith/assets/images/img-my-trophy.png" alt="나의 업적 이미지" width="48" height="48">
                    <span class="my-list-txt">나의 업적</span>
                  </a>
                </li> -->
              </ul>
            </nav>

            <div class="tw-body-line-wrap m-10 p-0">
              <p>라인</p>
            </div>
        </div>

        <div v-show="!bLoginChk">
          <div class="tw-body-input-wrap">
            <label class="tw-label-default essential">{{ $t('user.main.MYPAGE.LOGIN.EMAIL') }}</label>
            <div class="tw-input-wrap">
              <input 
                  type="text" 
                  class="tw-input-default" 
                  :placeholder="$t('user.main.MYPAGE.LOGIN.EMAIL-PH')" 
                  v-model="user.id" 
                  ref="id"
                  maxlength="40"
              >
              <span class="tw-input-icon" @click="delId" >input아이콘</span>
            </div>
          </div>
          <div class="tw-body-input-wrap">
            <label class="tw-label-default essential">{{ $t('user.main.MYPAGE.LOGIN.PSWRD') }}</label>
            <div class="tw-pw-wrap">
              <input 
                    :type="bShowPswrd ? 'text' : 'password'" 
                    class="tw-input-default" 
                    autocomplete="current-password" 
                    :placeholder="$t('user.main.MYPAGE.LOGIN.PSWRD-PH')" 
                    v-model="user.pswrd" 
                    ref="pswrd"
                    maxlength="40"
                    >
              <span class="tw-pw-icon" 
                    :class="{ 'active': bShowPswrd }"
                    @click="togglePswrdVisibility('pswrd')" >
                  {{ bShowPswrd ? '숨기기' : '보이기' }}
              </span>
            </div>
          </div>
          <div class="tw-body-btn-wrap">
            <button type="button" class="tw-btn-default primary" @click="goLogin">{{ $t('user.main.MYPAGE.LOGIN.LOGIN') }}</button>
            <button type="button" class="tw-btn-default border" @click="navigate({ value:'signupAgree' })" >{{ $t('user.main.MYPAGE.LOGIN.SIGNUP') }}</button>
          </div>
          <div class="tw-body-pw-wrap">
            <button type="button" class="tw-btn-text no-line" @click="navigate({value:'resetEmail'})"> {{ $t('user.main.RESET_PSWRD.TITLE') }} </button>
            <!-- <label class="tw-label-small"> ｜ </label>
            <button type="button" class="tw-btn-text no-line"> 전화번호로 비밀번호 찾기 </button> -->
          </div>
          <div class="tw-body-line-wrap">
            <p>라인</p>
            <div>
              <label class="tw-label-title">{{ $t('user.main.MYPAGE.LOGIN.SMPL-LOGIN') }}</label>
            </div>
          </div>
          <div class="tw-body-sns-wrap">
            <button type="button" class="tw-btn-sns" @click="clickKakaoSmplLoginBtn">
              <span class="tw-kko-icon">카카오톡로고</span>
            </button>
            <button type="button" class="tw-btn-sns" @click="clickNaverSmplLoginBtn">
              <span class="tw-nav-icon">네이버로고</span>
            </button>
            <button type="button" class="tw-btn-sns" @click="clickGoogleSmplLoginBtn">
              <span class="tw-goo-icon">구글로고</span>
            </button>
            <!-- <button type="button" class="tw-btn-sns" @click="clickFacebookSmplLoginBtn" >
              <span class="tw-fac-icon">페이스북로고</span>
            </button>
            <button type="button" class="tw-btn-sns">
              <span class="tw-app-icon">애플로고</span>
            </button>
            <button type="button" class="tw-btn-sns">
              <span class="tw-wec-icon">위챗로고</span>
            </button> -->
          </div>
        </div>

          <div class="tw-setting-box p-20-0">
            <h2 class="tw-label-default bold f16 m-bt-20">{{ $t('user.main.MYPAGE.LST.TRIP-COND.TITLE') }}</h2>
            <ul class="tw-setting-list-box main" >
              <li class="default-list" @click="navigate({ value:'mypageLang' })"><a>{{ $t('user.main.MYPAGE.LST.TRIP-COND.LANG') }}</a></li>
              <li class="default-list"><a @click="navigate({ value:'weatherMain' })" >{{ $t('user.main.MYPAGE.WEATHER.TITLE') }}</a></li>
              <!-- <li class="default-list"><a href="../2step/MB_MP_001_국가.html">국가 설정</a></li>  -->
              <li class="default-list"><a @click="navigate({ value:'exchangeRatesMain' })">{{ $t('user.main.MYPAGE.EXCHANGE_RATES.TITLE') }}</a></li> 
              <!-- <li class="default-list"><a href="../2step/MB_MP_001_시차1.html">현지 시간 / 시차</a></li> -->
              <!-- <li class="default-list"><a href="../2step/MB_MP_001_통화.html">통화 설정</a></li> -->
              <!-- <li class="default-list"><a href="../3step/PB_NC_002.html ">알림 설정</a></li> -->
              <!-- <li class="default-list"><a href="../2step/MB_MP_001_단위.html">단위 설정</a></li> -->
            </ul>
          </div>

          <div class="tw-body-line-wrap m-10 p-0">
            <p>라인</p>
          </div>

          <div class="tw-setting-box p-20-0">
            <h2 class="tw-label-default bold f16 bt-p">{{ $t('user.main.MYPAGE.LST.TRIP-SETTING.TITLE') }}</h2>
            <ul class="tw-setting-list-box main" >
              <li class="default-list" v-if="bLoginChk" ><a @click="logout" >{{ $t('user.main.MYPAGE.LST.TRIP-SETTING.LOGOUT') }}</a></li>
              <li class="default-list" v-if="bLoginChk && sUser.joinTypeCode == 'EMAIL'" ><a @click="navigate({ value: 'resetPswrd'})" >{{ $t('user.main.RESET_PSWRD.TITLE-ISLOGIN') }}</a></li>
              <li class="default-list"><a @click="goTnc">{{ $t('user.main.MYPAGE.LST.TRIP-SETTING.TNC') }}</a ></li>
              <li class="default-list"><a @click="goPipa">{{ $t('user.main.MYPAGE.LST.TRIP-SETTING.PIPA') }}</a></li>
              <!-- <li class="default-list"><a href="../2step/MB_MP_001_문의1.html">1:1문의</a></li> -->
              <li class="default-list"><a @click="goNtc">{{ $t('user.main.MYPAGE.LST.TRIP-SETTING.NTC') }}</a></li>
              <li class="default-list"><a @click="goFaq">{{ $t('user.main.MYPAGE.LST.TRIP-SETTING.FAQ') }}</a></li>
              <li class="default-list" v-if="bLoginChk" ><a @click="navigate({value : 'mypageWthd' })">{{ $t('user.main.MYPAGE.LST.TRIP-SETTING.WTHD') }}</a></li>
            </ul>
          </div>
        </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, nextTick } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
import axiosWrpr, { axiosInst } from '@/util/axiosHelper';  // axiosInst도 함께 import
//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert } = useComm();

//유저 정보 처리 시작
import cnst from '@/util/authConstants';

const user = ref({});

user.value.gbn = cnst.USER.GBN.USER;

const id = ref(null)
const pswrd = ref(null)

const bLoginChk = computed(() => store.state.user.user.bLoginChk);
const sUser = computed(() => store.state.user.user);

async function goLogin() {
    if(id.value.value == ""){
        id.value.focus();
        return false;
    }

    if(pswrd.value.value == ""){
        pswrd.value.focus()
        return false;
    }

    user.value.id = id.value.value
    user.value.pswrd = pswrd.value.value

    await $axios({
        method: 'POST',
        data: user.value,
        url: '/user/login',
        }).then( async (data) => {
          //로그인 처리
            let curUser = await loginVer();
    
            if(curUser && Object.keys(curUser).length !== 0){
            //userModule 생성
              let user = {
                    loginGbnCode : "NORMAL",
                    lang : 'kor',
                    bLoginChk : true,
                    userNum : curUser.userNum,
                    nick : curUser.nick,
                    gndr : curUser.gndr,
                    prflImgNum : curUser.prflFileNum,
                    prflFileFullPath : curUser.prflFileFullPath,
                    birthYear : curUser.birthYear,
                    birthMonth : curUser.birthMonth,
                    birthDay : curUser.birthDay,
                    joinTypeCode : curUser.joinTypeCode,
              }
            store.dispatch('user/setUser', user);
            }

        }).catch(error => {
           const data = error.response.data;
            toggleCommAlert("LOGIN", data.resultCode, "ALERT");
        });
}

import { clickNaverSmplLoginBtn, clickKakaoSmplLoginBtn, clickGoogleSmplLoginBtn } from '@/page/main/composables/useSimpleLogin.js';

function clickFacebookSmplLoginBtn(){
    const facebookUrl = "https://www.facebook.com/v11.0/dialog/oauth?client_id="
                  + cnst.SMPL.FACEBOOK.CLIENT_ID
                  + "&redirect_uri="
                  + cnst.SMPL.FACEBOOK.CALLBACK_URL
                  + "&response_type=code"
                  + "&scope=email,public_profile";
    window.location.href = facebookUrl;
}

const logout = async () => {
  try {
    // 로그아웃 API 호출
    await axiosWrpr({
      method: 'POST',
      url: '/token/logout',
      meta: { requiresAuth: true }  // 인증이 필요한 요청임을 표시
    });

    // 로컬 스토리지 토큰 제거
    localStorage.removeItem("authorization");
    
    // Vuex 스토어 초기화
    await store.dispatch("user/clearUser");
    
    // axios 인스턴스의 헤더에서 인증 토큰 제거
    delete axiosInst.defaults.headers['Authorization'];

    // 성공 알림 표시
    toggleCommAlert("LOGOUT", "SUCCESS", "ALERT", () => {
      // 페이지 새로고침 또는 로그인 페이지로 이동
      router.go(0); // 또는 router.push({ name: 'mypageMain' });
    });

  } catch (error) {
    console.error('Logout failed:', error);
    
    // 에러 처리
    if (error.response) {
      const { resultCode } = error.response.data;
      toggleCommAlert("LOGOUT", resultCode, "ALERT");
    } else {
      toggleCommAlert("LOGOUT", "ERROR", "ALERT");
    }
    
    // 에러가 발생해도 로컬 데이터는 정리
    localStorage.removeItem("authorization");
    await store.dispatch("user/clearUser");
    delete axiosInst.defaults.headers['Authorization'];
    router.go(0);
  }
};

onMounted(() => {
    window.fbAsyncInit = function() {
    window.FB.init({
      appId      : cnst.SMPL.FACEBOOK.CLIENT_ID,
      cookie     : true,
      xfbml      : true,
      version    : 'v11.0'
    });
    window.FB.AppEvents.logPageView();
    };
})

const loginVer = async () => {
  let tmpUser = {};
  await $axios({
      method: 'POST',
      data: '',
      url: '/user/chkToken',
  }).then((data) => {
      const resultObj = data.resultObj;
      if (resultObj) {
          tmpUser = resultObj;
      }
  }).catch((error) => {
      alert("에러 발생");
  })
  return tmpUser;  
}

const goTnc = () => {
  const TNC_BOARD_NUM = process.env.VUE_APP_BOARD_TNC;
  navigate({value:'legalDocument', params :{boardNum : TNC_BOARD_NUM}})
}

const goPipa = () => {
  const PIPA_BOARD_NUM = process.env.VUE_APP_BOARD_PIPA;
  navigate({value:'legalDocument', params :{boardNum : PIPA_BOARD_NUM}})
}

const goNtc = () => {
  const NTC_BOARD_NUM = process.env.VUE_APP_BOARD_NTC;
  navigate({value:'mypageNtc', params :{boardNum : NTC_BOARD_NUM}})
}

const goFaq = () => {
  const FAQ_BOARD_NUM = process.env.VUE_APP_BOARD_FAQ;
  navigate({value:'mypageFaq', params :{boardNum : FAQ_BOARD_NUM}})
}


const delId = () => {
  id.value.value = '';
  user.value.id = '';
  nextTick(() => {
    proxy.$refs.id.focus();
  });
}

const bShowPswrd = ref(false);
const togglePswrdVisibility = (field) => {
  if (field === 'pswrd') {
    bShowPswrd.value = !bShowPswrd.value
  }
}

</script>