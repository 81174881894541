<template>
<div class="poll-modal" :class="{ 'is-active': isVisible }">
    <div class="poll-content">
      <header class="tw-top">
        <a @click="closePoll" class="tw-top-icon">뒤로가기 아이콘</a>
        <h1 class="tw-label-top">{{ $t('user.main.CMMNT.POLL.TITLE') }}</h1>
        <button type="button" class="tw-btn-text white right" aria-label="완료 버튼" @click="submitPoll"> {{ $t('user.main.CMMNT.POLL.COMPLETE') }} </button>
      </header>

      <div class="tw-body h-0 p-0">
        <div class="scroll-container">
          <div class="tw-body-padding-wrap">
            <div class="tw-body-input-wrap">
            <form>
              <div class="tw-body-input-wrap">
                <h2 class="tw-label-default bold bt-p">{{ $t('user.main.CMMNT.POLL.QUESTION') }}</h2>
                <div class="tw-input-wrap">
                  <input type="text" class="tw-input-default" :placeholder="`${$t('user.main.CMMNT.POLL.QUESTION-PH')}`" aria-label="제목 입력" v-model="pollData.question" maxlength="300">
                  <span class="tw-input-icon"  tabindex="0">input아이콘</span>
                </div>
                <label class="tw-label-body">
                </label>
              </div>
              <h2 class="tw-label-default bold bt-p">{{ $t('user.main.CMMNT.POLL.OPTION') }}</h2>
              <div 
                v-for="(item, index) in pollData.options" 
                :key="index" 
                class="tw-input-wrap" 
                style="margin-bottom: 10px;"
              >
                <input 
                  v-model="pollData.options[index].text" 
                  type="text" 
                  class="tw-input-default" 
                  :placeholder="`${$t('user.main.CMMNT.POLL.OPTION-PH')} ${index + 1}`"
                >
                <span 
                  v-if="pollData.options.length > 2" 
                  class="tw-input-icon delete" 
                  tabindex="0" 
                  @click="removeItem(index)"
                >삭제</span>
              </div>
            </form>
            </div>
          </div>
          <div class="bottom-button-container">
            <button type="button" class="tw-btn-default secondary icon f16" aria-label="항목 추가하기 버튼" @click="addItem">
              <span class="floating-icon">plus 아이콘</span>
              <span class="tw-icon-text">{{ $t('user.main.CMMNT.POLL.ADD-ITEM') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue';
import { defineProps, defineEmits, defineExpose } from 'vue';

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['close', 'pollComplete']);

const pollData = reactive({
  question: '',
  options: [
    { text: '' },  // 객체 형태로 초기화
    { text: '' }
  ]
});

const errors = reactive({
  question: '',
  options: ''
});

// 유효성 검사
const validatePoll = () => {
  let isValid = true;
  errors.question = '';
  errors.options = '';

  if (!pollData.question.trim()) {
    errors.question = '투표 제목을 입력해주세요.';
    isValid = false;
  }

  const validItems = pollData.options.filter(item => item.text.trim());
  if (validItems.length < 2) {
    errors.options = '최소 2개의 투표 항목이 필요합니다.';
    isValid = false;
  }

  return isValid;
};

// 항목 추가
const addItem = () => {
  if (pollData.options.length < 10) {
    pollData.options.push({ text: '' });  // 객체 형태로 추가
  }
};

const removeItem = (index) => {
  pollData.options.splice(index, 1);
};

const setPollData = (data) => {
  if (data) {
    pollData.question = data.question;
    pollData.pollNum = data.pollNum;
    pollData.options = [...data.options];
  }
};
// pollComplete 이벤트를 통해 부모 컴포넌트로 데이터 전달
defineExpose({ setPollData });

// 투표 데이터 제출 (부모 컴포넌트로 전달)
const submitPoll = () => {
  if (!validatePoll()) return;

  const pollResult = {
    pollNum: pollData.pollNum,
    question: pollData.question,
    options: pollData.options
      .filter(item => item.text?.trim()) // text 속성에 대해 trim 적용
      .map(item => ({
        optionNum: item.optionNum, // 기존 optionNum 유지
        text: item.text.trim()
      }))
  };

  emit('pollComplete', pollResult);
  closePoll();
};

const closePoll = () => {
  // 상태 초기화
  pollData.question = '';
  pollData.options = ['', ''];
  errors.question = '';
  errors.options = '';
  emit('close');
};

</script>

<style scoped>
.poll-modal {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: transform 0.2s ease-in-out;
  z-index: 1000;
}

.poll-modal.is-active {
  transform: translateX(-100%);
}

.poll-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 352px;
  max-width: 500px;
  margin: auto;
}

.tw-top {
  flex-shrink: 0; /* 헤더 크기 고정 */
}

.tw-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100% - 56px); /* 헤더 높이만큼 뺌 */
}

.scroll-container {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 80px; /* 하단 버튼 높이만큼 여백 */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.bottom-button-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: #fff;
  /* border-top: 1px solid #eee; */
}

.tw-body-padding-wrap {
  padding: 20px;
}

/* 스크롤바 스타일링 (선택사항) */
.scroll-container::-webkit-scrollbar {
  display: none;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>