export default {
    namespaced: true,
    state: {
        favs : {
            currentTab : 'tripinfo'
        },
    },
    getters: {
    },
    mutations: {
        setCurrentTab(state, payload){
            state[payload.stateName][payload.varName] = payload.value;
        },
    },
    actions: {
        setCurrentTab({ state, commit }, payload) {
            commit('setCurrentTab', payload);
        },
    },
};
