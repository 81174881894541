<template>
  <div class="tw-main-banner">
        <Swiper :modules="[Pagination, Navigation, Autoplay]" :slidesPerView="1" :loop="true"  :pagination="{ clickable: true }" :initialSlide="1" ref="swiperRef">
          <template v-for="(bnr, index) in bnrLst" :key="index">
            <SwiperSlide>
              <div v-if="bnr.cntn" class="banner-corver" v-html="bnr.cntn">
              </div>
              <img v-if="bnr.bnrFileNum"
              :src="bnr.fullPath"
              :alt="bnr.bnrName">
            </SwiperSlide>
          </template>
        </Swiper>
  </div>
</template>


<script setup>
import { ref } from 'vue';
import { Pagination, Navigation, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css'; // 기본 Swiper 스타일
import 'swiper/css/pagination'; // Swiper Pagination 스타일
import 'swiper/css/navigation'; // Swiper Navigation 스타일
const swiperRef = ref(null);

//부모 컴포넌트에서 받은 값
const props = defineProps({
  bnrLst: Array,
})

</script>
