<template>
  
  <header class="tw-top">
    <a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
    <h1 class="tw-label-top">{{ $t('user.main.MYPAGE.WEATHER.TITLE') }}</h1>
    <!-- <button type="submit" class="tw-btn-text white right" aria-label="지역 삭제 버튼"> 지역 삭제 </button> -->
  </header>

  <div class="tw-body p-30-16">
    <div class="tw-weather-wrap full" v-if="weatherData">
      <div class="tw-weather-detail">
        <a @click="goDetail" class="tw-btn-text no-line">지역 변경하기</a>
        <div class="tw-detail-top">
          <h2 class="tw-top-tit">{{ getRegionName(weatherData, 'STEP1') }} {{ getRegionName(weatherData, 'STEP2') }}</h2>
          <div class="tw-top-time">{{ formattedTime }}</div>
          <!-- <div class="tw-top-sub">최고 {{ weatherData.weatherData.tmax }} / 최저 {{ weatherData.weatherData.tmin }}</div> -->
          <div class="tw-weather-icon" :class="getWeatherClass(weatherData.weatherData.sky, weatherData.weatherData.pty)">아이콘</div>
          <div class="tw-weather-temperature big">{{ Math.round(weatherData.weatherData.t1h) }}</div>
        </div>
      </div>
      <weatherTimelineSwiper :timelineData="weatherData.fsData" />
    </div> 
  </div>
</template>

<script setup>
import { ref, computed, onMounted, getCurrentInstance, defineProps } from 'vue';
import weatherRegData from '@/assets/data/weather_reg.json';
import { useComm } from '@/page/main/composables/comm';
import { getWeatherClass, getRegionName } from '@/util/weatherUtil'; // 날씨 클래스 가져오는 유틸리티 함수
import weatherTimelineSwiper from '@/page/main/views/mypage/weather/weatherTimelineSwiper.vue'; // 컴포넌트 이름 수정
import { useNavigation } from '@/page/main/composables/useNavigation';
import { getFormattedTime } from '@/util/DateTimeUtil';

const { navigate, goBack } = useNavigation();
const { toggleCommLayer, toggleCommAlert } = useComm();

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

const props = defineProps({
  weatherRegNum: {
    type: String,
    required: true
  }
});

const weatherData = ref(null); // 날씨 데이터를 저장할 ref
const formattedTime = computed(() => getFormattedTime());

const fetchWeatherData = async (weatherRegNum) => {
  const regionInfo = weatherRegData.find(region => region.WEATHER_REG_NUM == weatherRegNum);
  if (regionInfo) {
    const fusResponse = await goGetFus(regionInfo.X, regionInfo.Y); // 날씨 데이터 가져오기
    const fsResponse = await goGetFs(regionInfo.X, regionInfo.Y); // 날씨 데이터 가져오기
    weatherData.value = { ...regionInfo, weatherData: fusResponse, fsData: fsResponse }; // 지역 정보와 날씨 데이터 결합
    
  }
};

const goGetFus = async (nx, ny) => {
  const params = { nx, ny };
  let returnFus = {};
  await $axios({
    method: 'GET',
    params: params,
    url: '/weather/fus',
  })
    .then((data) => {
      const resultObj = data.resultObj;
      if (resultObj) {
        returnFus = resultObj;
      }
    })
    .catch(() => {
      toggleCommAlert("COMM", "FAILED");
    });
  return returnFus;
};


const goGetFs = async (nx, ny) => {
  const params = { nx, ny };
  let returnFs = {};
  await $axios({
    method: 'GET',
    params: params,
    url: '/weather/fs',
  })
    .then((data) => {
      const resultObj = data.resultObj;
      if (resultObj) {
        returnFs = resultObj;
      }
    })
    .catch(() => {
      toggleCommAlert("COMM", "FAILED");
    });
  return returnFs;
};

const goDetail = () => {
  navigate({ value: 'weatherAdd' });
}
onMounted(() => {
  fetchWeatherData(props.weatherRegNum); // 컴포넌트가 마운트될 때 날씨 데이터 가져오기
});
</script>