<template>

      <header class="tw-top">
          <a class="tw-top-icon" @click="goBack()">뒤로가기 아이콘</a>
        <h1 class="tw-label-top">{{$t('user.main.CMMNT.TITLE')}}</h1>
      </header>

      <div class="tw-body p-0">
        <div class="tw-info-detail">
          <div class="tw-info-detail-top">
            <div class="detail-top-info-wrap">
              <div class="detail-top-info-img">
                <img :src="cmmnt.fullPath" alt="닉네임 프로필 이미지">
              </div>
              <div class="detail-top-info-id">{{ cmmnt.nick }}
                <!-- <span class="id-badge">
                  <img src="/tripwith/assets/images/img-level-total-1.svg" alt="뱃지 이미지">
                </span> -->
              </div>
              <div class="detail-top-info-date">{{ convertTimestampToFormattedDate(cmmnt.regDt) }}</div>
            </div>    
            <div class="detail-top-icon-wrap">
              <span :class="['tw-bookmark-icon', { on: cmmnt.dibsedByUser }]" tabindex="0" @click="handlePostDibs(cmmnt)">북마크 아이콘</span>
              <template v-if="cmmnt.userNum == sUser.userNum">
                <span class="tw-more-icon" aria-haspopup="true" tabindex="0" @click="clickMoreIcon()">더보기 아이콘</span>
                <div class="tw-pop-mini"  role="dialog" aria-modal="true" v-show="bDtlShow" v-click-outside="onClickOutside">
                  <ul>
                    <li><a @click="goCmmntWrite">{{$t('user.main.COMM.UPD')}}</a></li>
                    <li><a @click="delPostConfirm" >{{$t('user.main.COMM.DEL')}}</a></li>
                  </ul>
                </div>
              </template>

            </div>
          </div>
          <div class="tw-info-detail-body">
            <div class="detail-body-txt">
              <h2>{{ cmmnt.title}}</h2>
              {{ cmmnt.cntn }}
            </div>
  
            <div class="detail-body-img-wrap">
              <ul>
                <li v-for="(img, index) in imgFiles" :key="index">
                  <div> <img :src="img.fullPath" alt="첨부 이미지"> </div>
                </li>
              </ul>
            </div>

            <!-- 투표 영역 -->
            <div v-if="cmmnt.pollQuestion" class="detail-vote-attend-wrap" :class="{ result: showResult }">
              <div class="detail-vote-attend-box">
                <div class="vote-attend-top">
                  <span class="tw-vote-ing-icon">투표 아이콘</span>
                  <span class="tw-icon-text bold">{{ cmmnt.pollQuestion }}</span>
                  <span class="tw-icon-text" v-if="totalVotes > 0">{{ totalVotes }}{{ $t('user.main.CMMNT.POLL.VOTED-BY') }}</span>
                  <button 
                    v-if="showResult" 
                    type="button" 
                    class="tw-btn-text" 
                    aria-label="다시 투표하기 버튼"
                    @click="resetVote"
                  >
                    {{ $t('user.main.CMMNT.POLL.RE-VOTE') }}
                  </button>

                </div>

                <!-- 투표 입력 화면 -->
                <div v-if="!showResult" class="vote-attend-list">
                  <form @submit.prevent="handleVoteSubmit">
                    <ul>
                      <li v-for="option in cmmnt.pollOptions" :key="option.pollOptionNum" @click="selectOption(option.pollOptionNum)">
                        <button type="button" class="tw-vote-btn" aria-label="투표 선택 버튼">
                          <label :for="'vote' + option.pollOptionNum" class="radio_box">
                            <input 
                              :type="maxSelectCount > 1 ? 'checkbox' : 'radio'"
                              :id="'vote' + option.pollOptionNum" 
                              name="vote" 
                              :value="option.pollOptionNum"
                              :checked="selectedOptions.includes(option.pollOptionNum)"
                              :disabled="hasVoted && !showResult"
                              style="pointer-events: none;"
                            >
                            <span class="on2"></span>
                            {{ option.optionText }}
                            <span v-if="maxSelectCount > 1" class="select-count">
                              ({{ selectedOptions.length }}/{{ maxSelectCount }})
                            </span>
                          </label>
                        </button>
                      </li>
                    </ul>
                    <div class="tw-body-btn-wrap flex">
                      <button 
                        type="submit" 
                        class="tw-btn-default primary" 
                        :disabled="selectedOptions.length === 0 || hasVoted"
                      >
                        {{ $t('user.main.CMMNT.POLL.VOTE') }}
                      </button>
                      <button 
                        type="button" 
                        class="tw-btn-default border" 
                        @click="showResult = true"
                      >
                        {{ $t('user.main.CMMNT.POLL.RESULT') }}
                      </button>
                    </div>
                  </form>
                </div>

                <!-- 투표 결과 화면 -->
                <div v-else class="vote-attend-list">
                  <ul>
                    <li v-for="option in cmmnt.pollOptions" :key="option.pollOptionNum">
                      <div class="vote-result-box">
                        <div class="vote-result-progress" :style="{ width: calculatePercentage(option.voteCount) + '%' }"></div>
                        <span class="result-txt-left">{{ option.optionText }}</span>
                        <span class="result-txt-right">{{ option.voteCount }} {{ $t('user.main.COMM.COUNT.USER_SUFFIX') }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
  
          </div>
          <div class="tw-info-detail-btn">
              <div
                :class="['info-bt-like icon', { on: cmmnt.likedByUser }]"
                tabindex="0"
                @click="handlePostLike(cmmnt)"
              >
                <span class="tw-like-icon">좋아요 아이콘</span>
                <span class="tw-like-txt">{{ cmmnt.likeCnt > 0 ? cmmnt.likeCnt : $t('user.main.CMMNT.MAIN.LIKE')}}</span>
              </div>
            <div class="info-bt-comm icon" :class="cmmnt.cmntCnt > 0 ? 'on' : ''" tabindex="0">
              <span class="tw-comm-icon">댓글 아이콘</span>
              <span class="tw-comm-txt">{{ cmmnt.cmntCnt > 0 ? cmmnt.cmntCnt : $t('user.main.CMMNT.MAIN.CMNT')}}</span>
            </div>
            <div class="info-bt-share icon" tabindex="0">
              <span class="tw-share-icon">공유하기 아이콘</span>
              <span class="tw-share-txt">{{$t('user.main.CMMNT.MAIN.SHARE')}}</span>
            </div>
          </div>
          <div class="tw-info-detail-commentbox">
            <p>공백</p>
            <ul class="tw-comment-list-wrap">
              <!-- 댓글 열린상태 예시 -->
              <cmmnt-comm-cmnt></cmmnt-comm-cmnt>

            </ul>
          </div>
          <send-input :cmmntCmnt="cmmntCmnt" @submit="handlePostCmnt"></send-input>

          <!-- <div class="tw-info-detail-send">
            <div class="detail-send-img">
              <img src="/tripwith/assets/images/img-sample(5).png" alt="닉네임 프로필 이미지">
            </div>
            <div class="detail-send-input">
              <form>
                <input type="text" class="tw-input-default tw-input-comment" aria-label="댓글 달기">
              </form>
            </div>
            <button type="submit" class="detail-send-icon" aria-label="보내기 버튼">보내기</button>
          </div> -->


        </div>
      </div>
</template>

<script setup>
import { ref, onMounted,  defineProps, computed, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";
import { 
  goGetPost, 
  goGetCmntLst, 
  regPostCmnt,
  submitPollVote
} from '@/services/postService';

import { usePost } from '@/page/main/composables/usePost';
const { handlePostLike, handlePostDibs, handleDelPost } = usePost();

import { useNavigation } from '@/page/main/composables/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommAlert, loginChk } = useComm();

const route = useRoute();
const store = useStore();

import cmmntCommCmnt from '@/page/main/views/cmmnt/comm/cmntLst.vue'
import sendInput from '@/page/main/views/cmmnt/comm/sendInput.vue'

const props = defineProps({
  postNum: {
    type: String,
    required: true
  }
});

const postNum = props.postNum;
const cmmnt = ref({});
const imgFiles = ref([]);
const sUser = computed(() => store.state.user.user);

onMounted( async () => {
  try{
    cmmnt.value = await goGetPost(postNum);
  }catch (error){
    toggleCommAlert("COMM", "FAILED");
  }
  
  const fileLst = cmmnt.value.postFileModelLst;
  if (fileLst.length > 0) {
    fileLst.forEach(file => imgFiles.value.push(file));
  }

  try{
    cmmntCmntLst.value = await goGetCmntLst(cmmntCmnt.value);
  }catch (error){
    toggleCommAlert("COMM", "FAILED");
  }

  store.dispatch('cmmnt/setCmntLst', cmmntCmntLst.value);

  if (cmmnt.value?.voted) {
    hasVoted.value = true;
    showResult.value = true;
  }
})

//상단 더보기 아이콘 이벤트 관련
let bDtlShow = ref(false);

// 외부 클릭 이벤트를 처리하는 함수
const onClickOutside = (event) => {
  bDtlShow.value = false;
}

const clickMoreIcon = () =>{
  event.stopPropagation();
  bDtlShow.value = !bDtlShow.value;
}

const goCmmntWrite = () =>{
    navigate({value:"cmmntWrite", params: {postNum:postNum}  } );
}


const handlePostCmnt = async (e) => {
  if (!loginChk()) return;
  
  try {
    const response = await regPostCmnt(e);
    if (response.resultYn === 'Y') {
      cmmntCmnt.value.cntn = '';
      cmmntCmntLst.value = await goGetCmntLst(cmmntCmnt.value);
      store.dispatch('cmmnt/setCmntLst', cmmntCmntLst.value);

      await nextTick(() => {
        scrollToLatestComment();
      });
    }
  } catch (error) {
    console.log(error);
    toggleCommAlert("COMM", "FAILED");
  }
};

const scrollToLatestComment = () => {
  // 방법 1: 마지막 댓글 요소로 스크롤
  const commentList = document.querySelector('.tw-comment-list-wrap');
  const lastComment = commentList?.lastElementChild;
  lastComment?.scrollIntoView({ behavior: 'smooth' });
};

const cmmntCmnt = ref({postNum : postNum, cntn:''});
const cmmntCmntLst = ref([]);


const delPostConfirm = () => {
  const onConfirm = async () => {
    await handleDelPost(cmmnt.value, navigate({value:"cmmntMain"})); 
  };

  //confirm open
  toggleCommAlert("CMMNT-DEL", "CONFIRM", "CONFIRM", onConfirm);
}

// dtl.vue의 script setup 부분에 추가
const selectedOptions = ref([]);
const maxSelectCount = ref(1); // 서버에서 받아올 최대 선택 가능 개수
const showResult = ref(false);
const hasVoted = ref(false);

const resetVote = () => {
  showResult.value = false;
  hasVoted.value = false;
  // 이전 선택했던 옵션들을 selectedOptions에 설정
  selectedOptions.value = cmmnt.value.pollOptions
    .filter(option => option.checked)
    .map(option => option.pollOptionNum);
};

const selectOption = (optionNum) => {
  if (hasVoted.value && !showResult.value) return; // 투표 완료 상태이면서 결과 보기가 아닐 때만 제한
  
  if (maxSelectCount.value === 1) {
    // 단일 선택인 경우
    selectedOptions.value = [optionNum];
  } else {
    // 다중 선택인 경우
    const index = selectedOptions.value.indexOf(optionNum);
    if (index === -1) {
      if (selectedOptions.value.length < maxSelectCount.value) {
        selectedOptions.value = [...selectedOptions.value, optionNum];
      }
    } else {
      selectedOptions.value = selectedOptions.value.filter(num => num !== optionNum);
    }
  }
};

// 총 투표 수 계산
const totalVotes = computed(() => {
  if (!cmmnt.value?.pollOptions) return 0;
  return cmmnt.value.pollOptions.reduce((sum, option) => sum + (option.voteCount || 0), 0);
});

// 투표 비율 계산
const calculatePercentage = (voteCount) => {
  if (!totalVotes.value) return 0;
  return Math.round((voteCount / totalVotes.value) * 100);
};


const handleVoteSubmit = async () => {
  if (!loginChk() || selectedOptions.value.length === 0) return;
  
  try {
    const response = await submitPollVote(cmmnt.value.pollNum, selectedOptions.value);
    if (response.resultYn === 'Y') {
      hasVoted.value = true;
      showResult.value = true;
      await refreshPollData();
    }
  } catch (error) {
    toggleCommAlert("VOTE", "FAIL");
  }
};

// 투표 데이터 새로고침
const refreshPollData = async () => {
  cmmnt.value = await goGetPost(postNum);
};



function convertTimestampToFormattedDate(timestamp) {
    if(timestamp == undefined){
      return '';
    }

    // timestamp 값을 milliseconds로 변환
    const date = new Date(timestamp);

    // 원하는 형식으로 날짜를 포맷
    const options = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
    };
    
    // 로케일을 'ko-KR'로 설정하여 한국 형식으로 포맷
    const formattedDate = new Intl.DateTimeFormat('ko-KR', options).format(date);

    // 'yyyy. MM. dd' 포맷으로 반환
    return formattedDate.replace(/\. /g, '.');
}
</script>