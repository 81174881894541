<template>
  <div class="tw-info-detail-send bottom active">
    <div class="detail-send-img">
      <img :src="computedProfileImageSrc" alt="닉네임 프로필 이미지">
    </div>
    <div class="detail-send-input">
        <input type="text" class="tw-input-default tw-input-comment" v-model="props.cmmntCmnt.cntn" @focus="loginChk"  @keydown.enter="regCmntDeb" ref="sendInput" >
    </div>
    <div class="detail-send-icon on" tabindex="0" @click="regCmntDeb">보내기</div>
  </div>
</template>


<script setup>
import { ref, onMounted, onBeforeMount, onBeforeUnmount, getCurrentInstance, computed } from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import { useNavigation } from '@/page/main/composables/useNavigation';
import { throttle } from 'lodash'

const { navigate } = useNavigation();

import { useComm } from '@/page/main/composables/comm';
const { toggleCommLayer, toggleCommAlert, loginChk } = useComm();

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const $axiosJson = proxy.$axiosJson;
const store = useStore();

const sUser = computed(() => store.state.user.user);

const sendInput = ref(null);
const cntn = ref("");

const computedProfileImageSrc = computed(() => sUser.value.prflFileFullPath || '/tripwith/assets/images/img-profile.png');

//부모 컴포넌트에서 받은 값
const props = defineProps({
  cmmntCmnt: Object,
})

const emit = defineEmits(['submit'])
const handleSubmit = () => {
}


const regCmntDeb = _.throttle((e) => {
  if(sendInput.value?.value == ''){
    return false;
  }
  props.cmmntCmnt.cntn = sendInput.value.value;
  emit('submit', props.cmmntCmnt)
}, 1000);

</script>