export default {
    namespaced: true,
    state: {
        srch : {
            keyword : '',
            tripinfoCtgryCode : '000',
            orderCode : '001',
        },
        filter : {
            keyword : '',
            tripinfoCtgryCode : '000',
            orderCode : '001',
        },
        cmnt : {
            // 조회를 위해 사용한다
            data : {
                tripinfoNum : '',
                upprTripinfoCmntNum : null,
            },
            activeCmntNum : null,
            lst : '',
        }
    },
    getters: {
        selCmnt: (state) => (payload) => {
            let cmntView = null;
            if (payload.upprTripinfoCmntNum) {
                const parentComment = state.cmnt.lst.find(cmnt => cmnt.tripinfoCmntNum === payload.upprTripinfoCmntNum);
                if (parentComment) {
                    cmntView = parentComment.childTripinfoCmntView.find(cmnt => cmnt.tripinfoCmntNum === payload.tripinfoCmntNum);
                }
            } else {
                cmntView = state.cmnt.lst.find(cmnt => cmnt.tripinfoCmntNum === payload.tripinfoCmntNum);
            }
            return cmntView;
        },
        getCmntData: (state) => {
            return state.cmnt.data;
        },
    },
    mutations: {
        setTripinfoFromModal(state, payload) {
            state[payload.stateName][payload.key] = payload.value;
        },
        setTripinfoSrch(state, payload) {
            state.srch = payload;
        },
        setCmntLst(state, payload){
            state.cmnt.lst = payload;
        },
        setCmntData(state, payload) {
            state.cmnt.data = {
                ...state.cmnt.data,
                ...payload
            };
        },
        clearCmntLst(state, payload){
            state.cmnt.lst = '';
        },
        clearCmntData(state) {
            state.cmnt.data = {
                tripinfoNum: '',
                upprTripinfoCmntNum: null
            };
        },
        pushCmnt(state, payload){
            // payload에 upprTripinfoCmntNum이 있는지 확인
            if (payload.upprTripinfoCmntNum) {
                // lst 배열에서 tripinfoCmntNum이 upprTripinfoCmntNum과 일치하는 요소를 찾음
                const parentComment = state.cmnt.lst.find(cmnt => cmnt.tripinfoCmntNum === payload.upprTripinfoCmntNum);
                
                // 해당 요소를 찾았으면 그 요소의 childTripinfoLst 배열에 payload를 추가
                if (parentComment) {
                    if (!parentComment.childTripinfoCmntProjection) {
                        // 만약 childTripinfoLst 배열이 존재하지 않는다면, 초기화해줌
                        parentComment.childTripinfoCmntProjection = [];
                    }
                    parentComment.childTripinfoCmntView.push(payload);
                }
            } else {
                // upprTripinfoCmntNum이 없는 경우, lst 배열에 payload를 직접 추가
                state.cmnt.lst.push(payload);
            }
        },
        delCmnt(state, payload){
            // payload에 upprTripinfoCmntNum이 있는지 확인
            if (payload.upprTripinfoCmntNum) {
                // lst 배열에서 tripinfoCmntNum이 upprTripinfoCmntNum과 일치하는 요소를 찾음
                const parentComment = state.cmnt.lst.find(cmnt => cmnt.tripinfoCmntNum === payload.upprTripinfoCmntNum);
                
                // 해당 요소를 찾았으면 그 요소의 childTripinfoLst 배열에 payload를 추가
                if (parentComment) {
                    //자식열에서 같은 tripinfoCmntNum을 가진 데이터를 찾음
                    const index = parentComment.childTripinfoCmntView.findIndex(cmnt => cmnt.tripinfoCmntNum === payload.tripinfoCmntNum);
                    if (index !== -1) {
                      parentComment.childTripinfoCmntView.splice(index, 1, payload);
                    }
                }
            } else {
                //tripinfoCmntNum을 가진 데이터를 찾음
                let tripinfoCmntView = state.cmnt.lst.find(cmnt => cmnt.tripinfoCmntNum === payload.tripinfoCmntNum);

                if (tripinfoCmntView){
                    //얕은 복사를 통해 해당 기능이 정상 작동하는지 확인 필요
                    tripinfoCmntView.delYn = payload.delYn;
                    tripinfoCmntView.cntn = payload.cntn;
                }

            }
        },
        setActiveCmntNum(state, cmntNum) {
            state.cmnt.activeCmntNum = cmntNum;
        },
        clearActiveCmntNum(state) {
            state.cmnt.activeCmntNum = null;
        }
    },
    actions: {
        setTripinfoFromModal({ state, commit }, payload) {
            commit('setTripinfoFromModal', payload);
        },
        setTripinfoSrch({ state, commit }, payload) {
            commit('setTripinfoSrch', payload);
        },
        setCmntLst({ state, commit }, payload) {
            commit('setCmntLst', payload);
        },
        setCmntData({ commit }, payload) {
            commit('setCmntData', payload);
        },
        clearCmntLst({ commit }, payload) {
            commit('clearCmntLst', payload);
        },
        clearCmntData({ commit }) {
            commit('clearCmntData');
        },
        pushCmnt({ state, commit }, payload) {
            commit('pushCmnt', payload);
        },
        delCmnt({ state, commit }, payload) {
            commit('delCmnt', payload);
        },
        setActiveCmntNum({ commit }, cmntNum) {
            commit('setActiveCmntNum', cmntNum);
        },
        clearActiveCmntNum({ commit }) {
            commit('clearActiveCmntNum');
        }
    },
};
