<template>
  <header class="tw-top search">
    <a @click="goBack" class="tw-top-icon">뒤로가기 아이콘</a>
    <div class="tw-top-search">
      <form class="tw-search-wrap" role="search" @submit.prevent>
          <input v-model="searchQuery" type="search" class="tw-input-default tw-input-search" 
                 :placeholder="$t('user.main.MYPAGE.EXCHANGE_RATES.SEARCH')" aria-label="환율 검색" 
                 @input="filterRates">
          <span class="tw-search-icon" tabindex="0">검색 아이콘</span>
      </form>
    </div>
  </header>
  


  <div class="tw-body p-0">
    <div class="tw-setting-box full fixed-section scroll-section">
      <h2 class="tw-label-default bold f16 bt-p">{{ $t('user.main.MYPAGE.EXCHANGE_RATES.TITLE') }}</h2>
        
      <!-- 선택된 지역 목록 -->
      <ul class="tw-setting-list-box selected-regions">
        <li v-for="rate in selectedRates" :key="rate.exchangeRatesNum" 
            class="default-list active" tabindex="0" @click="toggleRates(rate)">
            {{  rate.targetCurrency  }} <span>active icon</span>
        </li>
      </ul>
    </div>

    <!-- 선택되지 않은 지역 목록 -->
    <div class="tw-setting-box full scrollable-section">
      <ul class="tw-setting-list-box">
        <li v-for="rate in unselectedRates" :key="rate.exchangeRatesNum" 
            class="default-list" tabindex="0" @click="toggleRates(rate)">
            {{ rate.targetCurrency }}<span>active icon</span>
        </li>
      </ul>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted, computed, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import { useNavigation } from '@/page/main/composables/useNavigation';
import { useComm } from '@/page/main/composables/comm';
import { getFormattedTime } from '@/util/DateTimeUtil';

const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter();
const store = useStore();
const { locale } = useI18n();
const { navigate, goBack } = useNavigation();
const { toggleCommLayer, toggleCommAlert } = useComm();

const allRates = ref([]);
const getRates = ref([]);
const selectedRates = ref([]);
const unselectedRates = ref([]);
const filteredUnselectedRates = ref([]);
const formattedTime = computed(() => getFormattedTime());
const searchQuery = ref('');

onMounted(() => {
  fetchExchangeRates();
});

const fetchExchangeRates = async () => {
  await $axios({
        method: 'GET',
        url: '/exchangerates',
    }).then((data) => {
        const resultObj = data.resultObj
        if (resultObj) {
          allRates.value = resultObj;
          fetchSelectedRates();
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
};

const fetchSelectedRates = async () => {
  await $axios({
      method: 'GET',
      url: '/exchangerates/user',
  }).then((data) => {
      const resultObj = data.resultObj
      if (resultObj) {
        getRates.value = resultObj;
        updateFilteredRates();
      }
  }).catch((error) => {
      toggleCommAlert("COMM", "FAILED");
  })
};

const updateFilteredRates = () => {
  selectedRates.value = allRates.value.filter(rate => 
    getRates.value.some(selected => selected.id.currency == rate.targetCurrency)
  );

  unselectedRates.value = allRates.value.filter(rate => 
    !getRates.value.some(selected => selected.id.currency == rate.targetCurrency)
  );
};

const formatRate = (rate) => {
  return rate.toFixed(4);
};

const toggleRates = _.throttle(async (rate) => {
  const index = getRates.value.findIndex(r => r.id.currency === rate.targetCurrency);
  if (index > -1) {
    getRates.value.splice(index, 1);
    await removeMapping(rate.targetCurrency);
  } else if (selectedRates.value.length < 3) {
    let tmpRatesMapp = {id: {currency: rate.targetCurrency}};
    getRates.value.push(tmpRatesMapp);
    await saveMapping(rate.targetCurrency);
  } else {
    //toggleCommAlert('COMM', 'MAX_SELECTED_RATES');
  }
}, 100);

const saveMapping = async (pCurrency) => {
  await $axios({
    method: 'POST',
    url: '/exchangerates/user',
    data: { currency : pCurrency },
    showSpinner: false,
  }).then(() => updateFilteredRates()).catch(() => toggleCommAlert("COMM", "FAILED"));
};

const removeMapping = async (pCurrency) => {
  await $axios({
    method: 'DELETE',
    url: `/exchangerates/user/${pCurrency}`,
    showSpinner: false,
  }).then(() => updateFilteredRates())
    .catch(() => toggleCommAlert("COMM", "FAILED"));
};

const filterRates = () => {
  const query = searchQuery.value.toLowerCase();
  unselectedRates.value = allRates.value.filter(rate => 
  rate.targetCurrency.toLowerCase().includes(query)
  ).filter(rate => 
    !getRates.value.some(selected => selected.id.currency == rate.targetCurrency)
  );
};


</script>